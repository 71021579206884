import WorkstationsContainer from 'rapidfab/containers/assets/WorkstationsContainer';
import InventoryPostProcessorTypes from 'rapidfab/containers/assets/postProcessorTypes';
import InventoryPrinterTypes from 'rapidfab/containers/assets/printerTypes';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InventoryPrinters from 'rapidfab/containers/assets/printers';
import InventoryPostProcessors from 'rapidfab/containers/assets/postProcessors';
import PropTypes from 'prop-types';
import { FEATURES } from '../constants';
import { ROUTER_PERMISSION } from '../constantsRouter';
import PrivateRoute from './PrivateRoute';
import InventoryPostProcessorServices from '../containers/assets/PostProcessorServices';
import NotFound from '../components/404';

const AssetRoutes = ({ session }) => (
  <Routes>
    <Route
      path="/workstations"
      element={(
        <PrivateRoute
          session={session}
          component={WorkstationsContainer}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/asset-types"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPrinterTypes}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/printer-types"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPrinterTypes}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            {
              [FEATURES.HAWKING_DEPLOYMENT]: false,
            }
          }
        />
      )}
    />
    <Route
      path="/post-processor-types"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPostProcessorTypes}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={
            [FEATURES.MANUFACTURING_EXECUTION_SYSTEM]
          }
        />
      )}
    />
    <Route
      path="/printers"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPrinters}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={{
            [FEATURES.HAWKING_DEPLOYMENT]: false,
          }}
        />
      )}
    />
    <Route
      path="/post-processors"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPostProcessors}
          isRestrictedRoute
          permission={ROUTER_PERMISSION.BUREAU_ONLY}
          features={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
        />
      )}
    />
    <Route
      // TODO: This route config is an edge-case and needs to be handled in some other way.
      // It is allowed for all for now
      // TODO: Route is hidden under `SERVICE_PROVIDER_AT_STEPS` for regular user
      //  but SP role has no such feature
      path="/post-processor-services"
      element={(
        <PrivateRoute
          session={session}
          component={InventoryPostProcessorServices}
          permission={ROUTER_PERMISSION.LOGGED_IN_ONLY}
          isRestrictedRoute
          decidingFeatures={[FEATURES.MANUFACTURING_EXECUTION_SYSTEM]}
          features={[FEATURES.SERVICE_PROVIDERS]}
        />
      )}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);

AssetRoutes.propTypes = {
  session: PropTypes.shape({}).isRequired,
};

export default AssetRoutes;
