/* eslint-disable react-hooks/exhaustive-deps */
import { EVENT_STREAM_RUN_TRANSFORMATION_STATUSES, EVENT_STREAM_SELECTOR_RESOURCES, RUN_TRANSFORMATION_STATUSES } from 'rapidfab/constants';
import { getStateEventStreamEvents } from 'rapidfab/selectors';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// This watches for the update run transformation event
// and checks to see if its completed and if it is then it runs the call back function

const useTrackRunTransformationStatusUpdate = callback => {
  const { topic, payload } = useSelector(state =>
    getStateEventStreamEvents(state, EVENT_STREAM_SELECTOR_RESOURCES.CREATE_RUN_UPDATES) || {});
  useEffect(() => {
    if (topic === EVENT_STREAM_RUN_TRANSFORMATION_STATUSES.RUN_TRANSFORMATION_UPDATED
      && payload?.status === RUN_TRANSFORMATION_STATUSES.COMPLETE) {
      callback();
    }
  }, [payload?.status, topic]);
};

export default useTrackRunTransformationStatusUpdate;
