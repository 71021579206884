import { capitalize } from 'lodash/string';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING } from 'rapidfab/mappings';
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Button,
  OverlayTrigger,
  Tooltip, Row, Col, ListGroupItem, ListGroup, Image,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Loading from 'rapidfab/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormattedLocalizedCost from 'rapidfab/components/FormattedLocalizedCost';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import Feature from 'rapidfab/components/Feature';
import { FEATURES, QUOTE_PREVIEW_MODAL_FIELDS, TEXT_COLOR_CONTRAST } from 'rapidfab/constants';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import dayjs from 'dayjs';
import 'rapidfab/styles/componentStyles/line-item-quote-modal.scss';
import {
  faArrowLeftLong,
  faClose,
  faPlus,
  faPencil,
  faFloppyDisk,
  faQuestionCircle, faClockRotateLeft, faTrash, faMicrochip, faArrowDownWideShort, faChevronLeft, faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const QuoteProcessStepModal = ({ close,
  pieces,
  show,
  savingLineItemQuote,
  processStep,
  fetching,
  processSteps,
  handleSwitch,
  previewData,
  defaultData,
  setPreviewData,
  setDefaultData,
  processStepChargeData,
  editMode,
  onChangeUnit,
  currentValue,
  onClose,
  onCloseConfirm,
  error,
  toggleEditMode,
  confirmClose,
  editModeOff,
  handleSubmit,
  setConfirmClose,
  setSchedulingEstimatesEnabled,
  schedulingEstimatesEnabled,
  scheduledData,
  usersByUri,
  lineItem,
  buttonStates,
  model,
  modelFetching,
  handleChangeLineItemQuoteView,
  reFetchingWorkEstimate,
  workEstimateFetching,
  fetchingUsers,
  lineItemQuoteFetching,
  calculateTotalPricePerPiece,
  workflowsByUri }) => {
  const [additionalValue, setAdditionalValue] = useState(1);
  const [confirmRemoval, setConfirmRemoval] = useState('');
  const maxHoursToWork = Math.max(...processStepChargeData.map(current => Number(current.count)));
  const hasSomeRemovablePreviewItems = useMemo(() => previewData.some(item => item.removable), [previewData]);
  const hasSomeRemovableDefaultItems = useMemo(() => defaultData.some(item => item.removable), [defaultData]);
  const hasRemovableItems = editMode && previewData.length
    ? hasSomeRemovablePreviewItems
    : hasSomeRemovableDefaultItems;
  const totalHoursForPieces = maxHoursToWork * pieces;

  const {
    nextLineItemInactive,
    previousLineItemInactive,
  } = buttonStates;

  const onSubmit = async event => {
    event.preventDefault();
    close();
  };

  const addAdditionalItem = () => {
    setAdditionalValue(previous => previous + 1);
    const itemToAdd = {
      chargeName: `Additional Charge ${additionalValue}`,
      unit: 'Unit',
      count: 2,
      pricePerUnit: 10,
      price: 20,
      id: Math.random() * 2,
      removable: true,
    };

    let result;

    if (previewData.length) {
      result = [...previewData, itemToAdd];
    } else {
      result = [...defaultData, itemToAdd];
    }

    setPreviewData(result);
  };

  const removeAdditionalItem = id => {
    if (editMode) {
      const data = previewData.length ? previewData : defaultData;
      const resultItems = data.filter(item => item.id !== id);
      setPreviewData(resultItems);
    } else {
      const resultItems = defaultData.filter(item => item.id !== id);
      setDefaultData(resultItems);
    }
  };

  const handleConfirmRemoval = id => {
    if (editMode && confirmRemoval !== 'all') {
      return removeAdditionalItem(id);
    }
    if (confirmRemoval) {
      if (confirmRemoval === 'all') {
        if (previewData.length) {
          return setPreviewData(previewData.filter(item => !item.removable));
        }
        return setDefaultData(defaultData.filter(item => !item.removable));
      }
      return removeAdditionalItem(id);
    }
    return setConfirmRemoval(id);
  };

  const getTotalPricePerPiece = () => calculateTotalPricePerPiece({
    laborField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR }),
    workstationTimeField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME }),
    additionalFields: _filter(processStepChargeData, { removable: true }),
    pieceOverheadField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST }),
    runOverheadField: _find(processStepChargeData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST }),
  });

  const getTotalPriceLineItem = getTotalPricePerPiece(processStepChargeData) * pieces;

  const handleToggleSchedulingEstimates = () => setSchedulingEstimatesEnabled(previous => !previous);

  const convertPrice = value => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

  const editableCharges = new Set([
    QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST,
    QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST,
  ]);

  const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem?.status];
  const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];

  const renderListBodyItems = data => data.map(({ chargeName, unit, count, pricePerUnit, price, id, removable }) => (
    <ListGroupItem key={id} className="resource-list-item-card-item">
      <Row>
        <Col xs={5} className="wrap-text">
          {removable && editMode ? (
            <input
              onChange={event => onChangeUnit(id, 'chargeName', event.target.value)}
              className="line-item-quote-modal-input"
              type="text"
              value={currentValue[id]?.chargeName ?? chargeName}
            />
          ) : chargeName}
        </Col>
        <Col xs={hasRemovableItems ? 1 : 2} className="wrap-text">
          {unit}
        </Col>
        <Col xs={1} className="wrap-text">
          {
            editMode && chargeName !== QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST ? (
              <input
                className="line-item-quote-modal-input"
                onChange={event => onChangeUnit(id, 'count', event.target.value)}
                type="number"
                step=".01"
                value={currentValue[id]?.count ?? count}
                disabled={chargeName === QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST}
              />
            ) : <p>{count}</p>
          }
        </Col>
        <Col xs={2} className="wrap-text">
          {
            editMode && editableCharges.has(chargeName) ? (
              <div className="d-flex align-items-center">
                <div>$</div>
                <input
                  disabled={!removable &&
                      (!editableCharges.has(chargeName))}
                  onChange={event => onChangeUnit(id, 'pricePerUnit', event.target.value)}
                  className="line-item-quote-modal-input"
                  type="number"
                  value={currentValue[id]?.pricePerUnit ?? pricePerUnit}
                />
              </div>
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    {convertPrice(pricePerUnit)}
                  </Tooltip>
                )}
              >
                <div>
                  <FormattedLocalizedCost value={pricePerUnit} />
                </div>
              </OverlayTrigger>
            )
          }
        </Col>
        <Col xs={2} className="wrap-text">
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                {convertPrice(price)}
              </Tooltip>
            )}
          >
            <div className="quoteProcessStepModalInputTextPrice">
              <FormattedLocalizedCost
                value={price}
              />
            </div>
          </OverlayTrigger>
        </Col>
        <Col xs={1}>
          {removable && editMode && (
            <FontAwesomeIcon
              role="button"
              icon={faTrash}
              onClick={() => handleConfirmRemoval(id)}
            />
          )}
        </Col>
      </Row>

    </ListGroupItem>

  ));

  const renderSchedulingLastEdited = () => {
    if (workEstimateFetching || fetchingUsers) {
      return <Loading className="ml15" inline />;
    }

    return scheduledData ? (
      <span>{` ${usersByUri[scheduledData?.updated_by]?.name} (${dayjs(scheduledData.updated)
        .format('YYYY-MM-DD h:mm A')})`}
      </span>
    ) :
      ' Not Stored as scheduled time';
  };

  const renderQuoteLastEdited = () => {
    if (lineItemQuoteFetching) {
      return <Loading className="ml15" inline />;
    }

    return processStep?.updated_by && processStep?.updated ? (
      <span className="spacer-left">{`${usersByUri[processStep.updated_by]?.name} (${dayjs(processStep.updated)
        .format('YYYY-MM-DD h:mm A')})`}
      </span>
    ) :
      ' This workflow step quote has never been edited';
  };

  const renderModelData = model => {
    const noModel = !model || !model?.snapshot_content;
    if ((modelFetching && noModel) || reFetchingWorkEstimate) {
      return (
        <div className="line-item-quote-modal-model-fetching">
          <Loading />
        </div>
      );
    }

    if (noModel) {
      return (
        <div
          className="line-item-quote-modal-no-model"
        >
          <div>
            <FormattedMessage
              id="line_item.no_model"
              defaultMessage="No Model"
            />
          </div>
        </div>
      );
    }

    return (
      <Image src={model.snapshot_content} className="PiecesCardRightDataCardModel" thumbnail />
    );
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onClose}
      backdrop="static"
      className="quoteProcessStepModalShow"
      dialogClassName="line-item-quote-modal"
    >
      {show && (
        <Form onSubmit={onSubmit}>
          <Modal.Header>
            <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faPencil} />
                <p className="custom-darken-modal-title-text mt0">
                  <FormattedMessage
                    id="line_item.edit_order_quote"
                    defaultMessage="Edit Order Quote"
                  />
                </p>
              </div>
              <FontAwesomeIcon
                icon={faClose}
                onClick={onCloseConfirm}
                tabIndex={0}
                role="button"
                className="custom-darken-modal-button"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={3}>
                <p className="mb15 font-weight-500">
                  <FormattedMessage
                    id="line_item.workflows_steps"
                    defaultMessage="Workflow Steps"
                  />
                  {
                    editMode && (
                      <OverlayTrigger
                        placement="top"
                        overlay={(
                          <Tooltip>
                            <FormattedMessage
                              id="line_item.workflows_steps_edit_mode_on"
                              defaultMessage="Turn off Edit Mode to switch between Workflow Steps"
                            />
                          </Tooltip>
                        )}
                      >
                        <FontAwesomeIcon className="spacer-left" icon={faQuestionCircle} />
                      </OverlayTrigger>
                    )
                  }
                </p>
                <div className={editMode ? 'quoteProcessStepSelectData quoteProcessStepSelectDataDisabled' : 'quoteProcessStepSelectData'}>
                  {reFetchingWorkEstimate ? <Loading /> : processSteps?.map(({ name, id, updated }, index) => {
                    let stepSelectClass = processStep?.id === id ? 'quoteProcessStepSelectActive' : '';
                    if (editMode && processStep?.id === id) {
                      stepSelectClass += ' quoteProcessStepSelectDisabled';
                    }

                    return (
                      <div
                        className={stepSelectClass}
                        role="button"
                        tabIndex={0}
                        onClick={() => handleSwitch(id)}
                        key={updated}
                      >
                        {
                          name?.length > 30 ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={(
                                <Tooltip>
                                  {name}
                                </Tooltip>
                              )}
                            >
                              <p className="quoteProcessStepSelect">
                                {index + 1}. {name}
                              </p>
                            </OverlayTrigger>
                          ) : (
                            <p className="quoteProcessStepSelect">
                              {index + 1}. {name}
                            </p>
                          )
                        }
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Col md={9} className="bl-light">
                <div className="quoteProcessStepModalNoBorder list-group-quote resource-list-item-card">
                  <div className="d-flex align-items-center justify-content-between mb15">
                    <div className="d-flex align-items-center">
                      <div className="line-item-quote-modal-model">
                        {renderModelData(model)}
                      </div>
                      <div>
                        <p className="mb5 font-weight-500">{lineItem?.name}</p>
                        <div className="d-flex align-items-center mb5 font-weight-500">
                          <span
                            className="badge badge-sm"
                            style={{
                              backgroundColor: labelBgColor,
                              color: labelTextColor,
                            }}
                          >
                            {capitalize(lineItem.status)}
                          </span>
                          <span className="spacer-left">
                            Qty: {lineItem?.quantity}
                          </span>
                        </div>
                        <div className="font-weight-500">
                          <FontAwesomeIcon icon={faArrowDownWideShort} className="spacer-right" />
                          <TruncatedTitle
                            title={workflowsByUri[lineItem?.workflow]?.name || 'N/A'}
                            maxTextLength={48}
                            inline
                            titleClassName="text-white"
                          />
                        </div>

                      </div>
                    </div>

                    <div>
                      <Button
                        className="line-item-quote-modal-line-item-btn mr10"
                        disabled={previousLineItemInactive}
                        onClick={() => handleChangeLineItemQuoteView('prev')}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Button>
                      <Button
                        className="line-item-quote-modal-line-item-btn"
                        disabled={nextLineItemInactive}
                        onClick={() => handleChangeLineItemQuoteView('next')}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    </div>

                  </div>

                  <div className="line-item-quote-modal-step-title">
                    <FontAwesomeIcon icon={faMicrochip} className="spacer-right" />
                    <span>{processStep.name}</span>
                  </div>

                  {fetching || reFetchingWorkEstimate ?
                    <Loading className="mt15 mb15 center" size="2x" /> : (
                      <>
                        <ListGroup fill className="mb15">
                          <ListGroupItem className="resource-list-item-card-header">
                            <Row>
                              <Col xs={5}>
                                <b>
                                  <FormattedMessage
                                    id="line_item.quote_price_factor"
                                    defaultMessage="Price Factor"
                                  />
                                </b>
                              </Col>
                              <Col xs={hasRemovableItems ? 1 : 2}>
                                <b>
                                  <FormattedMessage
                                    id="line_item.quote_price_factor.unit"
                                    defaultMessage="Unit"
                                  />
                                </b>
                              </Col>
                              <Col xs={1}>
                                <b>
                                  <FormattedMessage
                                    id="line_item.quote_price_factor.count"
                                    defaultMessage="#"
                                  />
                                </b>
                              </Col>
                              <Col xs={2}>
                                <b>
                                  <FormattedMessage
                                    id="line_item.quote_price_factor.pricePerUnit"
                                    defaultMessage="$/#"
                                  />
                                </b>
                              </Col>
                              <Col xs={2}>
                                <b>
                                  <FormattedMessage
                                    id="line_item.quote_price_factor.price"
                                    defaultMessage="Price"
                                  />
                                </b>
                              </Col>
                              <Col xs={1} />
                            </Row>
                          </ListGroupItem>

                          {renderListBodyItems(previewData.length ? previewData : defaultData)}

                        </ListGroup>

                        {
                          editMode ? (
                            <div className="d-flex justify-content-between align-items-center mb15">
                              <Button
                                variant="outline-info"
                                className="btn-info-dark"
                                size="sm"
                                onClick={addAdditionalItem}
                              >
                                <FontAwesomeIcon icon={faPlus} className="spacer-right" />
                                <FormattedMessage
                                  id="button.addAdditionalCharge"
                                  defaultMessage="Add Additional Charge"
                                />
                              </Button>
                              <div>
                                <Button onClick={editModeOff} size="sm" variant="outline-light" className="spacer-right">
                                  <FontAwesomeIcon icon={faArrowLeftLong} className="spacer-right" />
                                  <FormattedMessage id="back" defaultMessage="Back" />
                                </Button>
                                <Button
                                  variant="outline-success"
                                  className="btn-success-dark"
                                  size="sm"
                                  onClick={handleSubmit}
                                >
                                  {savingLineItemQuote ?
                                    <Loading inline /> :
                                    <FontAwesomeIcon icon={faFloppyDisk} className="spacer-right" />}
                                  <FormattedMessage id="button.save" defaultMessage="Save" />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center justify-content-end">
                              <Button size="sm" variant="outline-info" onClick={toggleEditMode}>
                                <FontAwesomeIcon icon={faPencil} className="spacer-right" />
                                <FormattedMessage id="button.edit" defaultMessage="Edit" />
                              </Button>
                            </div>
                          )
                        }
                        <Feature featureName={FEATURES.WORKSTEP_ESTIMATE}>
                          {
                            editMode && (
                              <div className="checkbox mb15 d-flex justify-content-end">
                                <Form.Check
                                  inline
                                  className="quoteProcessStepScheduling"
                                  name="timeEstimatesWhenScheduled"
                                  disabled={!editMode}
                                  checked={schedulingEstimatesEnabled}
                                  onChange={handleToggleSchedulingEstimates}
                                  type="checkbox"
                                  label={(
                                    <FormattedMessage
                                      id="process_step.schedulingTimeEstimates"
                                      defaultMessage="Use time estimates when scheduling"
                                    />
                                  )}
                                />
                              </div>
                            )
                          }
                        </Feature>

                        <div className="line-item-quote-modal-summary">
                          <h4>
                            <FormattedMessage
                              id="field.summary"
                              defaultMessage="Summary"
                            />
                          </h4>

                          <Row className="g7">
                            <Col xs={6} className="font-weight-200 line-item-quote-modal-col-dashed-line">
                              <div className="d-flex align-items-center justify-content-between">
                                <p
                                  className="mb0"
                                >
                                  <FormattedMessage
                                    id="line_item_quote.per_piece_duration"
                                    defaultMessage="Per Piece Duration"
                                  />
                                </p>
                                <div className="d-flex align-items-center justify-content-between">
                                  <FontAwesomeIcon icon={faClockRotateLeft} className="spacer-right" />
                                  <p className="mb0 quoteProcessStepModalInputTextPrice">
                                    {maxHoursToWork} h
                                  </p>
                                </div>

                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <p
                                  className="mb0"
                                >
                                  <FormattedMessage
                                    id="line_item_quote.per_line_item_duration"
                                    defaultMessage="Per Line Item Duration"
                                  />
                                  <span className="spacer-left">
                                    ({pieces} pieces)
                                  </span>
                                </p>
                                <div className="d-flex align-items-center justify-content-between">
                                  <FontAwesomeIcon icon={faClockRotateLeft} className="spacer-right" />

                                  <p className="mb0 quoteProcessStepModalInputTextPrice">
                                    {totalHoursForPieces} h
                                  </p>
                                </div>

                              </div>
                            </Col>
                            <Col xs={6} className="font-weight-500">
                              <div className="d-flex align-items-center justify-content-between">
                                <p className="mb0">
                                  <FormattedMessage
                                    id="line_item_quote.per_piece_total_price"
                                    defaultMessage="Total Price Per Piece"
                                  />
                                </p>
                                <div>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={(
                                      <Tooltip>
                                        {convertPrice(getTotalPricePerPiece(processStepChargeData))}
                                      </Tooltip>
                                    )}
                                  >
                                    <p className="quoteProcessStepModalInputTextPrice">
                                      <strong>
                                        <FormattedLocalizedCost value={getTotalPricePerPiece(processStepChargeData)} />
                                      </strong>
                                    </p>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <p className="mb0">
                                  <FormattedMessage
                                    id="line_item_quote.per_line_item_total_price"
                                    defaultMessage="Total Price Per Line Item"
                                  />
                                </p>
                                <div>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={(
                                      <Tooltip>
                                        {convertPrice(getTotalPriceLineItem)}
                                      </Tooltip>
                                    )}
                                  >
                                    <p className="mb0 quoteProcessStepModalInputTextPrice">
                                      <strong>
                                        <FormattedLocalizedCost value={getTotalPriceLineItem} />
                                      </strong>
                                    </p>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}

                  {
                    !editMode && (
                      <div className="mb20">
                        <p className="mb0">
                          <strong>
                            <FormattedMessage
                              id="line_item_quote.last_edited"
                              defaultMessage="Quote Last Edited"
                            />:
                          </strong>
                          {renderQuoteLastEdited()}
                        </p>

                        <Feature featureName={FEATURES.WORKSTEP_ESTIMATE}>
                          <p className="mb0">
                            <strong>
                              <FormattedMessage
                                id="line_item_quote.schedule_last_edited"
                                defaultMessage="Schedule Last Edited"
                              />:
                            </strong>
                            {renderSchedulingLastEdited()}
                          </p>

                        </Feature>
                      </div>
                    )
                  }

                  {error && (
                    <p className="color-red">
                      <FormattedMessage
                        id="line_item_quote.something_went_wrong"
                        defaultMessage="Something went wrong, please try again."
                      />
                    </p>
                  )}
                </div>
              </Col>
            </Row>

            {confirmRemoval && (
              <CancelOrDeleteModal
                modalType={`${confirmRemoval === 'all' ? 'deleteQuoteStepAll' : 'deleteQuoteStep'}`}
                handleConfirm={() => handleConfirmRemoval(confirmRemoval)}
                handleOpen={() => setConfirmRemoval(false)}
              />
            )}
            {
              confirmClose && (
                <CancelOrDeleteModal
                  modalType="confirmClose"
                  handleConfirm={onClose}
                  handleOpen={() => setConfirmClose(false)}
                />
              )
            }
          </Modal.Body>
        </Form>
      )}
    </Modal>
  );
};

QuoteProcessStepModal.defaultProps = {
  processStep: {},
};

QuoteProcessStepModal.propTypes = {
  close: PropTypes.func.isRequired,
  pieces: PropTypes.number.isRequired,
  processStep: PropTypes.instanceOf(Object),
  show: PropTypes.bool.isRequired,
  savingLineItemQuote: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSwitch: PropTypes.func.isRequired,
  previewData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setPreviewData: PropTypes.func.isRequired,
  setDefaultData: PropTypes.func.isRequired,
  processStepChargeData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editMode: PropTypes.bool.isRequired,
  onChangeUnit: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseConfirm: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  confirmClose: PropTypes.bool.isRequired,
  editModeOff: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setConfirmClose: PropTypes.func.isRequired,
  scheduledData: PropTypes.shape({
    updated_by: PropTypes.string,
    updated: PropTypes.string,
  }).isRequired,
  usersByUri: PropTypes.shape({}).isRequired,
  lineItem: PropTypes.shape({
    status: PropTypes.string,
    workflow: PropTypes.string,
    quantity: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  workflowsByUri: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  workEstimateFetching: PropTypes.bool.isRequired,
  setSchedulingEstimatesEnabled: PropTypes.func.isRequired,
  schedulingEstimatesEnabled: PropTypes.bool.isRequired,
  lineItemQuoteFetching: PropTypes.bool.isRequired,
  fetchingUsers: PropTypes.bool.isRequired,
  calculateTotalPricePerPiece: PropTypes.func.isRequired,
  model: PropTypes.shape({
    snapshot_content: PropTypes.string,
  }).isRequired,
  modelFetching: PropTypes.bool.isRequired,
  handleChangeLineItemQuoteView: PropTypes.func.isRequired,
  reFetchingWorkEstimate: PropTypes.bool.isRequired,
  buttonStates: PropTypes.shape({
    nextLineItemInactive: PropTypes.bool,
    previousLineItemInactive: PropTypes.bool,
  }).isRequired,
};

export default memo(QuoteProcessStepModal);
