import isEmpty from 'lodash/isEmpty';
import NCSelectedLineItem from 'rapidfab/components/records/run/NCSelectedLineItem';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Form,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NCSelectedPiece from './NCSelectedPiece';

const ScrapModalContainer = ({
  selectedPiecesList,
  selectedLineItemsData,
  onClose,
  onSubmit,
}) => {
  const [notes, setNotes] = useState('');

  const handleSubmit = () => {
    onClose();
    onSubmit(notes);
  };

  return (
    <>

      <Modal show onHide={onClose} backdrop="static">
        <Modal.Header closeButton>
          <b>
            <FormattedMessage id="scrap.header" defaultMessage="Scrap" />
          </b>
        </Modal.Header>
        <Modal.Body>

          <div className="d-flex">
            <div className="col-md-6">
              <div>
                <b>
                  <FormattedMessage id="scrap.confirmation" defaultMessage="Are you sure you want to scrap?" />
                </b>
              </div>
              <br />
              <span>
                <FormattedMessage id="scrap.details1" defaultMessage="Scrapping will automatically end the workflow for the selected piece(s). This will mark these pieces as Incomplete and cannot be undone." />
              </span>
            &nbsp;
            &nbsp;
              <div>
                <span>
                  <FormattedMessage id="scrap.details2" defaultMessage="If you wish to automatically re-queue these pieces for " />
                  <span style={{ backgroundColor: '#e7e7e7' }}>
                    <b>
                      <FormattedMessage id="scrap.remanufacture" defaultMessage="Remanufacture " />
                    </b>
                  </span>
                  <FormattedMessage id="scrap.details3" defaultMessage="or to perform some other sort of " />
                  <span style={{ backgroundColor: '#e7e7e7' }}>
                    <b>
                      <FormattedMessage id="scrap.productionChange" defaultMessage="Production Change " />
                    </b>
                  </span>
                  <FormattedMessage id="scrap.details4" defaultMessage=", please select Cancel instead." />
                </span>
              </div>
            </div>

            <div className="col-md-6">
              {isEmpty(selectedLineItemsData) ?
                <NCSelectedPiece selectedPiecesList={selectedPiecesList} /> :
                <NCSelectedLineItem selectedLineItems={selectedLineItemsData} />}
            </div>
          </div>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control onChange={({ target }) => { setNotes(target.value); }} required as="textarea" placeholder="Reason for scrapping..." />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'end' }}>
          <div className="mr15">
            <Button variant="secondary" onClick={() => onClose()}>
              Cancel
            </Button>
          </div>
          <div className="mr15">
            <div>
              <Button
                variant="success"
                onClick={handleSubmit}
                disabled={!notes}
                style={!notes ? { pointerEvents: 'none' } : {}}
              >
                Scrap
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ScrapModalContainer.propTypes = {
  selectedPiecesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedLineItemsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  postProcessorsByUri: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ScrapModalContainer;
