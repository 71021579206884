import debounce from 'lodash/debounce';
import _omit from 'lodash/omit';
import { workStepDetailsReadOnlyFields } from 'rapidfab/constants/forms';
import { useOrderQuoteContext } from 'rapidfab/context/OrderQuoteContext';
import useDeepCompareEffect from 'rapidfab/hooks/useDeepCompareEffect';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { API_RESOURCES, FEATURES, QUOTE_PREVIEW_MODAL_FIELDS } from 'rapidfab/constants';
import { useDispatch, useSelector } from 'react-redux';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import QuoteProcessStepModal from 'rapidfab/components/records/order/edit/QuoteProcessStepModal';
import { convertHoursToSeconds } from 'rapidfab/utils/timeUtils';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { uniq } from 'lodash/array';
import { getLineItemWorkflowTypeObjectKey } from '../../../utils/lineItemUtils';

const QuoteProcessStepModalContainer = ({ close,
  show,
  lineItemUri,
  currentStep,
  modelFetching,
  processStepTypesByUri,
  workstepCostEstimates,
  calculateTotalPricePerPiece }) => {
  const order = useSelector(Selectors.getRouteUUIDResource);
  const bureauIntakeSettings = useSelector(Selectors.getBureauIntakeSettings);
  const lineItemWorkstepEstimate = useSelector(Selectors.getLineItemWorkstepEstimatesByUri);
  const lineItemWorkstepCostEstimates = useSelector(Selectors.getLineItemWorkstepCostEstimates);
  const lineItems = useSelector(state => Selectors.getLineItemsForOrderSortedByCreatedDesc(state, order));
  const lineItemsByUri = useSelector(state => Selectors.getLineItemsByUri(state));
  const models = useSelector(Selectors.getModels);
  const usersByUri = useSelector(Selectors.getUsersByUri);
  const isWorkstepEstimateFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.WORKSTEP_ESTIMATE));
  const savingLineItemQuote = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put.fetching);
  const lineItemWorkEstimateFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].list.fetching);
  const lineItemQuoteFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].get.fetching ||
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put.fetching);
  const reFetchingWorkEstimate = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list.fetching ||
    state.ui.nautilus[API_RESOURCES.SHIPPING].list.fetching ||
    state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].list.fetching);
  const fetchingUsers = useSelector(state => state.ui.nautilus[API_RESOURCES.USERS].list.fetching);

  const [lineItemViewUri, setLineItemViewUri] = useState(lineItemUri);

  const processSteps = useSelector(state =>
    Selectors.getLineItemQuoteProcessStepsForLineItem(state, lineItemViewUri));
  const lineItemQuote = useSelector(state => Selectors.getLineItemQuoteByLineItemUri(state, lineItemViewUri));
  const workflowsByUri = useSelector(Selectors.getWorkflowsByUri);
  const uuid = lineItemQuote ? extractUuid(lineItemQuote.uri) : null;

  const [editMode, setEditMode] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const [previewData, setPreviewData] = useState([...defaultData]);
  const [schedulingEstimatesEnabled, setSchedulingEstimatesEnabled] = useState(true);
  const [confirmClose, setConfirmClose] = useState(null);
  const [currentValue, setCurrentValue] = useState('');
  const [error, setError] = useState(false);
  const [processStep, setCurrentProcessStep] = useState({});
  const [fetchedScheduling, setFetchedScheduling] = useState(false);
  const [manualProcessStep, setManualProcessStep] = useState(currentStep);
  const [transformedSteps, setTransformedSteps] = useState([]);

  const { transformProcessSteps, getTransformedStep, getTotalPricePerPiece } = useOrderQuoteContext();

  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItemsByUri[lineItemViewUri]);
  const lineItemWorkstepCostEstimateForCurrentProcessStep =
    _find(lineItemWorkstepCostEstimates, { process_step: processStep?.uuid });

  const model = useMemo(
    () => (lineItemsByUri[lineItemViewUri] && models ?
      models.find(m => m.uri === lineItemsByUri[lineItemViewUri][workflowTypeKey]?.model)
      : null),
    [lineItemViewUri, models],
  );

  const nextLineItemInactive = useMemo(() => {
    const currentIndex = lineItems.findIndex(item => item.uri === lineItemViewUri);
    return currentIndex === lineItems.length - 1;
  }, [lineItemViewUri, lineItems]);

  const previousLineItemInactive = useMemo(() => {
    const currentIndex = lineItems.findIndex(item => item.uri === lineItemViewUri);
    return currentIndex === 0;
  }, [lineItemViewUri, lineItems]);

  const handleChangeLineItemQuoteView = direction => {
    const currentLineItemUri = lineItemViewUri;
    const currentIndex = lineItems.findIndex(item => item.uri === currentLineItemUri);

    if (currentIndex === -1) return;

    let newIndex;
    if (direction === 'next') {
      newIndex = currentIndex + 1;
      if (newIndex < lineItems.length) {
        setLineItemViewUri(lineItems[newIndex].uri);
      }
    } else {
      newIndex = currentIndex - 1;
      if (newIndex >= 0) {
        setLineItemViewUri(lineItems[newIndex].uri);
      }
    }
    setManualProcessStep(null);
  };

  const getTotalPriceLineItem = useCallback(step => {
    if (step.notCounted) {
      return 0;
    }
    return getTotalPricePerPiece(transformedSteps, step) * lineItemsByUri[lineItemViewUri].quantity;
  }, [getTotalPricePerPiece, lineItemsByUri, transformedSteps]);

  const getModalTransformedStep = useCallback((processStep, transformedSteps) => {
    const currentStep = getTransformedStep(transformedSteps, processStep);
    if (currentStep) {
      currentStep.totalPerPiece = getTotalPricePerPiece(transformedSteps, processStep);
      currentStep.totalPerLineItem = getTotalPriceLineItem(processStep);
      return currentStep;
    }
    return [];
  }, [getTotalPriceLineItem, getTotalPricePerPiece, getTransformedStep, transformedSteps]);

  const processStepChargeData = useMemo(() => (previewData.length
    ? previewData : defaultData), [previewData, defaultData]);

  const handleSwitchProcessStep = useCallback(processStepId => {
    const processStep = _find(processSteps, { id: processStepId });
    if (processStep) {
      setCurrentProcessStep(getModalTransformedStep(processStep, transformedSteps));
      setManualProcessStep(null);
    }
  }, [processSteps, transformedSteps, getModalTransformedStep]);

  const dispatch = useDispatch();

  const selected = {
    previewData,
    defaultData,
    processStepChargeData,
    editMode,
    currentValue,
    error,
    confirmClose,
    setConfirmClose,
    schedulingEstimatesEnabled,
    fetchingUsers,
    lineItem: lineItemsByUri[lineItemViewUri],
    model,
    modelFetching,
    workflowsByUri,
    buttonStates: {
      nextLineItemInactive,
      previousLineItemInactive,
    },
  };

  const saveQuoteDetails = async (
    currentUUID,
    lineItemQuoteDetails,
    notes,
    currentProcessStep,
    workstepCostEstimate = null,
  ) => {
    const lineItemQuoteDetailsForCurrentProcessStep = currentProcessStep ? _find(
      lineItemQuoteDetails,
      /* For some reason the fetched process-step's URI is under the key `uuid` 🤷🏼 */
      { process_step: currentProcessStep.uuid },
    ) : null;

    const payload = {
      work_steps_quote_details: lineItemQuoteDetails,
      public_notes: notes,
    };

    if (lineItemQuoteDetailsForCurrentProcessStep) {
      const workstepCostEstimatePayload = {
        line_item: lineItemsByUri[lineItemViewUri].uri,
        process_step: lineItemQuoteDetailsForCurrentProcessStep.process_step,
        overhead_cost_per_piece_in_run:
        lineItemQuoteDetailsForCurrentProcessStep.overhead_cost_per_piece_in_run,
        overhead_cost_per_run:
        lineItemQuoteDetailsForCurrentProcessStep.overhead_cost_per_run,
        number_of_runs: 1,
      };

      if (workstepCostEstimate) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE]
          .put(extractUuid(workstepCostEstimate.uri), workstepCostEstimatePayload));
      } else {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE]
          .post(workstepCostEstimatePayload));
      }
    }

    await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put(currentUUID, payload));
    return null;
  };

  const handleSaveQuoteDetails = (
    lineItemQuoteDetails,
    notes,
    currentProcessStep,
    workstepCostEstimate,
  ) => {
    const filteredLineItemQuoteDetails = _map(
      lineItemQuoteDetails,
      workStepDetails => _omit(workStepDetails, workStepDetailsReadOnlyFields),
    );
    return saveQuoteDetails(
      uuid,
      filteredLineItemQuoteDetails,
      notes,
      currentProcessStep,
      workstepCostEstimate,
    );
  };

  const submitAdditionalCharges = useCallback(async () => {
    const dataToSend = previewData.filter(items => items.removable);
    if (dataToSend) {
      const transformSentData = dataToSend.map(item => ({
        charge_name: item.chargeName,
        charge_item_units: item.unit,
        unit_count: item.count,
        price_per_unit: item.pricePerUnit,
      }));

      const laborField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR });
      const workstationTimeField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME });
      const pieceOverheadCostField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST });
      const runOverheadCostField = _find(previewData, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST });

      /* Manually update the state for the current step in the key: `work_steps_quote_details`
      of `$SITE/line-item-quote/{...}` */
      const currentWorkstep =
        lineItemQuote.work_steps_quote_details.find(step => step.process_step === processStep.uuid);

      currentWorkstep.additional_charges = transformSentData;
      currentWorkstep.labor_price_per = laborField.price;
      currentWorkstep.workstation_price_per = workstationTimeField.price;
      currentWorkstep.overhead_cost_per_piece_in_run = pieceOverheadCostField?.pricePerUnit || 0;
      currentWorkstep.overhead_cost_per_run = runOverheadCostField?.pricePerUnit || 0;
      currentWorkstep.number_of_runs = runOverheadCostField?.count;

      return handleSaveQuoteDetails(
        lineItemQuote.work_steps_quote_details,
        null,
        processStep,
        lineItemWorkstepCostEstimateForCurrentProcessStep,
      );
    }

    return null;
  }, [lineItemQuote.work_steps_quote_details, previewData, processStep.uuid, handleSaveQuoteDetails]);

  const updateUnits = useCallback((id, type, value) => {
    const newData = [...(previewData.length ? previewData : defaultData)];
    const index = newData.findIndex(item => item.id === id);
    if (index !== -1) {
      newData[index][type] = type !== 'chargeName' ? (value === '' ? '' : Number(value)) : value;
      newData[index].price = newData[index].count * newData[index].pricePerUnit;
      setPreviewData(newData);
    }
  }, [previewData, defaultData]);

  const debouncedUpdateUnits = useMemo(() => debounce((id, type, value) => {
    updateUnits(id, type, value);
  }, 300), [updateUnits]);

  const handleChangeUnit = useCallback((id, type, value) => {
    setCurrentValue({ ...currentValue, [id]: { ...currentValue[id], [type]: value } });

    if (value === '') {
      updateUnits(id, type, value);
    } else {
      debouncedUpdateUnits(id, type, value);
    }
  }, [updateUnits, currentValue, debouncedUpdateUnits]);

  const fetchUsers = (scheduledUsers, processStepsUsers) => {
    const scheduledUserUris = uniq(_map(scheduledUsers, 'updated_by').filter(Boolean));
    const processStepsUserUris = uniq(_map(processStepsUsers, 'updated_by').filter(Boolean));
    const allUsers = [...scheduledUserUris, ...processStepsUserUris];

    if (allUsers.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: allUsers }));
    }

    return setFetchedUsers(true);
  };

  const toggleEditMode = () => {
    if (previewData.length) {
      if (error) {
        setError(false);
      }
      setPreviewData([]);
      setCurrentValue('');
    }
    setEditMode(previous => !previous);
  };

  const editModeOff = () => {
    if (previewData.length) {
      setPreviewData([]);
      setCurrentValue('');
    }
    setEditMode(false);
  };

  const onClose = () => {
    if (savingLineItemQuote) {
      // Prevent closing modal during save process
      return;
    }
    editModeOff();
    close();
  };

  const onCloseConfirm = () => {
    if (previewData.length) {
      return setConfirmClose(true);
    }
    return onClose();
  };

  const handleToggleSchedulingEstimates = async () => {
    const laborDuration = _find(processStepChargeData,
      { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR });
    const workstationDuration = _find(processStepChargeData,
      { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME });
    const payload = {
      line_item: lineItemsByUri[lineItemViewUri].uri,
      process_step: processStep.uuid,
      labor_duration: convertHoursToSeconds(laborDuration?.count),
      workstation_duration: convertHoursToSeconds(workstationDuration?.count),
    };

    const workstep = lineItemWorkstepEstimate[processStep.uuid];

    if (workstep) {
      if (workstep.labor_duration === payload.labor_duration
        && workstep.workstation_duration === payload.workstation_duration) {
        // The durations are the same, so we can skip the update
        return null;
      }

      if (isWorkstepEstimateFeatureEnabled) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE]
          .put(lineItemWorkstepEstimate[processStep.uuid].uuid, payload));
      }

      return setFetchedScheduling(false);
    }

    if (isWorkstepEstimateFeatureEnabled) {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].post(payload));
    }

    return setFetchedScheduling(false);
  };

  const handleSubmit = async () => {
    setFetching(true);
    try {
      if (previewData.length) {
        await submitAdditionalCharges();
        setDefaultData(previewData);
        setPreviewData([]);
      }

      if (schedulingEstimatesEnabled) {
        /* If the checkbox is enabled -> we should either send POST and create a new resource,
             or PUT to update the existing one. */
        await handleToggleSchedulingEstimates();
      }

      if (!schedulingEstimatesEnabled && lineItemWorkstepEstimate[processStep.uuid]) {
        /* If the checkbox is disabled, but we already have the resource for this process step ->
             we should remove it by sending the DELETE request. */
        await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE]
          .delete(lineItemWorkstepEstimate[processStep.uuid].uuid));
        setFetchedScheduling(false);
      }

      toggleEditMode();
      setFetching(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setPreviewData(defaultData);
    } finally {
      setEditMode(false);
      setFetching(false);
      setFetchedScheduling(true);
    }
  };

  useDeepCompareEffect(() => {
    const allSteps = transformProcessSteps(
      processSteps,
      lineItemQuote,
      lineItemViewUri,
      lineItemsByUri[lineItemViewUri].quantity,
      processStepTypesByUri,
      workstepCostEstimates,
      bureauIntakeSettings);

    setTransformedSteps(allSteps);
    setCurrentProcessStep(getModalTransformedStep(manualProcessStep || processSteps[0], allSteps));
  }, [processSteps, lineItemQuote]);

  useEffect(() => {
    if ((show && processStep?.data && Object.values(lineItemWorkstepEstimate)?.length) && !fetchedUsers) {
      fetchUsers(Object.values(lineItemWorkstepEstimate), _map(processSteps, 'work_steps_quote_details'));
    }
  }, [show, processStep?.data, fetchedUsers, lineItemWorkstepEstimate]);

  useEffect(() => {
    if (show && processStep?.data) {
      setDefaultData(processStep.data);
    }
  }, [show, processStep?.data, processSteps]);

  useEffect(() => {
    setLineItemViewUri(lineItemUri);
  }, []);

  useEffect(() => {
    if (show && processSteps.length && !fetchedScheduling) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].list({
        line_item: lineItemsByUri[lineItemViewUri].uri,
        process_step: _map(processSteps, 'uri'),
      }));
      setFetchedScheduling(true);
    }
  }, [show, JSON.stringify(lineItemWorkstepEstimate), fetchedScheduling, processSteps]);

  useEffect(() =>
    // Cleanup function to cancel the debounced function
    () => {
      debouncedUpdateUnits.cancel();
    },
  [debouncedUpdateUnits]);

  const dispatched = {
    setDefaultData,
    setPreviewData,
    submitAdditionalCharges,
    onChangeUnit: handleChangeUnit,
    onClose,
    onCloseConfirm,
    toggleEditMode,
    editModeOff,
    handleSubmit,
    setSchedulingEstimatesEnabled,
  };

  return (
    <QuoteProcessStepModal
      close={close}
      pieces={lineItemsByUri[lineItemViewUri].quantity}
      show={show}
      savingLineItemQuote={savingLineItemQuote}
      processStep={processStep}
      fetching={fetching}
      setFetching={setFetching}
      processSteps={processSteps}
      handleSwitch={handleSwitchProcessStep}
      scheduledData={lineItemWorkstepEstimate[processStep.uuid]}
      usersByUri={usersByUri}
      reFetchingWorkEstimate={reFetchingWorkEstimate}
      handleChangeLineItemQuoteView={handleChangeLineItemQuoteView}
      workEstimateFetching={lineItemWorkEstimateFetching}
      lineItemQuoteFetching={lineItemQuoteFetching}
      calculateTotalPricePerPiece={calculateTotalPricePerPiece}
      {...selected}
      {...dispatched}
    />
  );
};

QuoteProcessStepModalContainer.defaultProps = {
  currentStep: null,
};

QuoteProcessStepModalContainer.propTypes = {
  currentStep: PropTypes.oneOfType([PropTypes.shape({}), null]),
  close: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  lineItemUri: PropTypes.string.isRequired,
  modelFetching: PropTypes.bool.isRequired,
  calculateTotalPricePerPiece: PropTypes.func.isRequired,
  processStepTypesByUri: PropTypes.shape({}).isRequired,
  workstepCostEstimates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default QuoteProcessStepModalContainer;
