import _find from 'lodash/find';
import _sumBy from 'lodash/sumBy';
import { API_RESOURCES, ORDER_QUOTE_DISCOUNT_TYPES } from 'rapidfab/constants';

export const transformWorkstepQuoteDetailsForProcessStep =
  (processStep, lineItemQuote, lineItemUri, workstepCostEstimates) => {
  /* This function merges the cost-estimate data into the line-item-quote data
  at keys `overhead_cost_per_piece_in_run` and `overhead_cost_per_run` */
    const workstepQuoteDetailsForProcessStep = _find(
      lineItemQuote.work_steps_quote_details,
      { process_step: processStep.uri });
    const workstepCostEstimateForProcessStep = _find(
      workstepCostEstimates,
      { process_step: processStep.uri, line_item: lineItemUri },
    );

    if (workstepQuoteDetailsForProcessStep && workstepCostEstimateForProcessStep) {
      return {
        ...workstepQuoteDetailsForProcessStep,
        overhead_cost_per_piece_in_run:
      workstepCostEstimateForProcessStep.overhead_cost_per_piece_in_run,
        overhead_cost_per_run:
      workstepCostEstimateForProcessStep.overhead_cost_per_run,
      };
    }

    return { ...workstepQuoteDetailsForProcessStep,
      overhead_cost_per_piece_in_run:
    workstepQuoteDetailsForProcessStep.overhead_cost_per_piece_in_run,
      overhead_cost_per_run:
    workstepQuoteDetailsForProcessStep.overhead_cost_per_run };
  };

export const isProcessStepTypeEqualTo = (currentProcessType, type) =>
  currentProcessType?.uri?.includes(type);

export const getWorkstationTimePricePerUnit = currentProcessType => {
  if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.POST_PROCESSOR_TYPE)) {
    return currentProcessType?.cost * 60;
  } if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.PRINTER_TYPE)) {
    return currentProcessType?.running_cost_per_hour;
  } if (isProcessStepTypeEqualTo(currentProcessType, API_RESOURCES.SHIPPING)) {
    return currentProcessType?.cost * 60;
  }
  return null;
};

export const convertFromPriceToCount = (price, pricePerCount) =>
  Number((price / pricePerCount).toFixed(2));

export const calculateTotalQuote = (
  lineItems,
  transformedSteps,
  discount,
  discountType,
  shippingCosts,
  markup,
  bureauCustomPrice,
) => {
  // Calculate total price for line items
  const lineItemsTotal = _sumBy(lineItems, lineItem =>
    _sumBy(transformedSteps[lineItem.uri] || [], step =>
      _sumBy(step.data, 'price'),
    ) * lineItem.quantity,
  );

  // Calculate discount
  let discountAmount = 0;
  if (discountType === ORDER_QUOTE_DISCOUNT_TYPES.PERCENTAGE && bureauCustomPrice !== null) {
    discountAmount = (bureauCustomPrice * discount) / 100;
  } else if (discountType === ORDER_QUOTE_DISCOUNT_TYPES.AMOUNT) {
    discountAmount = discount;
  }

  // Calculate total shipping cost
  const totalShippingCost = _sumBy(Object.values(shippingCosts), 'cost');

  // Calculate initial total (line items + shipping) before discount and markup
  const initialTotal = lineItemsTotal + totalShippingCost;

  // Calculate subtotal before markup (line items - discount + shipping)
  const subtotal = lineItemsTotal - discountAmount + totalShippingCost;

  // Calculate markup
  const markupAmount = (subtotal * markup) / 100;

  // Calculate final total
  const total = subtotal + markupAmount;

  return {
    discountAmount,
    totalShippingCost,
    markupAmount,
    initialTotal,
    total,
  };
};
