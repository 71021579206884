import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _assign from 'lodash/assign';
import _clone from 'lodash/clone';
import _cloneDeep from 'lodash/cloneDeep';
import _differenceBy from 'lodash/differenceBy';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _omit from 'lodash/omit';
import _partialRight from 'lodash/partialRight';
import _pick from 'lodash/pick';
import _pullAt from 'lodash/pullAt';
import _values from 'lodash/values';
import _without from 'lodash/without';
import PropTypes from 'prop-types';
import LabelsInput from 'rapidfab/components/records/model_library/LabelsInput';
import React, { Component } from 'react';
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Modal,
  Row,
  SplitButton,
  Table,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import DisabledByAccessInfoCheck from 'rapidfab/components/DisabledByAccessInfoCheck';
import Feature from 'rapidfab/components/Feature';
import LastUpdated from 'rapidfab/components/LastUpdated';
import Loading from 'rapidfab/components/Loading';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import WorkflowSpecimen from 'rapidfab/components/records/workflow/WorkflowSpecimen';
import WorkflowSpecimenFormModal from 'rapidfab/components/records/workflow/WorkflowSpecimenFormModal';
import {
  ACCESS_INFO_ACTION_TYPES,
  API_RESOURCES,
  FEATURES,
  PROCESS_STEP_SHIPPING_DIRECTION,
  ROUTES,
  WORKFLOW_TYPES,
  WORKFLOW_USAGE_STATES,
} from 'rapidfab/constants';
import WorkChecklistModalContainer from 'rapidfab/containers/records/WorkChecklistModalContainer';
import WorkflowPickListModalContainer from 'rapidfab/containers/records/workflow/WorkflowPickListModalContainer';
import Alert from 'rapidfab/utils/alert';
import { combineExistingStepsWithSelectedProcessTypes, getStepsUUIDsToDelete } from 'rapidfab/utils/handleWorkflowSave';
import { getEndpointFromURI, getRouteURI } from 'rapidfab/utils/uriUtils';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';

import { faAngleDown, faAngleUp, faBan, faClone, faEdit, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormControlSelect } from 'rapidfab/components/formTools';
import { FormattedMessageMappingOption } from 'rapidfab/i18n';
import { WORKFLOW_TYPES_MAP } from 'rapidfab/mappings';
import { Field, Form } from 'react-final-form';
import Fa from 'react-fontawesome';

const styles = {
  positionHeader: {
    width: '5%',
  },
  stepHeader: {
    width: '90%',
  },
  optionsHeader: {
    width: '5%',
  },
  centerIcons: {
    textAlign: 'center',
  },
  splitDivs: {
    display: 'inline-block',
    width: '50%',
  },
};

const readOnlyFields = ['updated', 'source_workflow', 'usage_state', 'pieces', 'updated_by'];

const ReplaceWarningModal = ({ show, close, replace }) => (
  <Modal show={show} onHide={close}>
    <Modal.Header closeButton>
      <Modal.Title>Warning</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        This Production Workflow has been used in production.
      </p>
      <p>
        Saving changes will update and replace the Workflow for all unconfirmed
        line items currently assigned to use this workflow.
      </p>
      <p>
        Line items that have already begun production (they are confirmed or later)
        will retain their previous production workflow and will not be updated.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="default" onClick={close}>
        <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
      </Button>
      <Button onClick={replace} variant="primary">
        <FormattedMessage
          id="button.updateAndReplace"
          defaultMessage="Update and Replace"
        />
      </Button>
    </Modal.Footer>
  </Modal>
);

ReplaceWarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
};

const ArchiveWarningModal = ({ show, close, archive }) => (
  <Modal show={show} onHide={close}>
    <Modal.Header closeButton>
      <Modal.Title>Warning</Modal.Title>
    </Modal.Header>
    <Modal.Body>This action will archive production workflow.</Modal.Body>
    <Modal.Footer>
      <Button onClick={close}>
        <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
      </Button>
      <Button onClick={archive} variant="primary">
        <FormattedMessage id="button.archive" defaultMessage="Archive" />
      </Button>
    </Modal.Footer>
  </Modal>
);

ArchiveWarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired,
};

const DeleteWarningModal = ({ show, close, submit }) => (
  <Modal show={show} onHide={close}>
    <Modal.Header closeButton>
      <FormattedMessage
        id="message.deleteWorkflowPrompt"
        defaultMessage="Are you sure you want to delete this workflow?"
      />
    </Modal.Header>
    <Modal.Footer>
      <Button onClick={close}>
        <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
      </Button>
      <Button onClick={submit} variant="danger">
        <FormattedMessage id="button.delete" defaultMessage="Delete" />
      </Button>
    </Modal.Footer>
  </Modal>
);

DeleteWarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

class Workflow extends Component {
  constructor(props) {
    super(props);

    this.defaultWorkflow = {
      name: '',
      type: props.isPowderEnabled ? WORKFLOW_TYPES.POWDER_MANUFACTURING : WORKFLOW_TYPES.ADDITIVE_MANUFACTURING,
    };

    this.state = {
      workflow: props.workflow || this.defaultWorkflow,
      steps: [],
      specimens: [],
      alteredSpecimenIndex: null,

      workInstructionProcessStepURI: null,
      showPickListModal: false,
      showSpecimenForm: false,
      showWorkInstructionForm: false,
      showArchiveWarning: false,
      showReplaceWarning: false,
      showDeleteWarning: false,

      selectedProcessStep: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectProcessStep = this.handleSelectProcessStep.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openWorkInstructionModal = this.openWorkInstructionModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.shouldOpenOverwriteWarning = this.shouldOpenOverwriteWarning.bind(
      this,
    );
    this.onArchive = this.onArchive.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDuplicate = this.onDuplicate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onReplace = this.onReplace.bind(this);
    this.addSteps = this.addSteps.bind(this);
    this.addSpecimen = this.addSpecimen.bind(this);
    this.deleteSpecimen = this.deleteSpecimen.bind(this);
  }

  componentDidMount() {
    const { specimens, steps } = this.props;
    if (specimens?.length) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ specimens });
    }

    if (steps) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ steps });
    }

    if (!this.state.workflow?.id) {
      // we are on the Workflow creation page, the workflow should be default
      this.setState({
        workflow: this.defaultWorkflow,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { workflow, specimens, steps } = this.props;

    if (specimens?.length !== prevProps.specimens?.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ specimens });
    }

    if (!_isEqual(workflow, prevProps.workflow)) {
      let updatedWorkflow = workflow;
      if (!updatedWorkflow) {
        updatedWorkflow = this.defaultWorkflow;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        workflow: updatedWorkflow,
      });
    }

    if (steps && !_isEqual(steps, prevProps.steps || [])) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        steps,
      });
    }
  }

  handleChange(event) {
    const workflow = _clone(this.state.workflow);
    const { name, value } = event.target;
    workflow[name] = value;
    this.setState({ workflow });
  }

  handleSelectProcessStep(processStep) {
    this.setState({ selectedProcessStep: processStep });
  }

  onDeleteClick() {
    const { workflow } = this.state;

    if (workflow.usage_state === WORKFLOW_USAGE_STATES.ACTIVE) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.productionWorkflow.usedByLineItems"
          defaultMessage="This production workflow can't be deleted as it used by line items"
        />,
      );
      return;
    }

    if (workflow.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED) {
      Alert.error(
        <FormattedMessage
          id="toaster.error.productionWorkflow.archivedCannotBeDeleted"
          defaultMessage="Archived production workflows can't be deleted"
        />);
      return;
    }

    const modalByUsageState = {
      [WORKFLOW_USAGE_STATES.INACTIVE]: 'showDeleteWarning',
      [WORKFLOW_USAGE_STATES.CAN_BE_SAFELY_ARCHIVED]: 'showArchiveWarning',
    };

    const modalName = modalByUsageState[workflow.usage_state];
    this.openModal(modalName);
  }

  onDelete() {
    this.closeModal('showDeleteWarning');
    this.props.onDelete(this.props?.uuid);
  }

  onDuplicate() {
    const { specimens, workflow } = this.state;

    // It's needed to submit only these fields to match with signature and remove 'uri'
    const specimenFields = ['model_library', 'quantity', 'workflow'];
    const duplicateSpecimens = _map(
      specimens,
      _partialRight(_pick, specimenFields),
    );

    const { name } = this.props.fields;
    const duplicateName = `${name} copy`;

    const workflowType = this.props.fields.type;

    const workflowCopy = {
      name: duplicateName,
      // steps: this.state.steps,
      source_workflow: workflow.uri,
      type: workflowType,
      specimens: duplicateSpecimens,
    };
    this.props.onDuplicate(workflowCopy);
  }

  onArchive() {
    this.closeModal('showArchiveWarning');
    let payload = _cloneDeep(this.props.workflow);

    const archiveReadOnlyFields = [...readOnlyFields, 'line_item', 'materials'];
    payload = _omit(payload, archiveReadOnlyFields);
    payload.usage_state = WORKFLOW_USAGE_STATES.ARCHIVED;

    this.props.onSave(payload, [], []);
  }

  onReplace() {
    const steps = _clone(this.state.steps);

    const stepUrisPromises = this.props.saveSteps(
      [],
      _map(steps, step => ({
        ..._omit(step, ['uuid', 'uri']),
        // If it's an already existing process step, we need to copy it with
        // source_process_step which points to parent process step
        source_process_step: step.uri,
      })),
    );

    Promise.all(stepUrisPromises).then(processStepsUris => {
      let payload = _cloneDeep(this.state.workflow);
      const replaceReadOnlyFields = [...readOnlyFields, 'specimens', 'type', 'materials', 'flow_time', 'flow_time_queued'];
      payload = _omit(payload, replaceReadOnlyFields);
      payload.description = payload.description || '';
      payload.process_steps = processStepsUris;

      this.props.onReplace(payload);
    });

    this.closeModal('showReplaceWarning');
  }

  onSave(formValues, refererIsParent = false) {
    const { workflow } = this.state;

    if (workflow) {
      if (workflow.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED) {
        Alert.error(
          <FormattedMessage
            id="toaster.error.productionWorkflow.archivedOnlyDuplicate"
            defaultMessage="This production workflow is archived. You can only duplicate it"
          />);
        return;
      }

      if (
        [WORKFLOW_USAGE_STATES.ACTIVE, WORKFLOW_USAGE_STATES.CAN_BE_SAFELY_ARCHIVED]
          .includes(workflow.usage_state)
      ) {
        if (this.shouldOpenOverwriteWarning()) {
          this.openModal('showReplaceWarning');
        } else {
          this.onSubmit(formValues);
        }
        return;
      }
    }

    // Production Workflow is not exist, create it
    this.onSubmit(formValues, refererIsParent);
  }

  onSubmit(formValues, refererIsParent = false) {
    const { specimens } = this.state;
    const { specimens: propsSpecimens } = this.props;

    const steps = _clone(this.state.steps);

    // find deleted steps, save them for later
    const deletedSteps = getStepsUUIDsToDelete(this.props.steps, steps);

    const deletedSpecimens = _differenceBy(propsSpecimens, specimens, 'uri');

    const stepUrisPromises = this.props.saveSteps(this.props.steps, steps);

    Promise.all(stepUrisPromises)
      .then(stepUris => {
        let payload = _cloneDeep(this.state.workflow);
        payload.labels = formValues.labels || [];
        const saveReadOnlyFields = [...readOnlyFields, 'line_item', 'materials'];
        payload = _omit(payload, saveReadOnlyFields);
        payload.description = payload.description ? payload.description : '';
        payload.process_steps = stepUris;

        // It's needed to submit only these fields to match with signature
        const specimenFields = ['uri', 'model_library', 'quantity', 'workflow'];
        payload.specimens = _map(
          specimens,
          _partialRight(_pick, specimenFields),
        );

        this.props.onSave(payload, deletedSteps, deletedSpecimens, uri => {
          if (refererIsParent === false) {
            if (uri) {
              window.location.hash = getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: getEndpointFromURI(uri).uuid });
            } else {
              window.location.hash = getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST);
            }
          }
        });
      });
  }

  getWorkflowState() {
    return this.state;
  }

  openModal(modalState, index, indexStateName) {
    if (!Number.isNaN(Number.parseInt(index, 10))) {
      this.setState({
        [modalState]: true,
        [indexStateName]: index,
        modalIndexStateName: indexStateName,
      });
    } else {
      this.setState({ [modalState]: true, modalIndexStateName: undefined });
    }
  }

  openWorkInstructionModal(stepURI) {
    if (!stepURI) {
      Alert.warning(
        <FormattedMessage
          id="toaster.warning.workflow.saveBeforeAddingWorkInstructions"
          defaultMessage="Please save production workflow before adding work instructions"
        />);
      return;
    }

    this.openModal('showWorkInstructionForm', stepURI, 'workInstructionProcessStepURI');
    this.handleSelectProcessStep(stepURI);
  }

  closeModal(modalState) {
    const {
      alteredSpecimenIndex,
      workInstructionProcessStepURI,
      modalIndexStateName,
    } = this.state;

    const newState = {
      [modalState]: false,
    };

    if (workInstructionProcessStepURI) {
      newState.workInstructionProcessStepURI = null;
    }

    if (alteredSpecimenIndex) {
      newState.alteredSpecimenIndex = null;
    }

    if (modalIndexStateName) {
      newState[modalIndexStateName] = null;
    }

    this.setState(newState);
  }

  shouldOpenOverwriteWarning() {
    const isExistingWorkflow = this.props?.uuid;
    const { steps } = this.state;
    const hasNewSteps = !_isEqual(steps, this.props.steps);
    return hasNewSteps && isExistingWorkflow;
  }

  addSteps(payload) {
    const changedSteps = combineExistingStepsWithSelectedProcessTypes(this.state.steps, payload);
    this.closeModal('showPickListModal');
    this.setState({ steps: changedSteps });
  }

  addSpecimen(payload) {
    const { specimens, alteredSpecimenIndex } = this.state;
    const updatedSpecimens = [...specimens];
    const specimen = payload;
    specimen.quantity = Number.parseInt(specimen.quantity, 10);

    if (alteredSpecimenIndex !== null) {
      updatedSpecimens.splice(alteredSpecimenIndex, 1, specimen);
    } else {
      updatedSpecimens.push(specimen);
    }

    this.setState({
      specimens: updatedSpecimens,
      showSpecimenForm: false,
      alteredSpecimenIndex: null,
    });
  }

  moveRow(index, direction) {
    // first ignore anything thats already at the top going up
    // or at the bottom going down
    if (index === 0 && direction === 'up') {
      return;
    } if (this.state.steps.length - 1 === index && direction === 'down') {
      return;
    }

    const steps = _cloneDeep(this.state.steps);
    const newPosition = direction === 'up' ? index - 1 : index + 1;

    const temporary = steps[newPosition];
    steps[newPosition] = steps[index];
    steps[index] = temporary;

    this.setState({ steps });
  }

  deleteStep(index) {
    const steps = _cloneDeep(this.state.steps);
    // TODO Fix it sometime
    // eslint-disable-next-line lodash/prefer-immutable-method
    _pullAt(steps, index); // js sucks at poping items at a given index

    this.setState({ steps });
  }

  deleteSpecimen(specimen) {
    let { specimens } = this.state;
    specimens = _without(specimens, specimen);
    this.setState({ specimens });
  }

  render() {
    const {
      workflow,
      steps,
      alteredSpecimenIndex,
      showArchiveWarning,
      showReplaceWarning,
      showDeleteWarning,
      showSpecimenForm,
      showWorkInstructionForm,
      workInstructionProcessStepURI,
      specimens,
      showPickListModal,
    } = this.state;

    const {
      uuid,
      fetching,
      availablePrinterTypes,
      availablePostProcessorTypes,
      shippingTypes,
      isSubmitting,
      modelLibraries,
      specimenWorkflows,
      checklistLinkings,
      postProcessorsByUri,
      printersByUri,
      processTypesByUri,
      isPowderEnabled,
      processStepsFetching,
      hideHeaderUI = false,
      lgCol,
      smCol,
      xsCol,
      workflowTypes,
      labelsByUri,
    } = this.props;

    const specimenWorkflowsByUri = _keyBy(specimenWorkflows, 'uri');
    const isArchived = workflow.usage_state === WORKFLOW_USAGE_STATES.ARCHIVED;

    // Contains list of process step URIs that have customized checklists
    const checklistLinkingProcessSteps = _map(
      _filter(
        checklistLinkings,
        // Filter checkist linkings to the ones,
        // that include checklists created specifically for current resource
        ({ work_checklist: { initial_related_uri: initialRelatedUri } }) =>
          initialRelatedUri === workflow.uri,
      ),
      'process_step',
    );

    const Arrows = ({ index }) => (
      <div>
        <div
          onClick={() => {
            this.moveRow(index, 'down');
          }}
          role="button"
          style={_assign({}, styles.centerIcons, styles.splitDivs)}
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faAngleDown} size="2x" />
        </div>
        <div
          onClick={() => {
            this.moveRow(index, 'up');
          }}
          role="button"
          style={_assign({}, styles.centerIcons, styles.splitDivs)}
          tabIndex={0}
        >
          <FontAwesomeIcon icon={faAngleUp} size="2x" />
        </div>
      </div>
    );

    const Rows = () => {
      const workstationsByUri = {
        ...postProcessorsByUri,
        ...printersByUri,
      };

      const rows = _map(steps, (step, index) => {
        const processType = processTypesByUri[step.workstation_type_uri];
        const isExistWorkInstructions = checklistLinkingProcessSteps.includes(step.uri);

        let icon = null;

        if (processType) {
          const { endpointName: processTypeEntityName } = getEndpointFromURI(processType.uri);

          if (processTypeEntityName === API_RESOURCES.PRINTER_TYPE) {
            icon = 'print';
          } else if (processTypeEntityName === API_RESOURCES.POST_PROCESSOR_TYPE) {
            icon = 'object-group';
          } else if (processTypeEntityName === API_RESOURCES.SHIPPING) {
            icon = 'truck';
          }
        }

        let shippingDirectionIcon;

        switch (step.shipping_direction) {
          case PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_SERVICE_PROVIDER:
            shippingDirectionIcon = 'level-down';
            break;
          case PROCESS_STEP_SHIPPING_DIRECTION.SERVICE_PROVIDER_TO_BUREAU:
            shippingDirectionIcon = 'level-up';
            break;
          case PROCESS_STEP_SHIPPING_DIRECTION.BUREAU_TO_CUSTOMER:
            shippingDirectionIcon = 'arrow-right';
            break;
          default:
            shippingDirectionIcon = null;
            break;
        }
        return (
          <tr key={index}>
            <td>
              <Arrows index={index} />
            </td>
            <td>
              {processType ? (
                <span>
                  {icon && <Fa name={icon} />}
                  {shippingDirectionIcon && <Fa className="ml5" name={shippingDirectionIcon} />}
                  <span className="ml5">{step.name || processType.name}</span>
                </span>
              ) : (
                <span>
                  <FormattedMessage
                    id="processStepMissing"
                    defaultMessage="[Error - Step Not Found]"
                  />
                </span>
              )}
            </td>
            <td>
              {step.workstation
                && workstationsByUri
                && workstationsByUri[step.workstation]?.name}
            </td>
            <td style={styles.centerIcons}>
              <div
                role="button"
                onClick={() => this.openWorkInstructionModal(step.uri)}
                tabIndex={0}
              >
                <FontAwesomeIcon icon={isExistWorkInstructions ? faEdit : faPlus} />
              </div>
            </td>
            <td style={styles.centerIcons}>
              <div
                role="button"
                onClick={() => this.deleteStep(index)}
                tabIndex={0}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </td>
          </tr>
        );
      });
      return <tbody>{rows}</tbody>;
    };

    /*
      No need to show "Loading" on the whole page if the Modal Window is opened,
      we will show the loading indicator inside it.
    */
    if (fetching && !showWorkInstructionForm) {
      return <Loading />;
    }

    return (
      <Container fluid>
        {hideHeaderUI === false && (
          <BreadcrumbNav
            breadcrumbs={[
              'plan',
              'workflows',
              workflow.id
                ? `${workflow.name} (${getShortUUID(workflow.uuid)})`
                : 'New',
            ]}
          />
        )}
        <Form
          onSubmit={this.onSave}
          initialValues={this.props.initialFormValues}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              { hideHeaderUI === false && (
                <div className={`d-flex align-items-center ${isArchived ? 'justify-content-between' : 'justify-content-end'}`}>
                  <div>
                    {isArchived && (
                      <Badge bg="warning" className="badge-md ms-2">
                        <FormattedMessage id="field.archived" defaultMessage="Archived" />
                      </Badge>
                    )}
                  </div>
                  <div>
                    <DisabledByAccessInfoCheck
                      resourceUri={workflow && workflow.uri}
                      actionType={ACCESS_INFO_ACTION_TYPES.EDIT}
                      renderDisabledPrefix
                      tooltipPlacement="bottom"
                    >
                      {({ disabled }) => (
                        <SplitButton
                          type="submit"
                          id="uxSaveDropdown"
                          variant="success"
                          size="sm"
                          disabled={disabled || isSubmitting}
                          title={isSubmitting ? <Loading /> : <SaveButtonTitle />}
                        >
                          <Dropdown.Item
                            eventKey={1}
                            onClick={() => this.onDuplicate()}
                            disabled={!this.props.fields?.id}
                          >
                            <FontAwesomeIcon icon={faClone} />{' '}
                            <FormattedMessage
                              id="button.duplicate"
                              defaultMessage="Duplicate"
                            />
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey={2}
                            onClick={() => this.onDeleteClick()}
                            disabled={!this.props.fields?.id}
                          >
                            <FontAwesomeIcon icon={faBan} />{' '}
                            <FormattedMessage
                              id="button.delete"
                              defaultMessage="Delete"
                            />
                          </Dropdown.Item>
                        </SplitButton>
                      )}
                    </DisabledByAccessInfoCheck>
                  </div>
                </div>
              )}

              {hideHeaderUI === false && <hr />}

              <Row>
                <Col xs={12}>
                  <Row>
                    <Col
                      xs={xsCol || 12}
                      sm={smCol || { span: 8, offset: 2 }}
                      lg={lgCol || { span: 6, offset: 3 }}
                    >
                      <FormGroup className="form-group">
                        <FormLabel>
                          <FormattedMessage
                            id="field.workflowName"
                            defaultMessage="Production Workflow Name"
                          />
                          : *
                        </FormLabel>
                        <Field
                          name="name"
                          render={({ input }) => (
                            <FormControl
                              type="text"
                              name="name"
                              required
                              onChange={event => {
                                input.onChange(event);
                                this.handleChange(event);
                              }}
                              value={workflow.name}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Feature featureName={FEATURES.SPECIMEN_LIBRARY}>
                    <Row>
                      <Col
                        xs={xsCol || 12}
                        sm={smCol || { span: 8, offset: 2 }}
                        lg={lgCol || { span: 6, offset: 3 }}
                      >
                        <FormGroup className="form-group">
                          <FormLabel>
                            <FormattedMessage
                              id="field.workflowType"
                              defaultMessage="Production Workflow Type"
                            />
                            : *
                          </FormLabel>
                          <Field
                            name="type"
                            type="select"
                            render={({ input }) => (
                              <FormControlSelect
                                name="type"
                                required
                                onChange={event => {
                                  input.onChange(event);
                                  this.handleChange(event);
                                }}
                                value={workflow.type}
                                disabled={Boolean(uuid)}
                              >
                                {workflowTypes.map(workflowTypeKey => (
                                  <FormattedMessageMappingOption
                                    key={workflowTypeKey}
                                    value={workflowTypeKey}
                                    mapping={WORKFLOW_TYPES_MAP}
                                  />
                                ))}
                              </FormControlSelect>
                            )}
                          />
                          {uuid && (
                            <FormText>
                              <small>
                                <FormattedMessage
                                  id="typeCannotBeChangedAfterCreation"
                                  defaultMessage="Production Workflow type cannot be changed once a production workflow is created."
                                />
                              </small>
                            </FormText>
                          )}
                        </FormGroup>

                      </Col>
                    </Row>
                  </Feature>

                  <Row>
                    <Col
                      xs={xsCol || 12}
                      sm={smCol || { span: 8, offset: 2 }}
                      lg={lgCol || { span: 6, offset: 3 }}
                    >
                      <FormGroup controlId="workflowLabels" className="form-group">
                        <FormLabel>
                          <FormattedMessage id="field.labels" defaultMessage="Labels" />:
                        </FormLabel>
                        <Field
                          name="labels"
                          render={props => (
                            <>
                              <Feature featureName="experiment-alpha">
                                <LabelsInput.New
                                  isDarkMode
                                  labelsByUri={labelsByUri}
                                  field={{
                                    ...props.meta,
                                    ...props.input,
                                  }}
                                />
                              </Feature>
                              <Feature featureName="experiment-alpha" isInverted>
                                <LabelsInput.Old
                                  field={{
                                    ...props.meta,
                                    ...props.input,
                                  }}
                                />
                              </Feature>
                            </>
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs={xsCol || 12}
                      sm={smCol || { span: 8, offset: 2 }}
                      lg={lgCol || { span: 6, offset: 3 }}
                    >
                      <Table responsive hover>
                        <thead>
                          <tr>
                            <th style={styles.positionHeader}>Position</th>
                            <th>Step</th>
                            <th>Specific Workstation</th>
                            <th style={styles.optionsHeader}>Work Instructions</th>
                            <th style={styles.optionsHeader}>Delete</th>
                          </tr>
                        </thead>
                        {
                          processStepsFetching ? (
                            <Loading className="workflowProcessStepsLoader" />
                          ) : <Rows />
                        }
                      </Table>
                      {_isEmpty(steps) && (
                        <em className="pull-left" style={{ paddingLeft: '8px' }}>
                          Please add steps to create a production workflow
                        </em>
                      )}
                      <Button
                        variant="success"
                        className="pull-right"
                        data-cy="add-steps"
                        onClick={() => this.openModal('showPickListModal')}
                      >
                        <FormattedMessage
                          id="button.addSteps"
                          defaultMessage="Add Steps"
                        />
                      </Button>
                    </Col>
                  </Row>
                  <Feature featureName={FEATURES.SPECIMEN_LIBRARY}>
                    {workflow.type === WORKFLOW_TYPES.ADDITIVE_MANUFACTURING && (
                      <Row>
                        <Col
                          xs={xsCol || 12}
                          sm={smCol || { span: 8, offset: 2 }}
                          lg={lgCol || { span: 6, offset: 3 }}
                        >
                          <hr />

                          {modelLibraries && specimens?.length ? (
                            <Table responsive hover>
                              <thead>
                                <tr>
                                  <th style={styles.stepHeader}>Specimen</th>
                                  <th style={styles.optionsHeader}>Quantity</th>
                                  <th style={styles.optionsHeader}>
                                    Production Workflow
                                  </th>
                                  <th style={styles.optionsHeader}>Edit</th>
                                  <th style={styles.optionsHeader}>Delete</th>
                                </tr>
                              </thead>
                              {_map(specimens, (specimen, index) => (
                                <WorkflowSpecimen
                                  key={index}
                                  specimen={specimen}
                                  modelLibrary={
                                    modelLibraries[specimen.model_library]
                                  }
                                  specimenWorkflow={
                                    specimenWorkflowsByUri[specimen.workflow]
                                  }
                                  onDelete={this.deleteSpecimen}
                                  onEdit={() => {
                                    this.openModal(
                                      'showSpecimenForm',
                                      index,
                                      'alteredSpecimenIndex',
                                    );
                                  }}
                                />
                              ))}
                            </Table>
                          ) : null}

                          <div className="clearfix">
                            <Button
                              variant="success"
                              className="pull-right"
                              onClick={() => this.openModal('showSpecimenForm')}
                            >
                              <FormattedMessage
                                id="button.addSpecimen"
                                defaultMessage="Add Specimen"
                              />
                            </Button>
                          </div>
                          <hr />
                        </Col>
                      </Row>
                    )}
                  </Feature>
                </Col>
              </Row>
            </form>
          )}
        </Form>
        {workflow && (
          <Row>
            <Col
              xs={xsCol || 12}
              sm={smCol || { span: 8, offset: 2 }}
              lg={lgCol || { span: 6, offset: 3 }}
            >
              <LastUpdated resource={workflow} />
            </Col>
          </Row>
        )}

        {showSpecimenForm && (
          <WorkflowSpecimenFormModal
            data={
              Number.isInteger(alteredSpecimenIndex) &&
              specimens[alteredSpecimenIndex]
            }
            modelLibraries={modelLibraries}
            show={showSpecimenForm}
            isEditing={this.state.modalIndexStateName === 'alteredSpecimenIndex'}
            specimenWorkflows={specimenWorkflows}
            close={() => this.closeModal('showSpecimenForm')}
            submit={this.addSpecimen}
            handleGetAllSpecimens={this.props.handleGetAllSpecimens}
          />
        )}

        {showWorkInstructionForm && (
          <WorkChecklistModalContainer
            onClose={() => this.closeModal('showWorkInstructionForm')}
            // relatedURI and production workflow is the same uri
            // relatedURI used to fetch all checklists
            // production workflow used to fetch all process steps
            // onlyProcessStepURI limits production workflow process steps
            //   with single one
            relatedURI={workflow.uri}
            workflowURI={workflow.uri}
            onlyProcessStepURI={workInstructionProcessStepURI}
            showDelete={false}
            selectedProcessStep={this.state.selectedProcessStep}
            selectProcessStep={this.handleSelectProcessStep}
            resourcesFetching={fetching}

            showWorkInstructionForm={showWorkInstructionForm}
          />
        )}

        {showPickListModal && (
          <WorkflowPickListModalContainer
            show={showPickListModal}
            onClose={() => this.closeModal('showPickListModal')}
            onSubmit={this.addSteps}
            currentWorkflowSteps={_values(steps)}
            printerTypes={
              workflow.type === WORKFLOW_TYPES.SPECIMEN ? [] : availablePrinterTypes
            }
            postProcessorTypes={availablePostProcessorTypes}
            shippingTypes={shippingTypes}
            isPowderWorkflow={isPowderEnabled}
          />
        )}

        <ReplaceWarningModal
          show={showReplaceWarning}
          close={() => this.closeModal('showReplaceWarning')}
          replace={this.onReplace}
        />

        <ArchiveWarningModal
          show={showArchiveWarning}
          close={() => this.closeModal('showArchiveWarning')}
          archive={this.onArchive}
        />

        <DeleteWarningModal
          show={showDeleteWarning}
          close={() => this.closeModal('showDeleteWarning')}
          name={workflow?.name || ''}
          submit={this.onDelete}
        />
      </Container>
    );
  }
}

Workflow.defaultProps = {
  workflow: null,
  specimens: [],
  hideHeaderUI: false,
  lgCol: null,
  smCol: null,
  xsCol: null,
};

Workflow.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    name: PropTypes.shape({
      value: PropTypes.string,
      initialValue: PropTypes.string,
    }),
    id: PropTypes.shape({
      value: PropTypes.string,
    }),
    type: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  modelLibraries: PropTypes.shape({}).isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onReplace: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  checklistLinkings: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  availablePrinterTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  availablePostProcessorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types,
  shippingTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  route: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  specimens: PropTypes.arrayOf(PropTypes.shape),
  initialFormValues: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  saveSteps: PropTypes.func.isRequired,
  workflow: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  specimenWorkflows: PropTypes.arrayOf(PropTypes.object).isRequired,
  postProcessorsByUri: PropTypes.shape({}).isRequired,
  printersByUri: PropTypes.shape({}).isRequired,
  processTypesByUri: PropTypes.objectOf(
    PropTypes.shape({}),
  ).isRequired,
  uuid: PropTypes.string.isRequired,
  handleGetAllSpecimens: PropTypes.func.isRequired,
  isPowderEnabled: PropTypes.bool.isRequired,
  processStepsFetching: PropTypes.bool.isRequired,
  hideHeaderUI: PropTypes.bool,
  lgCol: PropTypes.shape({}),
  smCol: PropTypes.shape({}),
  xsCol: PropTypes.shape({}),
  workflowTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  labelsByUri: PropTypes.shape({}).isRequired,
};

export default Workflow;
