import PropTypes from 'prop-types';
import * as Selectors from 'rapidfab/selectors';
import { getRequiredDefaultCustomFields } from 'rapidfab/utils/lineItemUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomOrderFieldReferences,
  getPermissionLocationUris,
  getPiecesForOrder,
  getPrintsByUri,
  getRolesCurrentUser,
  getRouteUUID,
  getRouteUUIDResource,
  isCurrentUserRestricted,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import { toISOStringWithoutTime } from 'rapidfab/utils/timeUtils';
import _isUndefined from 'lodash/isUndefined';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import {
  API_RESOURCES,
  FEATURES, MODAL_TEMPLATES,
  PIECE_STATUSES,
  PRINT_TYPES,
  PRIORITIES,
  ROUTES,
  USER_ROLES,
} from 'rapidfab/constants';
import { ORDER_SUMMARY } from 'rapidfab/constants/forms';
import Actions from 'rapidfab/actions';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import usePrevious, { useModal } from 'rapidfab/hooks';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Alert from 'rapidfab/utils/alert';
import { createOrReplaceArray } from 'rapidfab/utils/arrayUtils';
import getInitialCustomFieldValues from 'rapidfab/utils/getInitialCustomFieldValues';
import isOrderQuoteAvailable from 'rapidfab/utils/isOrderQuoteAvailable';
import OrderSummary from 'rapidfab/components/records/order/edit/OrderSummary';
import * as Sentry from '@sentry/react';
import { FormattedMessage } from 'rapidfab/i18n';
import { useNavigate } from 'react-router-dom';

const OrderSummaryContainer = ({ isOrderTemplate }) => {
  const navigate = useNavigate();
  const [showTabOpeningWarning, setShowTabOpeningWarning] = useState(false);
  const [modalType, setModalType] = useState('');
  const [orderTemplateName, setOrderTemplateName] = useState('');
  const [isFormDirty, setIsFormDirty] = useState(false);

  const isFormDirtyDecorator = useMemo(() => form => {
    const unsubscribe = form.subscribe(
      ({ dirty }) => {
        setIsFormDirty(dirty);
      },
      { dirty: true },
    );
    return unsubscribe;
  }, [setIsFormDirty]);

  const uuid = useSelector(getRouteUUID);
  const initialValues = useSelector(getRouteUUIDResource);

  // convert ISO date to yyyy-mm-dd for html input
  if (initialValues.due_date) {
    const date = new Date(initialValues.due_date);
    initialValues.due_date = toISOStringWithoutTime(date);
  }

  // if the order has not previously been set with a priority, default to normal
  if (_isUndefined(initialValues.priority)) {
    initialValues.priority = PRIORITIES.NORMAL;
  }
  const order = useSelector(getRouteUUIDResource);
  const bureau = useSelector(Selectors.getBureau);
  const printsByUri = useSelector(getPrintsByUri);
  const orderPieces = useSelector(
    state => getPiecesForOrder(state, order).filter(p => p.type === PRINT_TYPES.PRODUCT),
  );
  const currentRunUris = _compact(_uniq(orderPieces.map(piece => {
    const currentPrint = printsByUri[piece.current_print];
    const currentRunUri = currentPrint && currentPrint.run;
    return currentRunUri;
  })));

  /* When clicking `Go to current runs`, we want to warn the user if there are more
  than 3 runs because the system will open a new tab for each. */
  const currentRunUrisDoesExceedWarnThreshold = currentRunUris.length > 3;

  const isFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.PIECE].list.fetching
    || state.ui.nautilus[API_RESOURCES.PRINT].list.fetching);

  const piecesScheduled = orderPieces.filter(
    orderPiece => printsByUri[orderPiece.current_print]?.run ||
        ![PIECE_STATUSES.NEW, PIECE_STATUSES.CONFIRMED].includes(orderPiece.status),
  );
  const piecesCompleted = orderPieces.filter(orderPiece => {
    const pieceIndexStatus = Object.keys(PIECE_STATUSES).indexOf(orderPiece.status.toUpperCase());
    return (pieceIndexStatus > 3) && (pieceIndexStatus < 9);
  });

  const handleCancelTemplateModal = () => {
    setModalType('');
    setOrderTemplateName('');
  };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => ORDER_SUMMARY.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  const customOrderFieldReferences = useSelector(getCustomOrderFieldReferences) || [];
  const orderPut = useSelector(state => state.ui.nautilus[API_RESOURCES.ORDER].put);
  const saveOrderTemplateFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER].post.fetching);
  const orderQuoteFeatureEnabled = useSelector(state => isFeatureEnabled(
    state, FEATURES.ORDER_QUOTE,
  ));
  const isOrderSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.ORDER].put.fetching);
  const isLineItemSubmitting = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM].put.fetching);
  const isRestrictedUser = useSelector(isCurrentUserRestricted);
  const boeingOrderFieldsFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.BOEING_ORDER_FIELDS));
  const orderQuoteSpecialProcessingFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ORDER_QUOTE));
  const isRobozeDDWFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.ROBOZE_DDW));
  const currentUserRoles = useSelector(getRolesCurrentUser);
  const permissionLocationUris = useSelector(getPermissionLocationUris);
  const [openWarningModal, WarningModal] = useModal();

  const piecesScheduledCount = piecesScheduled?.length > 999 ? '999+' : piecesScheduled?.length;
  const piecesCompletedCount = piecesCompleted.length > 999 ? '999+' : piecesCompleted.length;

  const orderQuoteAvailable = isOrderQuoteAvailable(
    order,
    orderQuoteFeatureEnabled,
    orderQuoteSpecialProcessingFeatureEnabled,
  );

  const totalPieces = orderPieces?.length;

  const isSaveDisabled = isLineItemSubmitting || isOrderSubmitting;

  const dispatch = useDispatch();

  const onDuplicate = useCallback(currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER]
      .clone(currentUUID))
      .then(result => {
        const { headers: { location } } = result;
        const duplicatedOrderUUID = extractUuid(location);
        window.location.hash = getRouteURI(ROUTES.ORDER_EDIT, { uuid: duplicatedOrderUUID });
      })
      .catch(error => {
        Alert.error(error);
        console.error(error);
        Sentry.captureException(error);
      });
  }, [dispatch]);

  // Save as Template is the same as Create New Order + Template Name
  const onSaveAsTemplate = useCallback(async (templateName, values) => {
    const orderPayload = {
      application_type: values.application_type,
      bureau: bureau.uri,
      due_date: values.due_date
        ? new Date(values.due_date).toISOString()
        : null,
      custom_field_values: values.custom_field_values || [],
      customer_email: values.customer_email,
      customer_name: values.customer_name,
      customer_po: values.customer_po,
      ip_sensitivity: values.ip_sensitivity,
      location: values.location,
      name: values.name,
      order_owner:
        values.order_owner === '' ? null : values.order_owner,
      order_type: values.order_type,
      priority: values?.priority && Number.parseInt(values.priority, 10),
      region: values.region,
      shipping: {
        address: values.shipping.address,
        name: values.shipping.name,
        tracking: values.shipping.tracking,
        uri: values.shipping.uri,
      },
      shipping_grouping: values.shipping.uri
        ? values.shipping_grouping
        : null,
      template_name: templateName,
    };

    const requiredCustomFields =
        getRequiredDefaultCustomFields(customOrderFieldReferences, initialFormValues.custom_field_values);

    orderPayload.custom_field_values = [
      ...orderPayload.custom_field_values,
      ...requiredCustomFields];

    Object.keys(orderPayload).forEach(key => {
      if (orderPayload[key] == null || orderPayload[key] === 'none') {
        delete orderPayload[key];
      }
    });

    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].post(orderPayload));
      handleCancelTemplateModal();
      Alert.success('Template successfully created');
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const onDelete = useCallback(currentUUID => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].delete(currentUUID))
      .then(() => {
        window.location.hash = getRouteURI(ROUTES.ORDERS);
      })
      .catch(error => {
        Alert.error(error);
        console.error(error);
        Sentry.captureException(error);
      });
  }, [dispatch]);

  const onCancel = useCallback(currentUUID => {
    const payload = { status: 'cancelled' };
    dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER]
      .put(currentUUID, payload))
      .catch(error => {
        Alert.error(error);
        console.error(error);
        Sentry.captureException(error);
      });
  }, [dispatch]);

  const onSave = useCallback((formValues, showWarning) => {
    const payload = formValues;

    Object.keys(payload).forEach(key => {
      if (payload[key] === 'none') payload[key] = null;
    });

    ORDER_SUMMARY.NULL_FIELDS.forEach(
      fieldName => {
        if (payload[fieldName] === '') {
          payload[fieldName] = null;
        }
      },
    );

    delete payload.status;

    if (payload.notes === null) {
      delete payload.notes;
    }

    if (!payload.location) {
      // location null === "Any"
      payload.location = null;
    }

    if (payload.due_date) {
      const date = new Date(payload.due_date);
      payload.due_date = date.toISOString();
    } else {
      payload.due_date = null;
    }

    if (!payload.shipping.uri) {
      payload.shipping_grouping = null;
      // payload.shipping_grouping = 'by_order';
    }

    payload.priority = Number.parseInt(payload.priority, 10);

    const requiredCustomFields =
      getRequiredDefaultCustomFields(customOrderFieldReferences, payload.custom_field_values);

    // Submit required Custom Field Values which are `Required` but not yet provided
    payload.custom_field_values = [
      ...payload.custom_field_values,
      ...requiredCustomFields];

    const userRoles = new Set(currentUserRoles.map(role => role.role));
    const showLocationOrderAccessWarning = !userRoles.has(USER_ROLES.MANAGER)
    && !userRoles.has(USER_ROLES.GLOBAL_USER)
    && userRoles.has(USER_ROLES.LOCATION_USER)
    && !permissionLocationUris.includes(payload.location);
    if (showWarning && showLocationOrderAccessWarning) {
      openWarningModal({
        modalTemplate: MODAL_TEMPLATES.YES_OR_NO,
        title: '',
        bodyText: <FormattedMessage
          id="message.locationOrderAccessWarning"
          defaultMessage="Order not assigned to a location that you have access to. You will no longer have access to this Order until a Global or Manager User re-assigns this to your Location."
        />,
        onConfirm: () => onSave(payload, false),
      });
      return;
    }

    try {
      dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].put(payload.uuid, payload))
        .then(async () => {
          if (showLocationOrderAccessWarning) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].clear('list'));
            navigate(getRouteURI(ROUTES.ORDERS, {}, {}, true));
          } else {
            await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].get(payload.uuid));
          }
        });
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [dispatch, JSON.stringify(currentUserRoles), JSON.stringify(permissionLocationUris)]);

  const previousOrderSubmitting = usePrevious(isOrderSubmitting);
  useEffect(() => {
    if ((isOrderSubmitting !== previousOrderSubmitting) && previousOrderSubmitting) {
      const isSuccessResponse = orderPut.errors.length === 0;
      if (isSuccessResponse) {
        Alert.success(
          <FormattedMessage
            id="toaster.order.successfullyUpdated"
            defaultMessage="Order successfully updated."
          />,
        );
      }
    }
  }, [isOrderSubmitting, orderPut.errors.length, previousOrderSubmitting]);

  const onCustomFieldChange = useCallback(([field, customField], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const customFieldValuesReplaced = createOrReplaceArray(
      customFieldValues,
      { custom_field: customField.customFieldReferenceUri },
      { value: customField.value },
    );

    changeValue(state, field, () => (customFieldValuesReplaced));
  }, []);

  const initCustomFieldValues = useCallback(([field], state, { changeValue }) => {
    const customFieldValues = state.formState.values.custom_field_values;

    const updatedCustomOrderFieldValues = getInitialCustomFieldValues(
      customOrderFieldReferences, customFieldValues,
    );
    changeValue(state, field, () => (updatedCustomOrderFieldValues));
  }, [customOrderFieldReferences]);

  const dispatched = {
    onDuplicate,
    onDelete,
    onSaveAsTemplate,
    onCancel,
    onSave,
    onCustomFieldChange,
    initCustomFieldValues,
  };

  const selected = useMemo(() => ({
    initialFormValues,
    uuid,
    order,
    isOrderSubmitting,
    isLineItemSubmitting,
    isRestrictedUser,
    boeingOrderFieldsFeatureEnabled,
    orderPieces,
    printsByUri,
    piecesScheduledCount,
    piecesCompletedCount,
    isFetching,
    customOrderFieldReferences,
    orderQuoteAvailable,
    totalPieces,
    isSaveDisabled,
    currentRunUris,
    currentRunUrisDoesExceedWarnThreshold,
    isRobozeDDWFeatureEnabled,
    saveOrderTemplateFetching,
    tabOpeningWarning: {
      showTabOpeningWarning,
      setShowTabOpeningWarning,
    },
  }), [
    boeingOrderFieldsFeatureEnabled,
    customOrderFieldReferences,
    initialFormValues,
    isFetching,
    isLineItemSubmitting,
    isOrderSubmitting,
    isRestrictedUser,
    isSaveDisabled,
    order,
    orderPieces,
    orderQuoteAvailable,
    piecesCompletedCount,
    piecesScheduledCount,
    printsByUri,
    totalPieces,
    uuid,
    currentRunUris,
    currentRunUrisDoesExceedWarnThreshold,
    showTabOpeningWarning,
    saveOrderTemplateFetching,
  ]);

  return (
    <>
      <WarningModal id="orderLocationWarningModal" />
      <OrderSummary
        {...selected}
        {...dispatched}
        actionModalProps={{
          modalType,
          setModalType,
          orderTemplateName,
          setOrderTemplateName,
          handleCancelTemplateModal,
        }}
        isFormDirtyDecorator={isFormDirtyDecorator}
        isFormDirty={isFormDirty}
        isOrderTemplate={isOrderTemplate}
      />
    </>
  );
};

OrderSummaryContainer.propTypes = {
  isOrderTemplate: PropTypes.bool.isRequired,
};

export default React.memo(OrderSummaryContainer);
