import _compact from 'lodash/compact';
import _kebabCase from 'lodash/kebabCase';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _values from 'lodash/values';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import SelectMultiple from 'rapidfab/components/forms/SelectMultiple';
import MSNavImports from 'rapidfab/components/plan/MSNavImports';
import {
  API_RESOURCES,
  MSNAV_IMPORT_FILE_STATUSES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
} from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import { MSNAV_IMPORT_FILE_STATUSES_MAP } from 'rapidfab/mappings';
import {
  getMSNavImportFiles,
  getMSNavWorkflowToWorkflows,
  getMSNavWorkflows,
  getWorkflowsByUri,
  getIsDebugModeEnabled,
} from 'rapidfab/selectors';
import { getStateEventStreamMSNavLatest } from 'rapidfab/selectors/baseStateSelectors';
import Alert from 'rapidfab/utils/alert';
import React, { useEffect, useReducer, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

const MSNavImportsContainer = props => {
  const dispatch = useDispatch();
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);

  const msnavWorkflows = useSelector(state => getMSNavWorkflows(state));
  const workflowsByUri = useSelector(state => getWorkflowsByUri(state));
  const latestMSNavEvent = useSelector(state => getStateEventStreamMSNavLatest(state));
  const msnavWorkflowToWorkflows = useSelector(state => getMSNavWorkflowToWorkflows(state));
  const msnavWorkflowToWorkflowCreatedWorkflowUris = _compact(_map(msnavWorkflowToWorkflows, 'workflow'));

  // Conglomerate all the above data.
  const transformedMSNavWorkflowData = msnavWorkflows.map(msnavWorkflow => (
    { ...msnavWorkflow,
      workflow: workflowsByUri[msnavWorkflow?.workflow] }
  ));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transformedLineItemData, setTransformedLineItemData] = useState({});
  const [selectedFilterStatuses, setSelectedFilterStatuses] = useState([]);
  const [showMsNavImportModal, setShowMsNavImportModal] = useState(false);

  const isMsnavFileFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MSNAV_IMPORT_FILE].list.fetching
    || state.ui.nautilus[API_RESOURCES.MSNAV_IMPORT_FILE].post.fetching
    || state.ui.nautilus[API_RESOURCES.MSNAV_IMPORT_WORKFLOW].get.fetching
    || state.ui.nautilus[API_RESOURCES.MSNAV_IMPORT_MSNAV_WORKFLOW_TO_WORKFLOW].post.fetching
    || state.ui.nautilus[API_RESOURCES.MSNAV_IMPORT_FILE_TO_ORDER].post.fetching
    || state.ui.nautilus[API_RESOURCES.WORKFLOW]
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM]);

  const isOrderFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.ORDER].list.fetching);

  const onInitialize = () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.MSNAV_IMPORT_FILE].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MSNAV_IMPORT_WORKFLOW]
      .list());
    dispatch(Actions.Api.nautilus[API_RESOURCES.MSNAV_IMPORT_MSNAV_WORKFLOW_TO_WORKFLOW].list());
  };

  const getTransformedLineItemData = async () => {
    if (msnavWorkflowToWorkflowCreatedWorkflowUris.length) {
      const lineItemsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM]
        .list({ workflow: msnavWorkflowToWorkflowCreatedWorkflowUris }));

      const lineItemsForMSNavWorkflowToWorkflows = lineItemsResponse?.json?.resources;
      const lineItemsForMSNavWorkflowToWorkflowOrderUris = _map(lineItemsForMSNavWorkflowToWorkflows, 'order');

      if (_compact(lineItemsForMSNavWorkflowToWorkflowOrderUris).length) {
        const ordersForLineItemsResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER]
          .list({ uri: lineItemsForMSNavWorkflowToWorkflowOrderUris }));
        const ordersForLineItems = ordersForLineItemsResponse?.json?.resources;

        const lineItemPromises = ordersForLineItems ? lineItemsForMSNavWorkflowToWorkflows.map(async lineItem => ({
          ...lineItem,
          order: ordersForLineItems.find(order => order.uri === lineItem.order),
        })) : [];

        const result = await Promise.all(lineItemPromises);
        return result;
      }
    }
    // Otherwise...
    return null;
  };

  const onCheckboxChange = event => {
    const { checked } = event.target;
    const { onFilterUpdate, filters } = props;
    const { archived, ...changedFilters } = filters;

    if (event.target.name === 'toggleCompleted') {
      changedFilters.status = checked ?
        _reject(_values(MSNAV_IMPORT_FILE_STATUSES), value => value === 'processed')
        : _values(MSNAV_IMPORT_FILE_STATUSES);
    }

    if (event.target === 'toggleArchived') {
      changedFilters.archived = null;
    }

    onFilterUpdate(changedFilters);
  };

  const onStatusChange = selectedStatuses => {
    const { onFilterUpdate, filters } = props;
    const { status, ...changedFilters } = filters;

    if (selectedStatuses.length) {
      // _kebabCase converts to: `string-example-example`
      changedFilters.status = selectedStatuses.map(status => _kebabCase(status));
      setSelectedFilterStatuses(selectedStatuses);
    }

    onFilterUpdate(changedFilters);
  };

  const msnavFileInitialState = {
    uploadedMSNavFiles: [],
    msnavFileConversionProcessHasStarted: false,
    msnavFileConversionProcessStage: 1,
    processedMSNavFileUris: [],
  };

  const msnavFileStateReducer = (state, action) => {
    switch (action.type) {
      case 'SET_UPLOADED_MSNAV_FILES':
        return { ...state, uploadedMSNavFiles: action.payload };
      case 'SET_MSNAV_FILE_CONVERSION_STARTED':
        return { ...state, msnavFileConversionProcessHasStarted: action.payload };
      case 'SET_MSNAV_FILE_CONVERSION_STAGE':
        return { ...state, msnavFileConversionProcessStage: action.payload };
      case 'SET_PROCESSED_MSNAV_FILE_URIS':
        return { ...state, processedMSNavFileUris: action.payload };
      case 'RESET_STATE':
        return msnavFileInitialState;
      default:
        return state;
    }
  };

  const [msnavFileState, msnavFileStateDispatch] = useReducer(msnavFileStateReducer, msnavFileInitialState);

  const onModalHide = () => {
    setIsSubmitting(false);

    msnavFileStateDispatch({
      type: 'RESET_STATE',
    });

    setShowMsNavImportModal(false);
  };

  const handleError = error => {
    Alert.error(error.message);
    onModalHide();
  };

  // STAGE 1: Send POST to `msnav-import/file`
  const saveAndUploadMSNavFiles = async files => {
    const msnavImportFileUploadPromises = files.map(async file => {
      const msnavImportFilePostResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.MSNAV_IMPORT_FILE].post({
        name: file?.path,
      }));

      const { uploadLocation } = msnavImportFilePostResponse?.headers;
      const { uri } = msnavImportFilePostResponse?.payload;

      // The action is entitled `UploadModel` but it appears to work for all files (not only model files).
      await dispatch(Actions.UploadModel.upload(uploadLocation, file))
        .catch(error => handleError(error));

      return uri;
    });

    const uploadedMSNavFilesResponse = await Promise.all(msnavImportFileUploadPromises);

    msnavFileStateDispatch({
      type: 'SET_MSNAV_FILE_CONVERSION_STAGE',
      payload: msnavFileState.msnavFileConversionProcessStage + 1,
    });

    return uploadedMSNavFilesResponse;
  };

  // STAGE 2: Reset state data
  const handleMSNavFileProcessCompletion = () => {
    msnavFileStateDispatch({
      type: 'SET_MSNAV_FILE_CONVERSION_STARTED',
      payload: false,
    });

    msnavFileStateDispatch({
      type: 'RESET_STATE',
    });

    setShowMsNavImportModal(false);
    setIsSubmitting(false);
  };

  const handleProcessMSNavFiles = async () => {
    if (!isSubmitting && msnavFileState.msnavFileConversionProcessStage === 1) {
      // Stage 1. Handle initial upload; and `msnav-import/file` POST.
      setIsSubmitting(true);

      const processedMSNavFileUris = await saveAndUploadMSNavFiles(msnavFileState.uploadedMSNavFiles);

      msnavFileStateDispatch({
        type: 'SET_PROCESSED_MSNAV_FILE_URIS',
        payload: processedMSNavFileUris,
      });
    } else {
      /* Placeholder code */
    }
  };

  const handleReprocessMSNavFile = async (file_uuid, name) => {
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MSNAV_IMPORT_FILE].put(file_uuid, { name }));
  };

  useEffect(() => {
    if (msnavFileState.msnavFileConversionProcessHasStarted) {
      // Consolidate all stages of the conversion.
      handleProcessMSNavFiles(msnavFileState.uploadedMSNavFiles);
    }
  }, [
    msnavFileState.msnavFileConversionProcessHasStarted,
    msnavFileState.msnavFileConversionProcessStage,
  ]);

  useEffect(() => {
    if (
      latestMSNavEvent?.topic === 'nautilus.msnav-import/file.updated'
      && latestMSNavEvent?.payload.status === 'processed'
    ) {
      handleMSNavFileProcessCompletion();

      // Re-initialize data
      onInitialize();
      getTransformedLineItemData();

      // A crude way of getting the page to refresh.
      // eslint-disable-next-line no-self-assign
      window.location = window.location;
    } else if (
      latestMSNavEvent?.topic === 'nautilus.msnav-import/file.updated'
      && latestMSNavEvent?.payload.status === 'error') {
      // Handle catching of errors (Stage 3)
      Alert.error(latestMSNavEvent?.payload.error || latestMSNavEvent?.payload.notes);
      onModalHide();
    }
  }, [latestMSNavEvent]);

  const handleMSNavFileProcessStart = files => {
    // Trigger handleProcessMSNavFiles(...).
    msnavFileStateDispatch({
      type: 'SET_MSNAV_FILE_CONVERSION_STARTED',
      payload: true,
    });

    // Set a temporary store of the raw uploaded file(s).
    msnavFileStateDispatch({
      type: 'SET_UPLOADED_MSNAV_FILES',
      payload: files,
    });
  };

  useEffect(() => {
    onInitialize();
  }, []);

  useEffect(() => {
    (async () => {
      // Handle fetching of `/line-item`, populating the "Related Order" column
      setTransformedLineItemData(await getTransformedLineItemData());
    })();
  }, [isMsnavFileFetching, isSubmitting]);

  const dispatched = {
    onCheckboxChange,
    setIsSubmitting,
    onModalHide,
  };

  const selected = {
    isMsnavFileFetching,
    isOrderFetching,
    msnavWorkflows,
    transformedMSNavWorkflowData,
    transformedLineItemData,
    latestMSNavEvent,
    submitting: [isSubmitting, setIsSubmitting],
    msnavImportModalState: [showMsNavImportModal, setShowMsNavImportModal],
    isDebugModeEnabled,
  };

  return (
    <MSNavImports
      saveAndUploadMSNavFiles={handleMSNavFileProcessStart}
      handleReprocessMSNavFile={handleReprocessMSNavFile}
      {...props}
      {...selected}
      {...dispatched}
      extraFilters={[
        <div key="msnavImportsExtraFilters" className="form-inline" style={{ lineHeight: '40px' }}>
          <div className="form-group mr15">
            <FormLabel htmlFor="statusFilter">
              <FormattedMessage
                id="field.status"
                defaultMessage="Status"
              />:
            </FormLabel>
            <div className="spacer-left form-control inline-picky-wrapper">
              <SelectMultiple
                title="Status"
                data={Object.values(MSNAV_IMPORT_FILE_STATUSES_MAP)}
                labelKey="label"
                valueKey="status"
                selectedData={selectedFilterStatuses}
                handleOnClose={onStatusChange}
              />
            </div>
          </div>
        </div>,
      ]}
    />
  );
};

MSNavImportsContainer.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    archived: PropTypes.bool,
    status: PropTypes.string,
  }).isRequired,
};

export default withRecordsListHandling(
  MSNavImportsContainer,
  getMSNavImportFiles,
  ['msnav-import/file'],
  {
    searchBy: 'name',
    defaultLimit: PAGINATION_IGNORE_DEFAULT_LIMIT,
    defaultSort: null, // Returns 500, likely b/e-related
    useLocationFiltering: true,
    multicolumnSearch: false,
  },
);
