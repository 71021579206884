import NavSubMenuItem from 'rapidfab/components/NavSubMenuItem';
import AssetsTypesIcon from 'rapidfab/icons/assets-types-icon';
import PostProcessorTypesIcon from 'rapidfab/icons/post-processor-types-icon';
import PrinterTypeIcon from 'rapidfab/icons/printer-type-icon';
import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES, FEATURES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { Navbar, Nav, NavItem, NavLink, NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';
import Feature from 'rapidfab/components/Feature';
import NavProfile from 'rapidfab/components/navbar/NavProfile';
import {
  Plan,
  Inventory,
  Assets, Organize, Track, Analytics,
} from 'rapidfab/components/navbar/Titles';
import UseNonMfgLanguageFeature from 'rapidfab/components/generalMfgLanguage/UseNonMfgLanguageFeature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faWrench,
  faCubes,
  faPrint,
  faPenToSquare,
  faBusinessTime,
  faShareFromSquare,
  faBoxesPacking,
  faArrowDownShortWide,
  faBarsStaggered,
  faChartGantt,
  faBarsProgress,
  faPen,
  faFax,
  faCoins,
  faDiceD6,
  faLocationDot,
  faBriefcase,
  faTruckFast,
  faDollarSign,
  faSignal,
  faWarehouse,
  faBox,
  faObjectGroup,
  faBoxOpen,
  faArchive,
  faHammer,
  faBoxesStacked,
  faStore, faBookOpen, faEdit,
} from '@fortawesome/free-solid-svg-icons';
import VisibleFor from 'rapidfab/components/VisibleFor';

const NavLinksRegular = ({
  currentUser,
  onChangeLocale,
  locale,
  onLogout,
  onImpersonateStop,
  isStopImpersonationLoading,
  session,
  maEnabled,
  mesEnabled,
  isModelLibraryFeatureEnabled,
  serviceProvidersEnabled,
  materialManagementEnabled,
  isPrepWorkflowEnabled,
  isWIPMatrixEnabled,
  isDigitalDesignWarehouseFeatureEnabled,
  isPowderWorkflowFeatureEnabled,
  isPOCUKOrderFieldsFeatureEnabled,
  isToolingStockTypesFeatureEnabled,
  isVendorServiceProviderFeatureEnabled,
}) => (

  <Navbar.Collapse>
    {(maEnabled || mesEnabled) && (
      <Nav>
        <NavDropdown
          title={<Plan />}
          id="uxNavPlan"
        >
          {mesEnabled && (
            <NavSubMenuItem
              eventKey={1.1}
              route={ROUTES.ORDERS}
              icon={faPenToSquare}
              title={<FormattedMessage id="plan.orders" defaultMessage="Orders" />}
            >
              <Feature featureName={FEATURES.SERVICE_PROVIDER_AT_STEPS}>
                <NavDropdown.Item
                  eventKey={1.2}
                  href={getRouteURI(ROUTES.SERVICE_PROVIDER_JOBS)}
                >
                  <FontAwesomeIcon icon={faBusinessTime} fixedWidth />{' '}
                  <FormattedMessage
                    id="plan.serviceProviderJobs"
                    defaultMessage="Service Provider Jobs"
                  />
                </NavDropdown.Item>
              </Feature>

              <NavDropdown.Item eventKey={1.3} href={getRouteURI(ROUTES.PIECES)}>
                <FontAwesomeIcon icon={faWrench} fixedWidth />{' '}
                <FormattedMessage id="pieces" defaultMessage="Pieces" />
              </NavDropdown.Item>
            </NavSubMenuItem>
          )}

          <NavDropdown.Item eventKey={1.5} href={getRouteURI(ROUTES.RUNS)}>
            <FontAwesomeIcon icon={faShareFromSquare} fixedWidth />{' '}
            <FormattedMessage id="plan.runs" defaultMessage="Runs" />
          </NavDropdown.Item>

          <Feature
            featureNames={[
              FEATURES.SERVICE_PROVIDER_AT_STEPS,
              FEATURES.SHIPMENT_FOR_ORDER,
            ]}
          >
            <NavDropdown.Item
              eventKey={1.6}
              href={getRouteURI(ROUTES.SHIPMENTS)}
            >
              <FontAwesomeIcon icon={faBoxesPacking} fixedWidth />{' '}
              <FormattedMessage
                id="plan.shipments"
                defaultMessage="Shipments"
              />
            </NavDropdown.Item>
          </Feature>

          <Feature featureName={FEATURES.MSNAV_IMPORTER}>
            <NavDropdown.Item eventKey={1.7} href={getRouteURI(ROUTES.MSNAV_IMPORTS)}>
              <FontAwesomeIcon icon={faFileAlt} fixedWidth />{' '}
              <FormattedMessage
                id="msnavImports"
                defaultMessage="MSNav Imports"
              />
            </NavDropdown.Item>
          </Feature>

          <NavDropdown.Divider className="w-100" />

          {mesEnabled && (
            <NavDropdown.Item
              eventKey={1.4}
              href={getRouteURI(ROUTES.PRODUCTION_WORKFLOW_LIST)}
            >
              <FontAwesomeIcon icon={faArrowDownShortWide} fixedWidth />{' '}
              <FormattedMessage id="workflows" defaultMessage="Workflows" />
            </NavDropdown.Item>
          )}
        </NavDropdown>

        <NavDropdown
          title={<Track />}
          id="uxNavTrack"
        >
          <NavDropdown.Item eventKey={2.1} href={getRouteURI(ROUTES.QUEUES)}>
            <FontAwesomeIcon icon={faBarsStaggered} fixedWidth />{' '}
            <FormattedMessage id="track.queues" defaultMessage="Machine Queues" />
          </NavDropdown.Item>
          <NavDropdown.Item eventKey={2.2} href={`${getRouteURI(ROUTES.ORDERS)}?view_mode=chart`}>
            <FontAwesomeIcon icon={faChartGantt} fixedWidth />{' '}
            <FormattedMessage id="track.productionGantt" defaultMessage="Production Gantt" />
          </NavDropdown.Item>
          {isWIPMatrixEnabled && (
            <NavDropdown.Item eventKey={2.3} href={`${getRouteURI(ROUTES.WIP_MATRIX)}`}>
              <FontAwesomeIcon icon={faBarsProgress} fixedWidth />{' '}
              <FormattedMessage id="track.workInProgressMatrix" defaultMessage="WIP Matrix" />
            </NavDropdown.Item>
          )}
          {isPrepWorkflowEnabled && (
            <NavDropdown.Item eventKey={2.4} href={getRouteURI(ROUTES.PREP_TASK_RECORDS)}>
              <FontAwesomeIcon icon={faPen} fixedWidth />{' '}
              <FormattedMessage id="tasks" defaultMessage="User Tasks" />
            </NavDropdown.Item>
          )}
          <NavDropdown.Divider style={{ display: 'none' }} />
        </NavDropdown>

        <NavDropdown
          title={<Assets />}
          id="uxNavAssets"
        >
          {
            !isPowderWorkflowFeatureEnabled ? (
              <NavSubMenuItem
                eventKey={3.1}
                route={ROUTES.WORKSTATIONS}
                icon={faWarehouse}
                title={<FormattedMessage id="assets.workstations" defaultMessage="Workstations" />}
              >
                {
                  !isPowderWorkflowFeatureEnabled && (
                    <NavDropdown.Item eventKey={3.2} href={getRouteURI(ROUTES.PRINTERS)}>
                      <FontAwesomeIcon icon={faPrint} fixedWidth />{' '}
                      <UseNonMfgLanguageFeature
                        mfgLanguageComponent={(
                          <FormattedMessage
                            id="inventory.printers"
                            defaultMessage="Printers"
                          />
                        )}
                        nonMfgLanguageComponent={(
                          <FormattedMessage
                            id="mfg.printer.productionDevice"
                            defaultMessage="Production Device"
                          />
                        )}
                      />
                    </NavDropdown.Item>
                  )
                }

                {mesEnabled && (
                  <NavDropdown.Item
                    eventKey={3.3}
                    href={getRouteURI(ROUTES.POST_PROCESSORS)}
                  >
                    <FontAwesomeIcon icon={faFax} fixedWidth />{' '}
                    <FormattedMessage
                      id="inventory.postProcessors"
                      defaultMessage="Post Processors"
                    />
                  </NavDropdown.Item>
                )}

                <Feature featureName={FEATURES.SERVICE_PROVIDER_AT_STEPS}>
                  <NavDropdown.Item
                    eventKey={3.4}
                    href={getRouteURI(ROUTES.POST_PROCESSOR_SERVICES)}
                  >
                    <FontAwesomeIcon icon={faBusinessTime} fixedWidth />{' '}
                    <FormattedMessage
                      id="inventory.postProcessorServices"
                      defaultMessage="Post Processor Services"
                    />
                  </NavDropdown.Item>
                </Feature>
              </NavSubMenuItem>
            ) : (
              <>
                {mesEnabled && (
                  <NavDropdown.Item
                    eventKey={3.3}
                    href={getRouteURI(ROUTES.POST_PROCESSORS)}
                  >
                    <FontAwesomeIcon icon={faFax} fixedWidth />{' '}
                    <FormattedMessage
                      id="inventory.postProcessors"
                      defaultMessage="Post Processors"
                    />
                  </NavDropdown.Item>
                )}

                <Feature featureName={FEATURES.SERVICE_PROVIDER_AT_STEPS}>
                  <NavDropdown.Item
                    eventKey={3.4}
                    href={getRouteURI(ROUTES.POST_PROCESSOR_SERVICES)}
                  >
                    <FontAwesomeIcon icon={faBusinessTime} fixedWidth />{' '}
                    <FormattedMessage
                      id="inventory.postProcessorServices"
                      defaultMessage="Post Processor Services"
                    />
                  </NavDropdown.Item>
                </Feature>
              </>
            )
          }
          <NavDropdown.Divider className="w-100" />

          <NavSubMenuItem
            eventKey={3.5}
            route={isPowderWorkflowFeatureEnabled ? ROUTES.POST_PROCESSOR_TYPES : ROUTES.ASSET_TYPES}
            icon={faCubes}
            customIcon={(<AssetsTypesIcon />)}
            title={<FormattedMessage id="asset.types" defaultMessage="Asset Types" />}
          >

            <Feature isInverted featureName={FEATURES.POWDER_WORKFLOW}>
              <NavDropdown.Item
                eventKey={3.6}
                href={getRouteURI(ROUTES.PRINTER_TYPES)}
              >
                <PrinterTypeIcon />
                <UseNonMfgLanguageFeature
                  mfgLanguageComponent={(
                    <FormattedMessage
                      id="manage.printerTypes"
                      defaultMessage="Printer Types"
                    />
                  )}
                  nonMfgLanguageComponent={(
                    <FormattedMessage
                      id="mfg.printerTypes.productionDeviceTypes"
                      defaultMessage="Production Device Types"
                    />
                  )}
                />
              </NavDropdown.Item>
            </Feature>

            {mesEnabled && (
              <NavDropdown.Item
                eventKey={3.7}
                href={getRouteURI(ROUTES.POST_PROCESSOR_TYPES)}
              >
                <PostProcessorTypesIcon />
                <FormattedMessage
                  id="manage.postProcessorTypes"
                  defaultMessage="Post Processor Types"
                />
              </NavDropdown.Item>
            )}

          </NavSubMenuItem>
        </NavDropdown>

        <NavDropdown
          title={<Inventory />}
          id="uxNavInventory"
        >

          {mesEnabled && !materialManagementEnabled && (
            <NavDropdown.Item eventKey={4.1} href={getRouteURI(ROUTES.STOCKS)}>
              <FontAwesomeIcon icon={faBox} fixedWidth />{' '}
              <FormattedMessage id="inventory.feedstocks" defaultMessage="Feedstocks" />
            </NavDropdown.Item>
          )}

          {mesEnabled && materialManagementEnabled && (
            <NavSubMenuItem
              eventKey={4.1}
              route={ROUTES.STOCKS}
              icon={faBox}
              title={<FormattedMessage id="inventory.feedstocks" defaultMessage="Feedstocks" />}
            >

              <NavDropdown.Item
                eventKey={4.2}
                href={getRouteURI(ROUTES.MATERIAL_LOTS)}
              >
                <FontAwesomeIcon icon={faBoxOpen} fixedWidth />{' '}
                <FormattedMessage
                  id="inventory.materialLots"
                  defaultMessage="Material Lots"
                />
              </NavDropdown.Item>

              <NavDropdown.Item eventKey={4.3} href={getRouteURI(ROUTES.MATERIAL_BATCHES)}>
                <FontAwesomeIcon icon={faArchive} fixedWidth />{' '}
                <FormattedMessage
                  id="inventory.materialBatches"
                  defaultMessage="Material Batches"
                />
              </NavDropdown.Item>

              <NavDropdown.Item eventKey={4.4} href={getRouteURI(ROUTES.PERMANENT_CONTAINERS)}>
                <FontAwesomeIcon icon={faCoins} fixedWidth />{' '}
                <FormattedMessage
                  id="inventory.permanentContainers"
                  defaultMessage="Permanent Containers"
                />
              </NavDropdown.Item>
            </NavSubMenuItem>
          )}

          {mesEnabled && isToolingStockTypesFeatureEnabled && (
            <NavDropdown.Item eventKey={3.7} href={getRouteURI(ROUTES.TOOLING_STOCKS)}>
              <FontAwesomeIcon icon={faBoxesStacked} />{' '}
              <FormattedMessage
                id="record.tooling_stocks"
                defaultMessage="Tools"
              />
            </NavDropdown.Item>
          )}

          <Feature featureName={FEATURES.JENI_CLUSTER}>
            <NavDropdown.Item
              eventKey={4.5}
              href={getRouteURI(ROUTES.JENI_CLUSTERS)}
            >
              <FontAwesomeIcon icon={faPrint} fixedWidth />{' '}
              <FormattedMessage
                id="jeniClusters"
                defaultMessage="JENI Clusters"
              />
            </NavDropdown.Item>
          </Feature>

          <NavDropdown.Divider className="w-100" />

          {mesEnabled && (
            <NavDropdown.Item
              eventKey={3.8}
              href={getRouteURI(ROUTES.MATERIALS)}
            >
              <FontAwesomeIcon icon={faObjectGroup} fixedWidth />{' '}
              <FormattedMessage
                id="manage.materialTypes"
                defaultMessage="Material Types"
              />
            </NavDropdown.Item>
          )}

          {mesEnabled && isToolingStockTypesFeatureEnabled && (
            <NavDropdown.Item eventKey={4.7} href={getRouteURI(ROUTES.TOOLING_STOCK_TYPES)}>
              <FontAwesomeIcon icon={faHammer} />{' '}
              <FormattedMessage
                id="record.tooling_stock_types"
                defaultMessage="Tool Types"
              />
            </NavDropdown.Item>
          )}
        </NavDropdown>

        <NavDropdown
          title={<Organize />}
          id="uxNavOrganize"
        >
          {isDigitalDesignWarehouseFeatureEnabled && (
            <NavDropdown.Item
              eventKey={5.1}
              href={getRouteURI(ROUTES.DIGITAL_DESIGN_WAREHOUSE)}
            >
              <FontAwesomeIcon icon={faDiceD6} fixedWidth />{' '}
              <FormattedMessage
                id="manage.digitalDesignWarehouse"
                defaultMessage="Digital Design Warehouse"
              />
            </NavDropdown.Item>
          )}

          {isModelLibraryFeatureEnabled && !isDigitalDesignWarehouseFeatureEnabled && (
            <NavDropdown.Item
              eventKey={5.2}
              href={getRouteURI(ROUTES.MODEL_LIBRARY)}
            >
              <FontAwesomeIcon icon={faDiceD6} fixedWidth />{' '}
              <FormattedMessage
                id="organize.modelLibrary"
                defaultMessage="Model Library"
              />
            </NavDropdown.Item>
          )}

          {!isPowderWorkflowFeatureEnabled && <NavDropdown.Divider className="w-100" />}

          <NavSubMenuItem
            eventKey={5.3}
            route={ROUTES.TEMPLATES}
            icon={faBookOpen}
            title={<FormattedMessage id="organize.templates" defaultMessage="Templates" />}
          >
            <NavDropdown.Item eventKey={5.4} href={getRouteURI(ROUTES.ORDER_TEMPLATES)}>
              <FontAwesomeIcon icon={faEdit} fixedWidth />{' '}
              <FormattedMessage id="plan.orders" defaultMessage="Orders" />
            </NavDropdown.Item>
          </NavSubMenuItem>

          <NavDropdown.Item eventKey={5.5} href={getRouteURI(ROUTES.LOCATIONS)}>
            <FontAwesomeIcon icon={faLocationDot} fixedWidth />{' '}
            <FormattedMessage
              id="organize.locations"
              defaultMessage="Locations"
            />
          </NavDropdown.Item>

          {mesEnabled && serviceProvidersEnabled && (
            <NavDropdown.Item
              eventKey={5.6}
              href={getRouteURI(ROUTES.SERVICE_PROVIDERS)}
            >
              <FontAwesomeIcon icon={faBriefcase} fixedWidth />{' '}
              <FormattedMessage
                id="organize.serviceProviders"
                defaultMessage="Service Providers"
              />
            </NavDropdown.Item>
          )}

          {mesEnabled && isVendorServiceProviderFeatureEnabled && (
            <VisibleFor manager>
              <NavDropdown.Item
                eventKey={5.7}
                href={getRouteURI(ROUTES.VENDOR_SERVICE_PROVIDERS)}
              >
                <FontAwesomeIcon icon={faStore} fixedWidth />{' '}
                <FormattedMessage
                  id="organize.vendorServiceProviders"
                  defaultMessage="Vendor Service Providers"
                />
              </NavDropdown.Item>
            </VisibleFor>
          )}

          {mesEnabled && (
            <NavDropdown.Item
              eventKey={5.8}
              href={getRouteURI(ROUTES.SHIPPINGS)}
            >
              <FontAwesomeIcon icon={faTruckFast} fixedWidth />{' '}
              <FormattedMessage
                id="shippingPartners"
                defaultMessage="Shipping Partners"
              />
            </NavDropdown.Item>
          )}

          {mesEnabled && (
            <NavDropdown.Item
              eventKey={5.9}
              href={getRouteURI(ROUTES.CONVERSIONS)}
            >
              <FontAwesomeIcon icon={faDollarSign} fixedWidth />{' '}
              <FormattedMessage
                id="manage.currency-conversions"
                defaultMessage="Currency Conversions"
              />
            </NavDropdown.Item>
          )}

          <Feature featureName={FEATURES.AUTO_CREATE_SLICE}>
            <NavDropdown.Item
              eventKey={5.11}
              href={getRouteURI(ROUTES.SLICER_CONFIGURATIONS)}
            >
              <FontAwesomeIcon icon={faSignal} fixedWidth />{' '}
              <FormattedMessage
                id="slicer.config"
                defaultMessage="Slicer Configuration"
              />
            </NavDropdown.Item>
          </Feature>
        </NavDropdown>

        <Feature featureName={FEATURES.MACHINE_ANALYTICS}>
          <NavItem>
            <NavLink eventKey={6.1} href={getRouteURI(ROUTES.ANALYTICS, {}, {})}>
              <Analytics />
            </NavLink>
          </NavItem>
        </Feature>

        <Feature featureName={FEATURES.ANATOMICAL_MODEL}>
          <NavItem>
            <NavLink
              eventKey={6.2}
              href={getRouteURI(ROUTES.ANATOMICAL_MODELS, {}, {})}
            >
              Anatomical Models
            </NavLink>
          </NavItem>
        </Feature>
      </Nav>
    )}

    <NavProfile
      currentUser={currentUser}
      locale={locale}
      onChangeLocale={onChangeLocale}
      onLogout={onLogout}
      onImpersonateStop={onImpersonateStop}
      isStopImpersonationLoading={isStopImpersonationLoading}
      session={session}
      showLanguage={!isPOCUKOrderFieldsFeatureEnabled}
      showUnits={!isPOCUKOrderFieldsFeatureEnabled}
    />
  </Navbar.Collapse>
);
NavLinksRegular.propTypes = {
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onImpersonateStop: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired,
  maEnabled: PropTypes.bool.isRequired,
  mesEnabled: PropTypes.bool.isRequired,
  isModelLibraryFeatureEnabled: PropTypes.bool.isRequired,
  serviceProvidersEnabled: PropTypes.bool.isRequired,
  materialManagementEnabled: PropTypes.bool.isRequired,
  isPrepWorkflowEnabled: PropTypes.bool.isRequired,
  isWIPMatrixEnabled: PropTypes.bool.isRequired,
  isDigitalDesignWarehouseFeatureEnabled: PropTypes.bool.isRequired,
  isPowderWorkflowFeatureEnabled: PropTypes.bool.isRequired,
  isPOCUKOrderFieldsFeatureEnabled: PropTypes.bool.isRequired,
  isStopImpersonationLoading: PropTypes.bool.isRequired,
  isToolingStockTypesFeatureEnabled: PropTypes.bool.isRequired,
  isVendorServiceProviderFeatureEnabled: PropTypes.bool.isRequired,
};

export default NavLinksRegular;
