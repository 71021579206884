import _compact from 'lodash/compact';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _sum from 'lodash/sum';
import PropTypes from 'prop-types';
import { API_RESOURCES, QUOTE_PREVIEW_MODAL_FIELDS } from 'rapidfab/constants';
import {
  convertFromPriceToCount,
  getWorkstationTimePricePerUnit,
  isProcessStepTypeEqualTo,
  transformWorkstepQuoteDetailsForProcessStep,
} from 'rapidfab/utils/orderQuoteUtils';
import { convertSecondsToHours } from 'rapidfab/utils/timeUtils';
import React, { createContext, useCallback, useContext } from 'react';

const OrderQuoteContext = createContext(null);

export const OrderQuoteContextProvider = ({
  orderQuoteFFEnabled,
  workstepQuoteDetailsFFEnabled,
  children,
}) => {
  const transformProcessSteps = useCallback((
    processSteps,
    lineItemQuote,
    lineItemUri,
    lineItemQuantity,
    processStepTypesByUri,
    workstepCostEstimates,
    settings,
  ) => {
    if (!processSteps) return [];
    return processSteps.map(processStep => {
      const quoteDetail = processStep.work_steps_quote_details;
      const laborSetPrice = quoteDetail.labor_price_per;
      const notCounted = !quoteDetail.in_quote || !quoteDetail.in_price;
      const transformedWorkstepQuoteDetailsForProcessStep =
        transformWorkstepQuoteDetailsForProcessStep(processStep, lineItemQuote, lineItemUri, workstepCostEstimates);
      const currentProcessStepType = processStepTypesByUri[processStep.workstation_type_uri];
      const isCurrentProcessStepPostProcessorType = isProcessStepTypeEqualTo(currentProcessStepType,
        API_RESOURCES.POST_PROCESSOR_TYPE);
      const workstationTimePricePerUnit = getWorkstationTimePricePerUnit(currentProcessStepType);

      const dataToRender = {
        data: _compact([
          {
            chargeName: 'Labor',
            unit: 'Hour',
            count: laborSetPrice ?
              convertFromPriceToCount(laborSetPrice, settings?.default_labor_charge_per) :
              convertSecondsToHours(settings?.default_labor_time),
            pricePerUnit: settings?.default_labor_charge_per,
            price: laborSetPrice ||
              convertSecondsToHours(settings?.default_labor_time) *
              (settings?.default_labor_charge_per),
            id: Math.random() * 2,
          },
          {
            chargeName: 'Workstation Time',
            unit: 'Hour',
            count: isCurrentProcessStepPostProcessorType ?
              (currentProcessStepType?.duration / 3600).toFixed(2) :
              (settings?.default_workstation_time / 3600),
            pricePerUnit: workstationTimePricePerUnit,
            price: isCurrentProcessStepPostProcessorType ?
              Math.ceil((currentProcessStepType?.duration / 3600).toFixed(2) *
                workstationTimePricePerUnit) :
              (settings?.default_workstation_time / 3600).toFixed(2) *
              workstationTimePricePerUnit,
            id: Math.random() * 2,
          },
          isCurrentProcessStepPostProcessorType && ({
            chargeName: 'Piece Overhead Cost',
            unit: 'Piece',
            count: lineItemQuantity,
            pricePerUnit: transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_piece_in_run,
            price:
              transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_piece_in_run *
              lineItemQuantity,
            id: Math.random() * 2,
          }),
          isCurrentProcessStepPostProcessorType && ({
            chargeName: 'Run Overhead Cost',
            unit: 'Run',
            count: transformedWorkstepQuoteDetailsForProcessStep?.number_of_runs,
            pricePerUnit: transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_run,
            price:
              transformedWorkstepQuoteDetailsForProcessStep?.overhead_cost_per_run *
              transformedWorkstepQuoteDetailsForProcessStep?.number_of_runs,
            id: Math.random() * 2,
          }),
        ]),
        id: processStep.id,
        uuid: quoteDetail.process_step,
        updated: quoteDetail.updated,
        updated_by: quoteDetail.updated_by,
        name: processStep.name,
        notCounted,
      };

      if (quoteDetail.additional_charges.length) {
        const transformedAdditionalCharges = quoteDetail.additional_charges.map(item => ({
          chargeName: item.charge_name,
          unit: item.charge_item_units,
          pricePerUnit: item.price_per_unit,
          count: item.unit_count,
          price: item.unit_count * item.price_per_unit,
          id: Math.random() * 2,
          removable: true,
        }));

        dataToRender.data = [...dataToRender.data, ...transformedAdditionalCharges];
      }
      return dataToRender;
    });
  }, []);

  const getTransformedStep = useCallback((transformedSteps, processStep) => {
    if (processStep) {
      return transformedSteps.find(step => step.id === processStep.id);
    }
    return null;
  }, []);

  const calculateTotalPricePerPiece = useCallback(({
    laborField,
    workstationTimeField,
    additionalFields,
    pieceOverheadField,
    runOverheadField,
  }) => laborField?.pricePerUnit *
    laborField?.count +
    Math.ceil(workstationTimeField?.count *
      workstationTimeField?.pricePerUnit) +
    _sum(_map(additionalFields, 'price')) +
    (
      pieceOverheadField && runOverheadField ? (
        pieceOverheadField?.pricePerUnit +
        (runOverheadField?.pricePerUnit * runOverheadField?.count) /
        pieceOverheadField?.count
      ) : 0
    ), []);

  const getTotalPricePerPiece = useCallback((transformedSteps, processStep) => {
    const currentStep = getTransformedStep(transformedSteps, processStep);
    if (currentStep) {
      return calculateTotalPricePerPiece({
        laborField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.LABOR }),
        workstationTimeField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.WORKSTATION_TIME }),
        additionalFields: _filter(currentStep.data, { removable: true }),
        pieceOverheadField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.PIECE_OVERHEAD_COST }),
        runOverheadField: _find(currentStep.data, { chargeName: QUOTE_PREVIEW_MODAL_FIELDS.RUN_OVERHEAD_COST }),
      });
    }
    return null;
  }, [calculateTotalPricePerPiece, getTransformedStep]);

  if (!orderQuoteFFEnabled && !workstepQuoteDetailsFFEnabled) {
    return children;
  }

  return (
    <OrderQuoteContext.Provider value={{
      getTransformedStep,
      getTotalPricePerPiece,
      calculateTotalPricePerPiece,
      transformProcessSteps,
    }}
    >
      {children}
    </OrderQuoteContext.Provider>
  );
};

OrderQuoteContextProvider.propTypes = {
  orderQuoteFFEnabled: PropTypes.bool.isRequired,
  workstepQuoteDetailsFFEnabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const useOrderQuoteContext = () => useContext(OrderQuoteContext);
