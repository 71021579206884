import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import Loading from 'rapidfab/components/Loading';
import BuildPlateLineItemProductionChangeModal
  from 'rapidfab/components/records/run/BuildPlateLineItemProductionChangeModal';
import BuildPlateLineItemsMode from 'rapidfab/components/records/run/BuildPlateLineItemsMode';
import {
  API_RESOURCES, FEATURES,
  PRODUCTION_CHANGE_TYPES_VALUES,
} from 'rapidfab/constants';
import {
  getLineItemsForRun,
  getModelsByUri,
  getRunTransformations, getSpecimensForRun, getWorkflows,
  getWorkflowsByUri, isFeatureEnabled,
} from 'rapidfab/selectors';
import isFetchingInitial from 'rapidfab/utils/isFetchingInitial';
import useTrackRunTransformationStatusUpdate from 'rapidfab/hooks/useTrackRunTransformationStatusUpdate';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const BuildPlateLineItemsModeContainer = ({
  onBuildPlateModeToggle,
  isLineItemsModeEnabled,
  runRemanufacture,
  isWorkInstructionsModalShown,
  isScrapModalShown,
  onNonConformanceModalOpen,
  onScrapModalOpen,
  lineItemGeneralInfoFetched,
  run,
  onLineItemsProductionWorkflow,
  selectedLineItems,
  setSelectedLineItems,
  selectedLineItemPiecesCount,
  setSelectedLineItemPiecesCount,
  lineItemSummaryData,
  setLineItemSummaryData,
  remanufacturedWorkflowUri,
  orders,
  labelRelationshipsForLineItems,
}) => {
  // Get line items (excluding specimen) for the run
  const originalLineItems = useSelector(state => getLineItemsForRun(state, run));
  // Get only the specimens for the run
  const specimens = useSelector(state => getSpecimensForRun(state, run));
  // Combine line items and specimens
  const allLineItems = [...originalLineItems, ...specimens];

  // Exclude any Line Items / Specimen that are not fetched in API /line-items-by-run
  const lineItems = useMemo(() => {
    // If we do not have any Line Items by Run - skip next steps
    if (_isEmpty(lineItemSummaryData)) return [];
    // Get all the Line Item URIs from the Line Item Summary Data
    const summaryUris = new Set(lineItemSummaryData.map(summary => summary.line_item));

    // Return only the Line Items that are in the Line Item Summary Data
    return allLineItems.filter(item => summaryUris.has(item.uri));
  }, [allLineItems, lineItemSummaryData]);

  const isFetchingWorkflowInitial = useSelector(state =>
    (lineItems.length ?
      isFetchingInitial(state.ui.nautilus[API_RESOURCES.WORKFLOW].list) : false));
  const fetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].list.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEMS_BY_RUN].list.fetching
    || isFetchingWorkflowInitial);
  const isWorkflowFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.WORKFLOW].list.fetching);

  const modelsByUri = useSelector(getModelsByUri);
  const fetchedWorkflows = useSelector(getWorkflows);
  const workflowsByUri = useSelector(getWorkflowsByUri);
  const runTransformations = useSelector(state => getRunTransformations(state, run.uri));
  const isPowderWorkflowFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.POWDER_WORKFLOW));

  const [productionChange, setProductionChange] = useState(PRODUCTION_CHANGE_TYPES_VALUES.NONE);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isChangeInformationExpanded, setIsChangeInformationExpanded] = useState(false);
  const [lineItemChoosePiecesModalOpen, setLineItemChoosePiecesModalOpen] = useState(false);

  const reworkedPiecesFetchLimit = 25;
  const [lineItemForReworkedPieces, setLineItemForReworkedPieces] = useState(null);
  const [reworkedPiecesFetchMoreState, setReworkedPiecesFetchMoreState] = useState({ offset: 0, count: 1 });
  const [reworkedPieces, setReworkedPieces] = useState([]);

  const dispatch = useDispatch();

  const onClickProductionChange = () => {
    setLineItemChoosePiecesModalOpen(false);
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE) {
      return runRemanufacture();
    } if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE) {
      return onNonConformanceModalOpen();
    }
    if (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP) {
      return onScrapModalOpen();
    }
    return onLineItemsProductionWorkflow(dispatch);
  };

  const shouldShowReadMore = !isChangeInformationExpanded &&
    (productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP ||
      productionChange === PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE
    );

  const shouldShowMoreInfo = isChangeInformationExpanded;

  // When work instruction modal changed to closed, reset selected prints and select all checkbox
  useEffect(() => {
    if (!isWorkInstructionsModalShown) {
      setSelectAllChecked(false);
      setSelectedLineItems([]);
    }
  }, [isWorkInstructionsModalShown]);

  useEffect(() => {
    if (!isScrapModalShown) {
      setSelectAllChecked(false);
      setSelectedLineItems([]);
    }
  }, [isScrapModalShown]);

  const convertLineItemDetailsToSelectedPiecesData = () => {
    const lineItemsObject = Object.fromEntries(
      selectedLineItems.map(({ uri }) => [uri, '']),
    );
    setSelectedLineItemPiecesCount(lineItemsObject);
  };

  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);

  const checkboxDisableValues = isDisabled => {
    setIsCheckboxDisabled(isDisabled);
  };

  // to get the list of the reworked pieces - use pieces-by-run endpoint with pagination and filter

  const fetchWorkflows = async () => {
    if (lineItems.length) {
      const workflowUris = _uniq(_map(lineItems, 'workflow'));
      if (!isWorkflowFetching) {
        // Fetch only workflows that have not been fetched yet
        const unFetchedUris = workflowUris.filter(uri =>
          !fetchedWorkflows.some(workflow => workflow.uri === uri));
        if (!_isEmpty(unFetchedUris)) {
          await dispatch(Actions.Api.nautilus[API_RESOURCES.WORKFLOW].list(
            { uri: unFetchedUris, include_custom_workflows: true },
            {},
            {},
            {},
            true,
          ));
        }
      }
    }
  };

  const fetchReworkedPiecesForLineItem = async lineItemUri => {
    const pieceByRunResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PIECES_BY_RUN].list(
      { line_item: lineItemUri, run: run.uri, reworked: true },
      { limit: reworkedPiecesFetchLimit,
        offset: 0 },
      {},
      {},
      true,
    ));
    const pieces = pieceByRunResponse.json?.resources;

    setReworkedPiecesFetchMoreState(previous => (
      { offset: previous.offset + reworkedPiecesFetchLimit,
        count: pieceByRunResponse?.json.meta?.count || 0 }
    ));

    setReworkedPieces(pieces);
  };

  const fetchMoreReworkedPieces = async lineItemUri => {
    const piecesResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PIECES_BY_RUN]
      .list(
        { line_item: lineItemUri, run: run.uri, reworked: true },
        { limit: reworkedPiecesFetchLimit, offset: reworkedPiecesFetchMoreState.offset }, {}, {}, true));
    const pieces = piecesResponse.json?.resources;

    setReworkedPiecesFetchMoreState(previous => (
      { offset: previous.offset + reworkedPiecesFetchLimit, count: piecesResponse?.json.meta?.count || 0 }
    ));

    setReworkedPieces([...reworkedPieces, ...pieces]);
  };

  const onInitialize = async () => {
    const lineItemSummaryResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEMS_BY_RUN]
      .list({ run: run.uri }));
    const lineItemSummaryData = lineItemSummaryResponse.json?.resources;
    setLineItemSummaryData(lineItemSummaryData);

    const lineItemUris = _uniq(_map(lineItemSummaryData, 'line_item'));

    if (!_isEmpty(lineItemUris)) {
      // Clear Line Items cache
      await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].clear());
      const lineItemResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].list(
        // Get both, product and specimen
        { uri: lineItemUris, type: 'product,specimen' },
        {},
        {},
        {},
        true,
      ));
      const lineItems = lineItemResponse.json?.resources;

      const models = _uniq(_compact(_map(lineItems, 'additive.model')));

      if (!_isEmpty(models) && !isPowderWorkflowFeatureEnabled) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.MODEL].list(
          { uri: models },
          {},
          {},
          {},
          true,
        ));
      }
    }
  };

  // Hook is used to fetch current time data to build plate when run transformation is used
  useTrackRunTransformationStatusUpdate(onInitialize);

  const lineItemModeSelected = {
    selectedLineItemPiecesCount,
    modelsByUri,
    workflowsByUri,
    orders,
    reworkedPiecesProps: {
      reworkedPieces,
      setReworkedPieces,
      setReworkedPiecesFetchMoreState,
      lineItemForReworkedPieces,
      setLineItemForReworkedPieces,
      fetchReworkedPiecesForLineItem,
    },
    lineItemsGeneralProps: {
      selectedLineItems,
      lineItemSummaryData,
      selectedLineItemPiecesCount,
      setSelectedLineItemPiecesCount,
    },
    productionChangeProps: {
      onActionProductionChange: onClickProductionChange,
    },
  };

  const selected = {
    selectedLineItemPiecesCount,
    lineItems,
    modelsByUri,
    workflowsByUri,
    run,
    runTransformations,
    isWorkflowFetching,
    lineItemGeneralInfoFetched,
    orders,
  };

  const handleSelectAllCheckChange = ({ target: { checked } }) => {
    setSelectAllChecked(checked);
    // If select all is checked select all the prints
    let dataToSelect = [];

    if (checked) {
      // Filter out line items that are already in selectedLineItems to avoid duplication
      const newSelections = lineItems.filter(
        ({ uri }) => !selectedLineItems.some(lineItem => lineItem.uri === uri));

      // Combine the already selected line items with the new selections
      dataToSelect = [...selectedLineItems, ...newSelections];
    } else {
      const lineItemUris = new Set(lineItems.map(lineItem => lineItem.uri));
      dataToSelect = selectedLineItems.filter(lineItem => !lineItemUris.has(lineItem.uri));
    }

    setSelectedLineItems(dataToSelect);
    convertLineItemDetailsToSelectedPiecesData(dataToSelect);
  };

  useEffect(() => {
    onInitialize();
  }, [remanufacturedWorkflowUri]);

  useEffect(() => {
    fetchWorkflows();
  }, [lineItems.length]);

  // When production change type changes, reset selected prints, cut Change Information Text
  useEffect(() => {
    setSelectAllChecked(false);
    setSelectedLineItems([]);
    setSelectedLineItemPiecesCount({});
    setIsChangeInformationExpanded(false);
  }, [productionChange]);

  const chooseInformationText = () => {
    switch (productionChange) {
      case PRODUCTION_CHANGE_TYPES_VALUES.NONE:
        return 'record.run.lineItemProductionChangeInfo.none';
      case PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW:
        return `record.run.lineItemProductionChangeInfo.workflow.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE:
        return `record.run.lineItemProductionChangeInfo.remanufacture.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE:
        return `record.run.lineItemProductionChangeInfo.nonconformance.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      case PRODUCTION_CHANGE_TYPES_VALUES.SCRAP:
        return `record.run.lineItemProductionChangeInfo.scrap.${isChangeInformationExpanded ? 'expanded' : 'cut'}`;
      default:
        return 'record.run.lineItemProductionChangeInfo.none';
    }
  };

  const productionChangeModalTextData = {
    [PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW]: 'Workflow Change',
    [PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE]: 'Remanufacture',
    [PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE]: 'Flag for Non-Conformance Review',
    [PRODUCTION_CHANGE_TYPES_VALUES.SCRAP]: 'Scrap',
  };

  const onSelectPiecesModalOpen = () => {
    setLineItemChoosePiecesModalOpen(true);
    convertLineItemDetailsToSelectedPiecesData();
  };

  if (fetching) {
    return <Loading />;
  }

  return (
    <>
      <BuildPlateLineItemsMode
        {...selected}
        fetching={fetching}
        buildPlateToggleProps={{
          onBuildPlateModeToggle,
          isLineItemsModeEnabled,
        }}
        labelRelationshipsForLineItems={labelRelationshipsForLineItems}
        lineItemsProps={{
          lineItemSummaryData,
          selectedLineItems,
          setSelectedLineItems,
        }}
        productionChangeProps={{
          productionChange,
          setProductionChange,
          isCheckboxDisabled,
          isChangeInformationExpanded,
          chooseInformationText,
          shouldShowReadMore,
          shouldShowMoreInfo,
          setIsChangeInformationExpanded,
          onSelectPiecesModalOpen,
        }}
        selectAllProps={{
          selectAllChecked,
          checkboxDisableValues,
          handleSelectAllCheckChange,
        }}
      />

      <BuildPlateLineItemProductionChangeModal
        {...lineItemModeSelected}
        show={lineItemChoosePiecesModalOpen}
        onClose={() => setLineItemChoosePiecesModalOpen(false)}
        fetchMorePieces={reworkedPiecesFetchMoreState.offset < reworkedPiecesFetchMoreState.count ?
          fetchMoreReworkedPieces :
          null}
        productionChangeTitle={productionChangeModalTextData?.[productionChange]}
      />
    </>

  );
};

BuildPlateLineItemsModeContainer.propTypes = {
  onBuildPlateModeToggle: PropTypes.func.isRequired,
  isLineItemsModeEnabled: PropTypes.bool.isRequired,
  isBuildPlateLoading: PropTypes.bool.isRequired,
  run: PropTypes.shape({
    uri: PropTypes.string,
  }).isRequired,
  runRemanufacture: PropTypes.func.isRequired,
  isWorkInstructionsModalShown: PropTypes.bool.isRequired,
  isScrapModalShown: PropTypes.bool.isRequired,
  onNonConformanceModalOpen: PropTypes.func.isRequired,
  onScrapModalOpen: PropTypes.func.isRequired,
  lineItemGeneralInfoFetched: PropTypes.bool.isRequired,
  onLineItemsProductionWorkflow: PropTypes.func.isRequired,
  selectedLineItems: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
  })).isRequired,
  setSelectedLineItems: PropTypes.func.isRequired,
  selectedLineItemPiecesCount: PropTypes.objectOf(PropTypes.string).isRequired,
  setSelectedLineItemPiecesCount: PropTypes.func.isRequired,
  lineItemSummaryData: PropTypes.arrayOf(PropTypes.shape({
    line_item: PropTypes.string,
  })).isRequired,
  setLineItemSummaryData: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remanufacturedWorkflowUri: PropTypes.string.isRequired,
  labelRelationshipsForLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BuildPlateLineItemsModeContainer;
