import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import Loading from 'rapidfab/components/Loading';
import { FormattedMessage } from 'rapidfab/i18n';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import {
  DOCUMENT_RELATED_TABLE_NAMES,
  RUN_OPERATIONS,
  COLORS,
  COMMENT_RELATED_TABLE_NAMES,
} from 'rapidfab/constants';
import BuildPlateContainer from 'rapidfab/containers/records/run/BuildPlateContainer';
import RunRecordFormContainer from 'rapidfab/containers/records/run/RunRecordFormContainer';
import Documents from 'rapidfab/components/records/Documents';
import RunRecordHeader from 'rapidfab/components/records/run/RunRecordHeader';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { secureFileCheckoutResourceType } from 'rapidfab/types';
import RunData from 'rapidfab/components/records/run/RunData';
import RunRequeueButton from 'rapidfab/components/records/run/RunRequeueButton';
import RunScheduleButton from 'rapidfab/components/records/run/RunScheduleButton';
import CommentsContainer from 'rapidfab/containers/CommentsContainer';
import COMMENT_RESOURCE_TYPES from 'rapidfab/constants/CommentResourceType';
import RunToolLinks from '../tools/RunToolLinks';

const styles = {
  spacingTop: { marginTop: '1rem' },
};

const RunRecord = ({
  id,
  name,
  status,
  printCount,
  isRunEmpty,
  operation,
  uuid,
  exportControlled,
  uri,
  estimations,
  estimatesPrintTime,
  toggleEstimationModal,
  showEstimationTimeEditModal,
  onEstimationTimeChange,
  onEstimationTimeSubmit,
  isEstimationsSubmitting,
  loadPrints,
  build,
  canEditActuals,
  toggleActualsModal,
  showActualsEditModal,
  isUserManagedPrinterType,
  runMaterialData,
  materialsForRunMaterials,
  labels,
  labelRelationships,
  secureFileCheckout,
  isDisabledForm,
  isToolingStockFeatureEnabled,
  onEstMaterialWeightSubmit,
  pagination,
  filters,
  filterValues,
  listStore,
}) => (
  <Container fluid className="p-b">
    <BreadcrumbNav
      breadcrumbs={['plan', 'runs', id ? `${name} (${getShortUUID(id)})` : '']}
    />

    <RunRecordHeader
      isUserManagedPrinterType={isUserManagedPrinterType}
      operation={operation}
      name={name}
      exportControlled={exportControlled}
      estimations={estimations}
      printCount={printCount}
      status={status}
      lastSecureFileCheckoutStatus={secureFileCheckout?.status}
      estimatesPrintTime={estimatesPrintTime}
    />

    <Col xs={12} style={styles.spacingTop}>
      {isRunEmpty ? (
        <Loading />
      ) : (
        <Row>
          <Col xs={12} sm={6} md={4}>
            {operation === RUN_OPERATIONS.PRINTING && (
              <div>
                <div className="mb-1 d-flex align-items-center">
                  <b className="spacer-right">
                    <FormattedMessage
                      id="record.run.linkedToBuild"
                      defaultMessage="Linked to build"
                    />
                    :
                  </b>
                  <OverlayTrigger
                    placement="right"
                    overlay={(
                      <Tooltip id="isRunLinkedToBuildTooltip">
                        {build ? (
                          <>
                            <FormattedMessage
                              id="record.run.buildLinkedTooltip"
                              defaultMessage="Run is linked to Build:"
                            />
                            {` ${build.name} (${build.uuid})`}
                          </>
                        ) : (
                          <FormattedMessage
                            id="record.run.buildNotLinkedTooltip"
                            defaultMessage="Run is not linked to a Build."
                          />
                        )}
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon
                      icon={build ? faCheckCircle : faTimesCircle}
                      style={{
                        color: build ? COLORS.GREEN : COLORS.GRAY,
                        fontSize: 24,
                      }}
                    />
                  </OverlayTrigger>
                </div>
                {build?.carbon_footprint_estimate && (
                  <div id="carbon_footprint_estimate" className="mb15">
                    <small>
                      <b>Carbon footprint estimate: </b>
                      {build.carbon_footprint_estimate}
                    </small>
                  </div>
                )}
              </div>
            )}
            <RunRequeueButton manuallyDisabled={isDisabledForm} />
            <RunScheduleButton uuid={uuid} disabled={isDisabledForm} />
            <RunData
              isUserManagedPrinterType={isUserManagedPrinterType}
              estimates={estimations}
              estimatesPrintTime={estimatesPrintTime}
              toggleEstimationModal={toggleEstimationModal}
              showEstimationTimeEditModal={showEstimationTimeEditModal}
              handleEstimatesInputChange={onEstimationTimeChange}
              onEstimatesSubmit={onEstimationTimeSubmit}
              isEstimationsSubmitting={isEstimationsSubmitting}
              canEditActuals={canEditActuals}
              toggleActualsModal={toggleActualsModal}
              showActualsEditModal={showActualsEditModal}
              runMaterialData={runMaterialData}
              materialsForRunMaterials={materialsForRunMaterials}
              disabled={isDisabledForm}
              onEstMaterialWeightSubmit={onEstMaterialWeightSubmit}
            />
            {isToolingStockFeatureEnabled && <RunToolLinks uri={uri} />}
          </Col>
          <Col xs={12} sm={6} md={8}>
            <RunRecordFormContainer runURI={uri} printCount={printCount} />
          </Col>
          <Col xs={12}>
            <CommentsContainer
              resourceTableName={COMMENT_RELATED_TABLE_NAMES.RUN}
              resourceUUID={uuid}
              resourceType={COMMENT_RESOURCE_TYPES.RUN}
            />
          </Col>
          <Col xs={12}>
            <BuildPlateContainer
              uri={uri}
              loadPrints={loadPrints}
              labels={labels}
              labelRelationships={labelRelationships}
              filters={filters}
              filterValues={filterValues}
              pagination={pagination}
              listStore={listStore}
            />
          </Col>
          <Col xs={12} sm={6} md={8} className="flexed-pull-right">
            <Documents
              panelStyle="default"
              relatedTable={DOCUMENT_RELATED_TABLE_NAMES.RUN}
              relatedUUID={uuid}
            />
          </Col>
        </Row>
      )}
    </Col>
  </Container>
);

RunRecord.defaultProps = {
  id: null,
  name: null,
  status: null,
  printCount: null,
  operation: null,
  uuid: null,
  uri: null,
  estimations: {},
  toggleEstimationModal: () => true,
  onEstimationTimeChange: () => true,
  onEstimationTimeSubmit: () => true,
  showEstimationTimeEditModal: false,
  isEstimationsSubmitting: false,
  estimatesPrintTime: null,
  build: null,
  canEditActuals: false,
  toggleActualsModal: () => true,
  showActualsEditModal: false,
  isUserManagedPrinterType: false,
};

RunRecord.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  printCount: PropTypes.number,
  isRunEmpty: PropTypes.bool.isRequired,
  operation: PropTypes.string,
  estimatesPrintTime: PropTypes.string,
  estimations: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    baseMaterial: PropTypes.number,
    supportMaterial: PropTypes.number,
    time: PropTypes.shape({
      post_processing: PropTypes.number,
      print: PropTypes.number,
      shipping: PropTypes.number,
    }),
  }),
  onEstimationTimeSubmit: PropTypes.func,
  onEstimationTimeChange: PropTypes.func,
  toggleEstimationModal: PropTypes.func,
  showEstimationTimeEditModal: PropTypes.bool,
  isEstimationsSubmitting: PropTypes.bool,
  loadPrints: PropTypes.func.isRequired,
  uuid: PropTypes.string,
  uri: PropTypes.string,
  build: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    carbon_footprint_estimate: PropTypes.number,
  }),
  canEditActuals: PropTypes.bool,
  toggleActualsModal: PropTypes.func,
  showActualsEditModal: PropTypes.bool,
  isUserManagedPrinterType: PropTypes.bool,
  runMaterialData: PropTypes.shape({
    runMaterial: PropTypes.shape({}),
    runMaterialFetching: PropTypes.bool,
  }).isRequired,
  exportControlled: PropTypes.bool.isRequired,
  materialsForRunMaterials: PropTypes.shape({}).isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labelRelationships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  secureFileCheckout: secureFileCheckoutResourceType.isRequired,
  isDisabledForm: PropTypes.bool.isRequired,
  isToolingStockFeatureEnabled: PropTypes.bool.isRequired,
  onEstMaterialWeightSubmit: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  listStore: PropTypes.shape({}).isRequired,
};

export default RunRecord;
