import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {
  ButtonToolbar,
  FormLabel,
  Col,
  FormControl,
  FormGroup,
  Container,
  Dropdown,
  Row,
  SplitButton,
  InputGroup,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import SaveButtonTitle from 'rapidfab/components/SaveButtonTitle';
import Loading from 'rapidfab/components/Loading';
import { finalFormInputTypes } from 'rapidfab/types';
import {
  FormControlDate,
  FormControlTextArea,
} from 'rapidfab/components/formTools';
import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { ANATOMICAL_MODEL_STATUSES, ROUTES } from 'rapidfab/constants';
import { getShortUUID } from 'rapidfab/utils/uuidUtils';
import AnatomicalModelAssembliesContainer from 'rapidfab/containers/AnatomicalModel/AnatomicalModelAssembliesContainer';

import { Form, Field } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import {
  addTimeZoneToDateString,
  removeTimeZoneFromDateString,
} from 'rapidfab/utils/formHelpers';
import { faBan, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// No new translations/breadcrumbs/etc. were created for this component since it is a temporary one
// and it will be deleted once functionality is no longer needed

const DownloadButton = ({
  anatomicalModel,
  recreateBuildFile,
  isSubmitting,
}) => {
  if (!anatomicalModel || isSubmitting) {
    return null;
  }

  const recreateBuildFileButton = (
    <Button
      onClick={() => recreateBuildFile(anatomicalModel.uuid)}
      size="sm"
      disabled={
        anatomicalModel.build_file_status ===
        ANATOMICAL_MODEL_STATUSES.PROCESSING
      }
    >
      {anatomicalModel.build_file_status ===
        ANATOMICAL_MODEL_STATUSES.PROCESSING && (
        <Loading inline className="spacer-right" />
      )}
      Recreate Build File
    </Button>
  );

  if (
    [
      ANATOMICAL_MODEL_STATUSES.PENDING,
      ANATOMICAL_MODEL_STATUSES.PROCESSING,
    ].includes(anatomicalModel.build_file_status)
  ) {
    return recreateBuildFileButton;
  }

  if (anatomicalModel.build_file_status === ANATOMICAL_MODEL_STATUSES.ERROR) {
    return (
      <>
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip>
              {anatomicalModel.notes || 'Failed to generate ZIP file'}
            </Tooltip>
          )}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="btn btn-warning-outline btn-sm"
          />
        </OverlayTrigger>
        {recreateBuildFileButton}
      </>
    );
  }

  // For all other statuses - render Download link
  return (
    <>
      <a
        className="btn btn-info-outline btn-sm mb-1"
        href={anatomicalModel.build_file}
      >
        Download ZIP
      </a>
      {recreateBuildFileButton}
    </>
  );
};

DownloadButton.propTypes = {
  anatomicalModel: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    notes: PropTypes.string,
    build_file: PropTypes.string,
    build_file_status: PropTypes.string.isRequired,
  }),
  recreateBuildFile: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

DownloadButton.defaultProps = {
  anatomicalModel: null,
};

const AnatomicalModel = ({
  onSave,
  onDelete,
  isSubmitting,
  anatomicalModel,
  recreateBuildFile,
  initialFormValues,
}) => (
  <Container fluid>
    <BreadcrumbNav
      breadcrumbs={[
        {
          href: getRouteURI(ROUTES.ANATOMICAL_MODELS),
          message: 'Anatomical Models',
        },
        {
          href: '',
          message: initialFormValues.uuid ? getShortUUID(initialFormValues.uuid) : 'New',
        },
      ]}
    />
    <Form
      onSubmit={onSave}
      initialValues={initialFormValues}
      initialValuesEqual={isEqual}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="clearfix">
            <ButtonToolbar className="pull-right">
              <div className="btn-group-vertical me-1">
                <DownloadButton
                  anatomicalModel={anatomicalModel}
                  recreateBuildFile={recreateBuildFile}
                  isSubmitting={isSubmitting}
                />
              </div>
              <SplitButton
                id="uxSaveDropdown"
                type="submit"
                variant="success"
                size="sm"
                title={isSubmitting ? <Loading inline /> : <SaveButtonTitle />}
                pullRight
                disabled={isSubmitting}
              >
                <Dropdown.Item
                  eventKey={1}
                  onClick={() => onDelete(initialFormValues.uuid)}
                  disabled={!initialFormValues.uuid}
                >
                  <FontAwesomeIcon icon={faBan} />{' '}
                  <FormattedMessage id="button.delete" defaultMessage="Delete" />
                </Dropdown.Item>
              </SplitButton>
            </ButtonToolbar>
          </div>

          <hr />

          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormLabel>
                  <FormattedMessage id="field.name" defaultMessage="Name" />: *
                </FormLabel>
                <Field
                  name="name"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>
                  <FormattedMessage
                    id="field.description"
                    defaultMessage="Description"
                  />
                  :
                </FormLabel>
                <FormControlTextArea name="description" />
              </FormGroup>
              <FormGroup>
                <FormLabel>Product / Package Version:</FormLabel>
                <Field
                  name="version"
                  type="number"
                  render={props => (
                    <FormControl {...props.input} />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Scale to Print: *</FormLabel>
                <InputGroup>
                  <Field
                    name="scale_to_print"
                    type="number"
                    render={props => (
                      <FormControl {...props.input} required />
                    )}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup>
                <FormLabel>Medical Record Number: *</FormLabel>
                <Field
                  name="medical_record_number"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Case ID: *</FormLabel>
                <Field
                  name="case_id"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Date Sent:</FormLabel>
                <Field
                  name="date_sent"
                  parse={addTimeZoneToDateString}
                  format={removeTimeZoneFromDateString}
                  render={props => {
                    const field = { ...props.input, ...props.meta };
                    return (
                      <FormControlDate {...field} />
                    );
                  }}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>Accession Number: *</FormLabel>
                <Field
                  name="accession_number"
                  type="text"
                  render={props => (
                    <FormControl {...props.input} required />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>
        </form>
      )}
    />
    {anatomicalModel && (
      <Row>
        <Col xs={12} className="mt15">
          <AnatomicalModelAssembliesContainer
            anatomicalModelUri={anatomicalModel.uri}
          />
        </Col>
      </Row>
    )}
  </Container>
);

AnatomicalModel.propTypes = {
  initialFormValues: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    version: PropTypes.number,
    scale_to_print: PropTypes.number,
    medical_record_number: PropTypes.string,
    case_id: PropTypes.string,
    accession_number: PropTypes.string,
    date_sent: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  input: finalFormInputTypes.isRequired,
  meta: PropTypes.shape({}).isRequired,
  anatomicalModel: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    build_file: PropTypes.string,
    build_file_status: PropTypes.string.isRequired,
  }),
  recreateBuildFile: PropTypes.func.isRequired,
};

AnatomicalModel.defaultProps = {
  anatomicalModel: null,
};

export default AnatomicalModel;
