import { workStepDetailsReadOnlyFields } from 'rapidfab/constants/forms';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _difference from 'lodash/difference';
import * as Selectors from 'rapidfab/selectors';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import LineItemQuotePreview from 'rapidfab/components/records/order/edit/LineItemQuotePreview';
import Actions from 'rapidfab/actions';
import { FormattedMessage } from 'rapidfab/i18n';
import Loading from 'rapidfab/components/Loading';
import { API_RESOURCES,
  PAGINATION_IGNORE_DEFAULT_LIMIT, FEATURES } from 'rapidfab/constants';
import { Card } from 'react-bootstrap';

const LineItemQuoteContainer = ({
  lineItemUri,
  setLineItemViewUri,
  isWorkflowChanged,
}) => {
  const lineItemUuid = extractUuid(lineItemUri);
  const lineItem = useSelector(state => Selectors.getUUIDResource(state, lineItemUuid));
  const lineItemQuote = useSelector(state => Selectors.getLineItemQuoteByLineItemUri(state, lineItemUri));
  const workstepCostEstimates = useSelector(Selectors.getLineItemWorkstepCostEstimates);
  const postProcessorTypesByUri = useSelector(Selectors.getPostProcessorTypesByUri);
  const printerTypesByUri = useSelector(Selectors.getPrinterTypesByUri);
  const shippingsByUri = useSelector(Selectors.getShippingsByUri);
  const publicNotes = (lineItemQuote && lineItemQuote.public_notes) ? lineItemQuote.public_notes : '';
  const workflow = useSelector(state =>
    Selectors.getProductionWorkflowForLineItem(state, lineItem));
  const workflowQuoteProcessSteps = useSelector(state =>
    Selectors.getLineItemQuoteProcessStepsForLineItem(state, lineItemUri));
  const nonSeparateWorkflowProcessSteps =
    useSelector(state => Selectors.getNonSeparateInQuoteLineItemQuoteProcessStepsForLineItem(state, lineItemUri));
  const separateWorkflowProcessSteps =
    useSelector(state => Selectors.getSeparateLineItemQuoteProcessStepsForLineItem(state, lineItemUri));
  const order = useSelector(Selectors.getRouteUUIDResource);
  const orderQuoteRequired = order.quote_required;
  const modelFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.MODEL].list.fetching
    || state.ui.nautilus[API_RESOURCES.MODEL].get.fetching);
  const isLineItemQuotesLoading = useSelector(state => state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].list.fetching);
  const lineItemQuoteDataFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].post.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].delete.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].post.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_ESTIMATE].put.fetching,
  );
  const isRobozeDDWFeatureEnabled = useSelector(state => Selectors.isFeatureEnabled(state, FEATURES.ROBOZE_DDW));

  const dispatch = useDispatch();

  const onInitialize = async () => {
    /* Get line item workstep cost estimates for the current line item */
    await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_WORKSTEP_COST_ESTIMATE]
      .list({
        line_item: lineItemUri,
      }));
    const processStepProcessTypeUris = _map(workflowQuoteProcessSteps, 'workstation_type_uri');

    if (!_isEmpty(processStepProcessTypeUris)) {
      const postProcessorTypeUris =
        _filter(processStepProcessTypeUris, uri => uri.includes(API_RESOURCES.POST_PROCESSOR_TYPE));
      const printerTypeUris =
        _filter(processStepProcessTypeUris, uri => uri.includes(API_RESOURCES.PRINTER_TYPE));
      const shippingUris =
        _filter(postProcessorTypeUris, uri => uri.includes(API_RESOURCES.SHIPPING));

      if (!_isEmpty(postProcessorTypeUris)) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list({
          uri: postProcessorTypeUris,
        }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
      }

      if (!_isEmpty(printerTypeUris)) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].list({
          uri: printerTypeUris,
        }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
      }

      if (!_isEmpty(shippingUris)) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.SHIPPING].list({
          uri: shippingUris,
        }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
      }
    }
  };

  useEffect(() => {
    onInitialize();
  }, [lineItemUri]);

  const syncLineItemQuoteProcessStepsWithWorkflowProcessSteps = async () => {
    /* Process steps fetched from quote */
    const workflowQuoteProcessStepUris = _map(workflowQuoteProcessSteps, 'uri');

    /* Process steps fetched from workflow of line item */
    const workflowProcessStepUris = workflow?.process_steps;

    /* Check quote process steps and workflow process steps are synced */
    const workflowProcessStepAndQuoteDifference =
        _difference(workflowProcessStepUris, workflowQuoteProcessStepUris);
    const isQuoteProcessStepsAndWorkflowStepsSynced = !workflowProcessStepAndQuoteDifference.length;

    if (!isQuoteProcessStepsAndWorkflowStepsSynced) {
      const updatedLineItemQuoteDetails = [
        ..._map(workflowQuoteProcessSteps, step =>
          _omit(step.work_steps_quote_details, workStepDetailsReadOnlyFields)),
        ..._map(workflowProcessStepAndQuoteDifference, processStepUri => ({
          process_step: processStepUri,
          in_quote: true,
          in_price: true,
          separate: false,
          workstation_price_per: 0,
          consumable_price_per: 0,
          labor_price_per: 0,
          machine_price_per: 0,
          overhead_cost_per_piece_in_run: 0,
          overhead_cost_per_run: 0,
          number_of_runs: 0,
          additional_charges: [],
        })),
      ];

      const lineItemQuotePayload = {
        work_steps_quote_details: updatedLineItemQuoteDetails,
        public_notes: '',
      };

      if (lineItemQuote) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM_QUOTE].put(
          extractUuid(lineItemQuote?.uri),
          lineItemQuotePayload,
        ));
      }
    }
  };

  /* Workflow process-steps must sync with the line-item-quote process-steps */
  useEffect(() => {
    if (!_isEmpty(workflow)) {
      syncLineItemQuoteProcessStepsWithWorkflowProcessSteps();
    }
  }, [lineItemQuote, workflowQuoteProcessSteps]);

  const { quantity: lineItemQuantity } = lineItem ?? {};

  if (!orderQuoteRequired) {
    return null;
  }

  if (isLineItemQuotesLoading) {
    return (
      <Loading />
    );
  }

  if (isWorkflowChanged) {
    return (
      <Card bg="success">
        <FormattedMessage
          id="line_item.workflow_changed"
          defaultMessage="Production Workflow Changed. Please save changes to see updated quote preview."
        />
      </Card>
    );
  }

  return (
    <LineItemQuotePreview
      processSteps={workflowQuoteProcessSteps}
      processStepTypesByUri={{ ...postProcessorTypesByUri,
        ...printerTypesByUri,
        ...shippingsByUri }}
      separateWorkflowProcessSteps={separateWorkflowProcessSteps}
      nonSeparateWorkflowProcessSteps={nonSeparateWorkflowProcessSteps}
      publicNotes={publicNotes}
      lineItem={lineItem}
      setLineItemViewUri={setLineItemViewUri}
      lineItemQuoteDataFetching={lineItemQuoteDataFetching}
      modelFetching={modelFetching}
      lineItemQuantity={lineItemQuantity}
      lineItemQuote={lineItemQuote}
      lineItemUri={lineItem?.uri}
      workstepCostEstimates={workstepCostEstimates}
      isRobozeDDWFeatureEnabled={isRobozeDDWFeatureEnabled}
    />
  );
};

LineItemQuoteContainer.propTypes = {
  lineItemUri: PropTypes.string.isRequired,
  isWorkflowChanged: PropTypes.bool.isRequired,
  setLineItemViewUri: PropTypes.func.isRequired,
};

export default LineItemQuoteContainer;
