import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _chunk from 'lodash/chunk';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';

import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import { API_RESOURCES, FEATURES, LIST_BY_URIS_CHUNK_SIZE, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import withRecordsListHandling from 'rapidfab/containers/hocs/withRecordsListHandling';
import PostProcessorsComponent from 'rapidfab/components/assets/postProcessors';

const PostProcessorServicesContainer = props => {
  const postProcessorTypes = useSelector(Selectors.getPostProcessorTypesByUri);
  const isServiceProviderRole = useSelector(Selectors.isServiceProvider);
  const isGroupQualificationsFeatureEnabled = useSelector(state =>
    Selectors.isFeatureEnabled(state, FEATURES.GROUP_QUALIFICATIONS));

  const dispatch = useDispatch();
  const onInitialize = async () => {
    dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
    const postProcessorResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR]
      .list({ is_service: true }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }));
    const postProcessorUris = _map(postProcessorResponse.json.resources, 'uri');

    if (postProcessorUris.length) {
      const processProviderResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_PROVIDER]
        .list({ post_processor: postProcessorUris }));
      const serviceProviderUris = _uniq(_map(processProviderResponse.json.resources, 'service_provider'));
      _chunk(serviceProviderUris, LIST_BY_URIS_CHUNK_SIZE).forEach(serviceProviderUriChunk =>
        dispatch(Actions.Api.nautilus[API_RESOURCES.SERVICE_PROVIDER].list({ uri: serviceProviderUriChunk })));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await onInitialize())();
  }, []);

  return (
    <PostProcessorsComponent
      {...props}
      isServices
      postProcessorTypes={postProcessorTypes}
      isServiceProviderRole={isServiceProviderRole}
      isGroupQualificationsFeatureEnabled={isGroupQualificationsFeatureEnabled}
    />
  );
};

export default withRecordsListHandling(
  PostProcessorServicesContainer,
  Selectors.getServicePostProcessorsWithServiceProviderData,
  ['post-processor'],
);
