import { faExternalLink, faPlus, faCircleQuestion, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import TableWithSearching from 'rapidfab/components/Tables/TableWithSearching';
import MSNavImportsModal from 'rapidfab/components/modals/MSNavImportsModal';
import {
  MSNAV_IMPORT_FILE_STATUSES,
  ROUTES,
} from 'rapidfab/constants';
import { MSNAV_IMPORT_FILE_STATUSES_MAP } from 'rapidfab/mappings';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import React from 'react';
import { Button, Container, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'rapidfab/i18n';

const MSNavImports = componentProps => {
  const {
    data,
    isMsnavFileFetching,
    isOrderFetching,
    saveAndUploadMSNavFiles,
    handleReprocessMSNavFile,
    onCheckboxChange,
    transformedMSNavWorkflowData,
    transformedLineItemData,
    latestMSNavEvent,
    onModalHide,
    submitting: [isSubmitting, setIsSubmitting],
    msnavImportModalState: [showMsNavImportModal, setShowMsNavImportModal],
    isDebugModeEnabled,
  } = componentProps;

  const columns = [
    {
      type: 'custom',
      uid: 'field.name',
      accessor: 'name',
      defaultMessage: 'Name',
      isSortable: true,
      Cell: ({ row: { original: data } }) => (isDebugModeEnabled && data.content ? (
        <a href={data.content}>
          {data.name} <FontAwesomeIcon className="spacer-left" icon={faDownload} />
        </a>
      ) : data.name),
    },
    {
      type: 'date',
      uid: 'created',
      accessor: 'created',
      defaultMessage: 'Date Created',
      isSortable: true,
    },
    {
      type: 'custom',
      uid: 'field.status',
      accessor: 'status',
      defaultMessage: 'Status',
      isSortable: true,
      Cell: ({ row: { original: data } }) => {
        if (data.status === MSNAV_IMPORT_FILE_STATUSES.ERROR) {
          return (
            <>
              {MSNAV_IMPORT_FILE_STATUSES_MAP[data.status]}
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    <FormattedMessage
                      id="toaster.error.MSNavImportFileProcessing"
                      defaultMessage="There was an error processing the file. Please try reprocessing."
                    />

                  </Tooltip>
                )}
              >
                <FontAwesomeIcon className="spacer-left" icon={faCircleQuestion} />
              </OverlayTrigger>
              <Button
                size="xs"
                onClick={() => handleReprocessMSNavFile(extractUuid(data.uri), data.name)}
                variant="danger"
                className="spacer-left"
              >
                <FormattedMessage id="button.reprocess" defaultMessage="Reprocess" />
              </Button>
            </>
          );
        }
        return MSNAV_IMPORT_FILE_STATUSES_MAP[data.status] || '';
      },
    },
    {
      type: 'text',
      uid: 'field.shortId',
      accessor: 'msnav_id',
      defaultMessage: 'Short ID',
    },
    {
      type: 'custom',
      uid: 'field.workflow_name',
      accessor: 'workflow_name',
      defaultMessage: 'Workflow Name',
      isSortable: true,
      Cell: ({ row: { original: data } }) => {
        const relatedWorkflows = transformedMSNavWorkflowData.filter(transformedMSNavWorkflow => (
          data.msnav_workflows?.includes(transformedMSNavWorkflow.uri)
        ));

        return relatedWorkflows.map(relatedWorkflow => (
          <a href={getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: extractUuid(relatedWorkflow?.workflow?.uri) })}>
            {relatedWorkflow?.workflow?.name}
            {' '}
            {relatedWorkflow?.workflow?.name && <FontAwesomeIcon className="spacer-left" icon={faExternalLink} />}
          </a>
        ));
      },
    },
    {
      type: 'custom',
      uid: 'field.linked_order',
      accessor: 'linked_order',
      defaultMessage: 'Linked Order',
      isSortable: true,
      Cell: ({ row: { original: data } }) => {
        const relatedWorkflows = transformedMSNavWorkflowData.filter(transformedMSNavWorkflow => (
          data.msnav_workflows?.includes(transformedMSNavWorkflow.uri)
        ));
        const relatedWorkflowUris = _map(relatedWorkflows, 'workflow.uri');

        if (isOrderFetching) {
          return (
            <span>
              <FormattedMessage
                id="status.pending"
                defaultMessage="Pending"
              />
            </span>
          );
        }

        if (!transformedLineItemData?.length) {
          return <span>No Linked Order</span>;
        }

        const relatedLineItems = transformedLineItemData?.filter(lineItem => (
          relatedWorkflowUris.includes(lineItem.workflow)
        ));

        return (
          <a href={getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(relatedLineItems[0]?.order?.uri) })}>
            {relatedLineItems[0]?.order?.name}
            {relatedLineItems[0]?.order && <FontAwesomeIcon className="spacer-left" icon={faExternalLink} />}
          </a>
        );
      },
    },
  ];

  const NavbarLinks = (
    <Button
      variant="primary"
      size="sm"
      onClick={() => setShowMsNavImportModal(true)}
      className="pull-right"
    >
      <FontAwesomeIcon icon={faPlus} />{' '}
      <FormattedMessage
        id="msnav.uploadCsv"
        defaultMessage="Upload MSNav CSV"
      />
    </Button>
  );

  const NavbarLeadingContent = ([
    <Form.Check
      name="toggleArchived"
      onChange={onCheckboxChange}
      type="checkbox"
      label="Hide Archived"
    />,
    <Form.Check
      name="toggleCompleted"
      onChange={onCheckboxChange}
      type="checkbox"
      label="Hide Completed"
    />,
  ]);

  return (
    <Container fluid>
      {showMsNavImportModal && (
        <MSNavImportsModal
          submitting={[isSubmitting, setIsSubmitting]}
          isMsnavFileFetching={isMsnavFileFetching}
          saveAndUploadMSNavFiles={saveAndUploadMSNavFiles}
          onHide={onModalHide}
          latestMSNavEvent={latestMSNavEvent}
        />
      )}
      <TableWithSearching
        {...componentProps}
        withBreadcrumbs
        navbar={NavbarLinks}
        navbarLeading={NavbarLeadingContent}
        data={data}
        columns={columns}
        breadcrumbs={['admin', 'msnavImports']}
        isFilteringEnabled={false}
        showLocationsFilter={false}
        isManualSoringEnabled={false}
        isUpdatedColumnShown={false}
        initialSortedDesc
        initialSortedColumn="created"
      />
    </Container>
  );
};

export default MSNavImports;

MSNavImports.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      msnav_workflows: PropTypes.arrayOf(PropTypes.shape({})),
      status: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      notes: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
