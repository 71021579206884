import {
  faArrowDownShortWide,
  faEdit, faGear,
  faInfoCircle,
  faQuestionCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _some from 'lodash/some';
import { capitalize } from 'lodash/string';
import PropTypes from 'prop-types';
import { ExportControlWarningIcon } from 'rapidfab/components/ExportControl';
import Loading from 'rapidfab/components/Loading';
import ModalLightboxModel from 'rapidfab/components/ModelThumbnail';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import {
  MODEL_LIBRARY_TYPES,
  PRODUCTION_CHANGE_TYPES_VALUES,
  ROUTES, RUN_STATUSES,
  RUN_TRANSFORMATION_STATUSES,
  TEXT_COLOR_CONTRAST,
  WORKFLOW_TYPES,
} from 'rapidfab/constants';
import { rcTooltipInnerStyleList } from 'rapidfab/constants/styles';
import { FormattedMessage } from 'rapidfab/i18n';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING } from 'rapidfab/mappings';
import { pluralWord } from 'rapidfab/utils/stringUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'rapidfab/styles/componentStyles/build-plate-line-item-mode.scss';

const BuildPlateLineItem = ({
  lineItem,
  workflow,
  model,
  productionChange,
  run,
  runTransformations,
  lineItemSummaryData,
  selectedLineItems,
  setSelectedLineItems,
  checkboxDisableValues,
  isNoProductionChangeSelected,
  isWorkflowFetching,
  ordersByUri,
  labelRelationshipsForLineItems,
}) => {
  if (!lineItem || !workflow) return null;

  const {
    uri: lineItemUri,
    type: lineItemType,
  } = lineItem;

  const isSpecimen = lineItemType === MODEL_LIBRARY_TYPES.SPECIMEN;
  const exportControlLabelRelationship = _find(labelRelationshipsForLineItems, { target_uri: lineItemUri }) ?? null;
  const exportControlLabel = exportControlLabelRelationship?.label;

  const lineItemPieceCounts = lineItemSummaryData.find(({ line_item }) => line_item === lineItemUri);

  const {
    total_piece_quantity: allPieceCount,
    original_piece_quantity: originalPiecesCount,
  } = lineItemPieceCounts ?? {};

  const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem.status];
  const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];

  const isScrapChange = productionChange === PRODUCTION_CHANGE_TYPES_VALUES.SCRAP;
  const isWorkflowChange = productionChange ===
    PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW ||
    productionChange === PRODUCTION_CHANGE_TYPES_VALUES.NONCONFORMANCE;
  const isRemanufactureChange = productionChange === PRODUCTION_CHANGE_TYPES_VALUES.REMANUFACTURE;

  const onCheckboxChange = event => {
    let newLineItemsToChange = selectedLineItems;
    if (event.target.checked) {
      newLineItemsToChange = [...newLineItemsToChange, lineItem];
    } else {
      newLineItemsToChange = _filter(selectedLineItems, lineItem => lineItem.uri !== lineItemUri);
    }
    setSelectedLineItems(newLineItemsToChange);

    // Immediately disable checkboxes with different workflows
    const selectedWorkflow = newLineItemsToChange.length > 0 ? newLineItemsToChange[0].workflow : null;
    const isDifferentWorkflow = selectedWorkflow && selectedWorkflow !== lineItem.workflow;
    checkboxDisableValues(!isScrapChange && isDifferentWorkflow);
  };

  const checked = _find(selectedLineItems, { uri: lineItemUri });

  const selectedWorkflow = selectedLineItems.length > 0 ? selectedLineItems[0].workflow : null;
  const isWorkflowMismatch = (selectedWorkflow && selectedWorkflow !== lineItem.workflow);

  /* You should not be able to change the workflow if the current run status is NOT:
   - 'In Progress'
   - 'Complete' */
  const isNotAllowedChangingWorkflow = productionChange === PRODUCTION_CHANGE_TYPES_VALUES.WORKFLOW
    && Object.values(RUN_STATUSES)
      .filter(status => ![RUN_STATUSES.IN_PROGRESS, RUN_STATUSES.COMPLETE].includes(status)).includes(run?.status);

  const isSpecimenWorkflow = workflow.type === WORKFLOW_TYPES.SPECIMEN;

  const remanufactureNotAllowedDueToRunStatus = isRemanufactureChange && Object.values(RUN_STATUSES)
    .filter(status => ![RUN_STATUSES.COMPLETE, RUN_STATUSES.ERROR].includes(status)).includes(run?.status);

  const showSpecimenError = isWorkflowChange
    && isSpecimenWorkflow;

  // Remanufacture is incomplete when at least one run transformation is not completed
  const hasIncompleteRemanufactures = _some(
    runTransformations,
    ({ status }) => status !== RUN_TRANSFORMATION_STATUSES.COMPLETE,
  );

  const noPiecesAvailableForProductionChange = productionChange !== PRODUCTION_CHANGE_TYPES_VALUES.NONE
    && !originalPiecesCount;

  const isCheckboxDisabled = isNoProductionChangeSelected
    || showSpecimenError
    || noPiecesAvailableForProductionChange
    || (hasIncompleteRemanufactures && isRemanufactureChange)
    || isNotAllowedChangingWorkflow
    || remanufactureNotAllowedDueToRunStatus
    || (!isScrapChange && isWorkflowMismatch);

  checkboxDisableValues(isCheckboxDisabled);

  const renderProductionChangeCheckbox = disabled => (
    <Form.Check
      className="m-t-0 mb0 outlined-checkbox production-change-checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onCheckboxChange}
      type="checkbox"
    />
  );

  const renderLineItemViewRightContent = () => (
    <div className="buildPlate-lineItemRightContent">
      <p className="mb0 d-flex align-items-center justify-content-end">
        <span className="mr5 font-weight-500 text-white">Production Change</span>
        {renderProductionChangeCheckbox(isCheckboxDisabled)}

        {!isScrapChange && isWorkflowMismatch && (
          <OverlayTrigger
            className="ml15"
            placement="left"
            overlay={(
              <Tooltip>
                <FormattedMessage
                  id="record.run.workflowMismatchLineItemTooltipWarning"
                  defaultMessage="Production changes can only be initiated for Line Items with the same workflow."
                />
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faWarning} color="#FFA500" className="mt3 ml5" />
          </OverlayTrigger>
        )}

        {showSpecimenError && (
          <OverlayTrigger
            className="ml15"
            placement="left"
            overlay={(
              <Tooltip>
                <FormattedMessage
                  id="record.run.noSpecimenWorkflowChange"
                  defaultMessage="Unable to change Workflow for specimen"
                />
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faQuestionCircle} className="mt3 ml5" />
          </OverlayTrigger>
        )}

        {noPiecesAvailableForProductionChange && (
          <OverlayTrigger
            className="ml15"
            placement="left"
            overlay={(
              <Tooltip>
                <FormattedMessage
                  id="record.run.noLineItemPiecesAvailable"
                  defaultMessage="No pieces available for Production Change"
                />
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="mt3 ml5" />
          </OverlayTrigger>
        )}

        {remanufactureNotAllowedDueToRunStatus && (
          <OverlayTrigger
            className="ml15"
            placement="left"
            overlay={(
              <Tooltip>
                <FormattedMessage
                  id="record.run.remanufactureNotAllowedWarning"
                  defaultMessage="Remanufacture can only be initiated after a completed run (successful or error)"
                />
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faQuestionCircle} className="mt3 ml5" />
          </OverlayTrigger>
        )}

        {/* TODO: When isTheLatestCompleteRun validation is ready - change this tooltip
               to 185 Line of ProductionChangeColumn.jsx file */}
        {isNotAllowedChangingWorkflow && (
          <OverlayTrigger
            className="ml15"
            placement="left"
            overlay={(
              <Tooltip>
                <FormattedMessage
                  id="record.run.productionChangeNotAllowed"
                  defaultMessage="Production changes can only be initiated for runs in progress or completed."
                />
              </Tooltip>
            )}
          >
            <FontAwesomeIcon icon={faQuestionCircle} className="mt3 ml5" />
          </OverlayTrigger>
        )}

        {
          hasIncompleteRemanufactures && isRemanufactureChange && (
            <OverlayTrigger
              className="ml15"
              placement="left"
              overlay={(
                <Tooltip>
                  <FormattedMessage
                    id="record.run.remanufactureIncomplete"
                    defaultMessage="Remanufacture is incomplete. Please complete all run transformations."
                  />
                </Tooltip>
              )}
            >
              <FontAwesomeIcon icon={faQuestionCircle} className="mt3 ml5" />
            </OverlayTrigger>
          )
        }

      </p>
      <p className="font-weight-200">
        {allPieceCount !== originalPiecesCount ? (
          <>
            {originalPiecesCount} of {allPieceCount} {pluralWord('piece', allPieceCount, 's', true)} available
            <RCTooltip
              placement="bottom"
              destroyTooltipOnHide
              overlayInnerStyle={{ ...rcTooltipInnerStyleList, width: 'max-content', textAlign: 'center' }}
              mouseLeaveDelay={0.4}
              overlay={(
                <div>
                  <div><strong>{allPieceCount - originalPiecesCount} {pluralWord('piece', originalPiecesCount, 's', true)}</strong> have Production Changes.</div>
                  <div>These Pieces will be skipped during any additional Production Changes for this Line Item.</div>
                  <div>Use the Piece View to find and interact with these specific Pieces.</div>
                </div>
              )}
            >
              <FontAwesomeIcon icon={faInfoCircle} color="#FFA500" className="spacer-left" />
            </RCTooltip>
          </>

        ) : (
          <span>
            {originalPiecesCount} {pluralWord('piece', originalPiecesCount, 's', true)}
          </span>
        )}
      </p>
    </div>
  );

  return (
    <>
      <div className="buildPlate-lineItem">
        <div className="buildPlate-lineItemLeftContent">
          <div className="buildPlate-lineItemSnapshot">
            <ModalLightboxModel
              snapshot={model?.snapshot_content}
              customBtnClass="buildPlate-lineItemSnapshotContent"
              customImgClass="buildPlate-lineItemSnapshotContentImage"
              piecesCount={allPieceCount}
            />
          </div>
          <div className="buildPlate-lineItemDetails">
            <div className="font-weight-500 text-white mb5">
              <div className="d-flex align-items-center">
                <TruncatedTitle
                  title={lineItem.name}
                  maxTextLength={60}
                  inline
                />
                {exportControlLabel && (
                  <ExportControlWarningIcon
                    isLineItem
                    exportControlLabel={exportControlLabel}
                  />
                )}
              </div>

            </div>
            {!isSpecimen && (
              <div className="d-flex align-items-center mb5 font-weight-500">
                <span
                  className="badge badge-sm"
                  style={{
                    backgroundColor: labelBgColor,
                    color: labelTextColor,
                  }}
                >
                  {capitalize(lineItem.status)}
                </span>
              </div>
            )}
            {isWorkflowFetching ? <Loading inline /> : (
              <div>
                <FontAwesomeIcon icon={faArrowDownShortWide} className="spacer-right" />
                <Link to={getRouteURI(ROUTES.WORKFLOW_EDIT, { uuid: extractUuid(workflow.uri) }, {}, true)}>
                  <TruncatedTitle
                    title={workflow.name}
                    maxTextLength={80}
                    inline
                  />
                </Link>
              </div>
            )}
            {ordersByUri[lineItem.order]?.uri && !isSpecimen && (
              <div>
                <FontAwesomeIcon icon={faEdit} className="spacer-right" />
                <Link to={getRouteURI(ROUTES.ORDER_EDIT,
                  { uuid: extractUuid(ordersByUri[lineItem.order].uri) }, {}, true)}
                >
                  <TruncatedTitle
                    title={ordersByUri[lineItem.order].name || 'N/A'}
                    maxTextLength={80}
                    inline
                  />
                </Link>
              </div>
            )}
            {isSpecimen && (
              <div>
                <FontAwesomeIcon icon={faGear} className="spacer-right" />
                These are specimen(s)
                <RCTooltip
                  placement="top"
                  destroyTooltipOnHide
                  overlayInnerStyle={{ ...rcTooltipInnerStyleList, width: 'max-content', textAlign: 'center' }}
                  mouseLeaveDelay={0.4}
                  overlay={(
                    <div>
                      <div>These Specimen(s) were added to the Run automatically based
                        on the Workflows of some other Pieces in the Run.
                      </div>
                      <div>Please inspect the Workflows of the non-specimen Pieces to
                        determine where they were requested.
                      </div>
                    </div>
                  )}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="spacer-left spacer-right" />
                </RCTooltip>
              </div>
            )}
          </div>
        </div>
        {renderLineItemViewRightContent()}
      </div>
    </>
  );
};

BuildPlateLineItem.defaultProps = {
  selectedLineItems: [],
  setSelectedLineItems: () => {},
  checkboxDisableValues: () => {},
  isNoProductionChangeSelected: false,
  run: {},
  productionChange: '',
  runTransformations: [],
  labelRelationshipsForLineItems: [],
};

BuildPlateLineItem.propTypes = {
  lineItem: PropTypes.shape({
    uri: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    order: PropTypes.string,
    name: PropTypes.string,
    workflow: PropTypes.string,
  }).isRequired,
  workflow: PropTypes.shape({
    name: PropTypes.string,
    uri: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  model: PropTypes.shape({
    snapshot_content: PropTypes.string,
  }).isRequired,
  lineItemSummaryData: PropTypes.arrayOf(PropTypes.shape({
    line_item: PropTypes.string,
  })).isRequired,
  selectedLineItems: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string,
    workflow: PropTypes.string,
  })),
  setSelectedLineItems: PropTypes.func,
  checkboxDisableValues: PropTypes.func,
  isNoProductionChangeSelected: PropTypes.bool,
  run: PropTypes.shape({
    status: PropTypes.string,
  }),
  productionChange: PropTypes.string,
  runTransformations: PropTypes.arrayOf(PropTypes.shape({})),
  isWorkflowFetching: PropTypes.bool.isRequired,
  ordersByUri: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  labelRelationshipsForLineItems: PropTypes.arrayOf(PropTypes.shape({
    target_uri: PropTypes.string,
    label: PropTypes.string,
  })),
};

export default BuildPlateLineItem;
