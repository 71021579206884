import usePrevious from 'rapidfab/hooks';
import usePagination from 'rapidfab/hooks/usePagination';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import _map from 'lodash/map';
import _flatMap from 'lodash/flatMap';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';

import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  BUILD_PACKER_TYPES,
  PAGINATION_IGNORE_DEFAULT_LIMIT,
  RUN_STATUSES,
  USER_ROLES,
  LIST_BY_URIS_CHUNK_SIZE,
  FEATURES,
  SECURE_FILE_CHECKOUT_STATUSES,
  MATERIAL_UNITS,
  PIECE_GROUPS_FILTER,
  RUN_OPERATIONS,
} from 'rapidfab/constants';
import Actions from 'rapidfab/actions';

import RunRecord from 'rapidfab/components/records/run/RunRecord';
import { hhmmss, parseHhmmss } from 'rapidfab/utils/timeUtils';
import Alert from 'rapidfab/utils/alert';
import { runType } from 'rapidfab/types';
import {
  getRouteUUID,
  getRunEstimateForRun,
  getRunPrints,
  getUUIDResource,
  isFeatureEnabled,
} from 'rapidfab/selectors';
import _chunk from 'lodash/chunk';
import { FormattedMessage } from 'react-intl';

import { buildURIFromUUID } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { loadExportControlData } from 'rapidfab/dispatchers/labelRelationship';
import { convertMassToOtherUnit } from '../../../utils/mathUtils';

const RunRecordContainer = props => {
  const [showEstimationTimeEditModal, setShowEstimationTimeEditModal] = useState(false);
  const [estimatesPrintTime, setEstimatesPrintTime] = useState('');
  const [isEstimationsSubmitting, setIsEstimationsSubmitting] = useState(false);
  const [showActualsEditModal, setShowActualsEditModal] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const uuid = useSelector(state => getRouteUUID(state));
  const run = useSelector(state => Selectors.getUUIDResource(state, uuid));
  const runMaterial = useSelector(state => Selectors.getRunMaterialsForRun(state, run));
  const materialsForRunMaterials = useSelector(Selectors.getMaterialsByUri);
  const runMaterialFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.RUN_MATERIAL].get.fetching);
  const prints = useSelector(state => getRunPrints(state, run));
  const currentUserRole = useSelector(Selectors.getCurrentUserRole);
  const isRunEmpty = !run || initialLoading;
  const build = useSelector(state => Selectors.getBuildForRun(state, run));
  const estimates = useSelector(Selectors.getRunEstimates);
  const runEstimates = useSelector(state => getRunEstimateForRun(state, run));
  const printerType = useSelector(state => getUUIDResource(state, extractUuid(run?.printer_type)));
  const labels = useSelector(Selectors.getLabels);
  const labelRelationships = useSelector(state =>
    Selectors.getLabelRelationshipsForPieceUris(state,
      _map(prints, 'piece'),
    ));
  const isPOCUKOrderFieldsFeatureEnabled = useSelector(
    state => Selectors.isFeatureEnabled(state, FEATURES.POC_UK_ORDER_FIELDS),
  );
  const secureFileCheckout = useSelector(state => Selectors.getLastSecureFileCheckoutForRun(state, uuid));
  const secureFileCheckoutStatuses = new Set([
    SECURE_FILE_CHECKOUT_STATUSES.COMPLETED,
    SECURE_FILE_CHECKOUT_STATUSES.ERROR,
  ]);

  // Only show Secure File Checkout for printing runs
  const isDisabledForm = isPOCUKOrderFieldsFeatureEnabled ?
    (run?.operation === RUN_OPERATIONS.PRINTING &&
      !!secureFileCheckout &&
      !secureFileCheckoutStatuses.has(secureFileCheckout?.status)) :
    !!secureFileCheckout && !secureFileCheckoutStatuses.has(secureFileCheckout?.status);

  const isSecureFileCheckoutFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.SECURE_FILE_CHECKOUT),
  );
  const isToolingStockFeatureEnabled = useSelector(
    state => isFeatureEnabled(state, FEATURES.TOOLING_STOCK),
  );

  const [globalFilter, setGlobalFilter] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [piecesGroupsFilter, setPiecesGroupsFilter] = useState(PIECE_GROUPS_FILTER.ALL);
  const [listStore, setListStore] = useState(null);

  const {
    paginationState,
    setTotalItems,
    setPage,
    nextPage,
    prevPage,
    totalPaginatedPages,
    goToPage,
    onLimitChange,
  } = usePagination(25);

  const runInfo = {
    id: run?.id,
    operation: run?.operation,
    uri: run?.uri,
    name: run?.name,
    status: run?.status,
    printCount: paginationState?.totalItems || 0,
  };

  const selected = {
    uuid,
    build,
    isRunEmpty,
    estimates,
    materialsForRunMaterials,
    runMaterialData: {
      runMaterial,
      runMaterialFetching,
    },
    run,
    // TODO: Use `run` object directly instead
    ...(run
      ? runInfo
      : null),
    labels,
    labelRelationships,
  };

  const dispatch = useDispatch();

  const getPiecesGroupsFilter = () => {
    const filterMap = {
      [PIECE_GROUPS_FILTER.ALL]: {},
      [PIECE_GROUPS_FILTER.ORIGINAL_PIECES_ONLY]: { reworked: false },
      [PIECE_GROUPS_FILTER.PRODUCTION_CHANGED_PIECES]: { reworked: true },
    };

    return filterMap[piecesGroupsFilter] || {};
  };

  /* The API will send the pieces uris as chunks (several requests)
   and disable the pagination filter to have all the resources. */
  const loadPrintsByPiecesUris = piecesUris => {
    const promises = [];
    _chunk(piecesUris, LIST_BY_URIS_CHUNK_SIZE).forEach(pieceUri => {
      promises.push(dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT]
        .list({ piece: pieceUri, work_needed: false }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }, {}, {}, true)));
    });

    return Promise.all(promises);
  };

  const loadPiecesByRun = uri => {
    if (!uri) return;

    const filter = {
      ...getPiecesGroupsFilter(),
      run: uri,
    };

    const search = {};

    if (globalFilter) {
      search.multicolumn_search = globalFilter.trim();
    }

    const piecesByRunRequest = dispatch(
      Actions.Api.nautilus[API_RESOURCES.PIECES_BY_RUN].list(
        { ...filter },
        { limit: paginationState.pageLimit,
          offset: paginationState.offset > 0 ? paginationState.offset : 0 },
        {},
        { ...search },
        true));

    piecesByRunRequest.then(piecesByRunResponse => {
      setTotalItems(piecesByRunResponse.json?.meta?.count);
      setListStore({
        ...piecesByRunResponse,
        ...piecesByRunResponse.json,
      });
      const responsePiecesByRun = piecesByRunResponse.json.resources;
      const pieceUris = _uniq(_map(responsePiecesByRun, 'piece'));

      if (pieceUris.length) {
        loadExportControlData(dispatch, pieceUris);
      }

      if (pieceUris.length) {
        // There might be cases when no prints are left in the run. E.g. those were removed via QR App
        // So request pieces only if there are any uris found
        dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
          { uri: pieceUris },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        )).then(pieceResponse => {
          const pieceResponseData = pieceResponse.json.resources;
          const workflowUris = _uniq(_map(pieceResponseData, 'workflow'));
          const remanufactureAvailablePrints = _filter(_uniq(_map(responsePiecesByRun, 'remanufactured_to')), print => print !== undefined);

          // Needs to be refactored when Backend gets a change
          if (remanufactureAvailablePrints) {
            remanufactureAvailablePrints?.forEach(UUID => {
              const uri = buildURIFromUUID(API_RESOURCES.PRINT, UUID);
              dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
                { uri },
                { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                {},
                {},
                true,
              )).then(response => {
                const pieceURI = response?.json?.resources[0]?.piece;
                dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
                  { uri: pieceURI },
                  { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                  {},
                  {},
                  true,
                ));
              });
            });

            // needs backend update to work correctly because remanufactured_to arent uris they are uuids

          //   dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].list(
          //     { uri: remanufactureAvailablePrints },
          //     { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          //     {},
          //     {},
          //     true,
          //   )).then(response => {
          //     const pieceURI = response?.json?.resources[0]?.piece;
          //     dispatch(Actions.Api.nautilus[API_RESOURCES.PIECE].list(
          //       { uri: pieceURI },
          //       { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          //       {},
          //       {},
          //       true,
          //     ));
          //   });
          }

          dispatch(Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list({ workflows: workflowUris }));

          const lineItemUris = _uniq(_map(pieceResponseData, 'line_item'));

          if (lineItemUris.length) {
            // There might be cases when no prints are left in the run. E.g. those were removed via QR App
            // So request line items only if there are any uris found
            dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].list(
              { uri: lineItemUris },
              { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
              {},
              {},
              true,
            ));
          }
        });
      }

      dispatch(Actions.Api.nautilus[API_RESOURCES.PRINT].clear('list'));
      loadPrintsByPiecesUris(pieceUris)
        .then(printResponses => {
          const allPrints = _flatMap(printResponses, mappedPrints => mappedPrints.json.resources);
          const firstStepPrints = _filter(allPrints, { process_step_position: 1 });
          const runUris = _uniq(_map(firstStepPrints, 'run'))
            .filter(Boolean);
          if (runUris.length > 0) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].list({ uri: runUris }, {}, {}, {}, true));
            dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
            dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR].list());
          }
        });
    });
  };

  const onInitialize = () => dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(uuid, true))
    .then(response => {
      if (response.json?.post_processor_type) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.POST_PROCESSOR_TYPE].list());
      }

      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS].list(
        { run: response.json.uri },
        // Assuming there is 1-1 relation for run<->runActuals
        { limit: 1 },
      ));

      loadPiecesByRun(response.json.uri);

      dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].list(
        { run: response.json.uri },
      ));

      if (response.json.orders.length) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
          { uri: response.json.orders },
          { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
          {},
          {},
          true,
        ));
      }
      dispatch(Actions.Api.nautilus[API_RESOURCES.BUILD].list({ run: response.json.uri }));
      if (isSecureFileCheckoutFeatureEnabled) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.SECURE_FILE_CHECKOUT].list(
          { resource_uuid: uuid, related_table_name: 'run' },
        ));
        dispatch(Actions.Api.nautilus[API_RESOURCES.SECURE_CHECKOUT_DIRECTORY].list());
      }
    });

  const previousPage = usePrevious(paginationState.activePage);
  const previousPageLimit = usePrevious(paginationState.pageLimit);
  const previousGlobalFilter = usePrevious(globalFilter);
  const previousPieceGroupsFilter = usePrevious(piecesGroupsFilter);

  // Re-fetch pieces only if page or page limit has changed
  useEffect(() => {
    if (previousPage !== paginationState.activePage
      || previousPageLimit !== paginationState.pageLimit) {
      loadPiecesByRun(run?.uri);
    }
  }, [
    run?.uri,
    paginationState.pageLimit,
    paginationState.offset,
    paginationState.activePage,
  ]);

  // Re-fetch pieces if the filters were changed and reset offset if applicable
  useEffect(() => {
    if (globalFilter !== previousGlobalFilter
      || piecesGroupsFilter !== previousPieceGroupsFilter) {
      // On each filter change, reset the page to 0 with the applicable filters
      if (paginationState.activePage !== 0) {
        setPage(0);
      } else {
        // If we already on the first page, just re-fetch the pieces with the removed / updated filters
        loadPiecesByRun(run?.uri);
      }
    }
  }, [
    paginationState.activePage,
    globalFilter,
    piecesGroupsFilter,
  ]);

  const previousRunUri = usePrevious(run?.uri);

  useEffect(() => {
    if (run?.uri && previousRunUri !== run.uri) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_MATERIAL].get(extractUuid(run.uri), true))
        .then(runMaterialResponse => {
          const runMaterial = runMaterialResponse.json;
          const runMaterialUris = _map(runMaterial?.materials, 'uri');
          if (runMaterialUris.length) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ uri: runMaterialUris }));
          }
        });
    }
  }, [printerType, run?.uri]);

  useEffect(() => {
    if (runEstimates?.estimates) {
      const estimatesPrintSeconds = runEstimates.estimates.time.run_duration;
      const currentEstimatesPrintTime = hhmmss(estimatesPrintSeconds, true);
      setEstimatesPrintTime(currentEstimatesPrintTime);
    } else {
      setEstimatesPrintTime('');
    }
  }, [runEstimates]);

  useEffect(() => {
    setInitialLoading(true);
    onInitialize().finally(() => setInitialLoading(false));
  }, [uuid, isSecureFileCheckoutFeatureEnabled]);

  const onEstimationTimeChange = event => {
    setEstimatesPrintTime(event.target.value);
  };

  const onEstimationTimeSubmit = event => {
    event.preventDefault();
    const estimatedPrintSeconds = dayjs.duration(parseHhmmss(estimatesPrintTime)).asSeconds();

    const payload = {
      estimates: {
        time: {
          run_duration: estimatedPrintSeconds,
        },
      },
    };

    setIsEstimationsSubmitting(true);

    dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].put(runEstimates.uuid, payload))
      .then(() => {
        Alert.success(<FormattedMessage
          id="toaster.scheduleRuns.estimationsUpdated"
          defaultMessage="Estimations successfully updated"
        />);
        dispatch(Actions.Api.nautilus[API_RESOURCES.SCHEDULE_RUNS].get(runEstimates.uuid))
          .then(() => {
            setIsEstimationsSubmitting(false);
            setShowEstimationTimeEditModal(false);
          });
      })
      .catch(() => {
        setIsEstimationsSubmitting(false);
      });
  };
  const onEstMaterialWeightSubmit = async values => {
    const { weight } = values;
    let baseMaterialUsed = null;
    if (weight) {
      const { units, density } = materialsForRunMaterials[runMaterial?.materials[0].uri];
      baseMaterialUsed = convertMassToOtherUnit(
        +weight, units, MATERIAL_UNITS.GRAM,
      ) / density;
    }
    try {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.RUN_MATERIAL].put(uuid, { estimate_base_material_used: baseMaterialUsed }),
      );
      await dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_MATERIAL].get(uuid, true));
      Alert.success(<FormattedMessage
        id="toaster.runMaterial.weightUpdated"
        defaultMessage="Material weight successfully updated"
      />);
    } catch {
      return null;
    }
    return null;
  };

  const toggleEstimationModal = () => setShowEstimationTimeEditModal(previous => !previous);
  const toggleActualsModal = () => setShowActualsEditModal(previous => !previous);

  const estimations = {
    ...(runEstimates?.estimates || runEstimates),
    // If the Run was manually scheduled - it has higher priority over Schedule Runs entity
    ...(run && run.start ? { start: run.start } : {}),
    ...(run && run.finish ? { end: run.finish } : {}), //--
    baseMaterial: run?.materials_base,
    supportMaterial: run?.materials_support,
  };

  const isManager = currentUserRole === USER_ROLES.MANAGER;
  const canEditActuals = run?.status === RUN_STATUSES.COMPLETE && isManager;
  const isUserManagedPrinterType = run?.batch_type === BUILD_PACKER_TYPES.USER_MANAGED;

  return (
    <RunRecord
      {...props}
      {...selected}
      estimatesPrintTime={estimatesPrintTime}
      showEstimationTimeEditModal={showEstimationTimeEditModal}
      loadPrints={loadPiecesByRun}
      exportControlled={run?.export_controlled}
      toggleEstimationModal={toggleEstimationModal}
      estimations={estimations}
      onEstimationTimeChange={onEstimationTimeChange}
      onEstimationTimeSubmit={onEstimationTimeSubmit}
      isEstimationsSubmitting={isEstimationsSubmitting}
      canEditActuals={canEditActuals}
      toggleActualsModal={toggleActualsModal}
      showActualsEditModal={showActualsEditModal}
      isUserManagedPrinterType={isUserManagedPrinterType}
      secureFileCheckout={secureFileCheckout}
      isDisabledForm={isDisabledForm}
      isToolingStockFeatureEnabled={isToolingStockFeatureEnabled}
      onEstMaterialWeightSubmit={onEstMaterialWeightSubmit}
      listStore={listStore}
      pagination={{
        ...paginationState,
        goToPage,
        nextPage,
        prevPage,
        setPage,
        onLimitChange,
        totalPaginatedPages,
      }}
      filters={{
        globalFilter,
        setGlobalFilter,
        piecesGroupsFilter,
        setPiecesGroupsFilter,
      }}
      filterValues={{
        filterValue,
        setFilterValue,
      }}
    />
  );
};

RunRecordContainer.defaultProps = {
  estimates: [],
  uri: null,
  uuid: null,
  run: null,
};

RunRecordContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  estimates: PropTypes.arrayOf(PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
    materials: PropTypes.shape({
      base: PropTypes.number,
      support: PropTypes.number,
    }),
    time: PropTypes.shape({
      run_duration: PropTypes.number,
      pre_run_duration: PropTypes.number,
      post_run_duration: PropTypes.number,
    }),
  })),
  uri: PropTypes.string,
  uuid: PropTypes.string,
  run: runType,
};

export default RunRecordContainer;
