import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Form as BsForm } from 'react-bootstrap';
import { extractUuid } from 'rapidfab/reducers/makeApiReducers';
import * as Selectors from 'rapidfab/selectors';
import {
  API_RESOURCES,
  TEXT_COLOR_CONTRAST,
} from 'rapidfab/constants';

import SmoothCollapse from 'react-smooth-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash/string';
import Alert from 'react-s-alert';
import { LINE_ITEM_STATUS_COLOR_CODE_MAPPING,
  WORKFLOW_TYPES_ESTIMATES_KEY_MAP } from 'rapidfab/mappings';
import Actions from 'rapidfab/actions';
import CancelOrDeleteModal from 'rapidfab/components/CancelOrDeleteModal';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';
import { FormattedMessage } from 'rapidfab/i18n';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';
import LineItemPowderWorkflowEditFormContainer
  from 'rapidfab/containers/records/order/LineItemPowderWorkflowEditFormContainer';
import { faAngleDown, faAngleUp, faClone, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BulkLineItemActionContext } from 'rapidfab/context/BulkLineItemActionContext';

const LineItemPowderWorkflow = props => {
  const { readOnly, expandMode, setExpandedItems, setExpandMode, expandedItems } = props;
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalType, setModalType] = useState('');
  const lineItem = useSelector(state => Selectors.getUUIDResource(state, extractUuid(props.uri)));
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
  const workflowTypeEstimatesKey = WORKFLOW_TYPES_ESTIMATES_KEY_MAP[lineItem?.workflow_type];

  const order = useSelector(state => Selectors.getUUIDResource(state, extractUuid(lineItem.order)));
  const isRestrictedUser = useSelector(Selectors.isCurrentUserRestricted);
  const lineItems = useSelector(state => Selectors.getLineItemsForOrderSortedByCreatedDesc(state, order));

  const isDebugModeEnabled = useSelector(Selectors.getIsDebugModeEnabled);

  const { handleMarkLineItemForBulkAction,
    isBulkActionLineItemMarked } = useContext(BulkLineItemActionContext) || {};

  const index = useMemo(
    () => lineItems.findIndex(l => l.uri === lineItem.uri),
    [lineItems, lineItem.uri],
  );

  useEffect(() => {
    if (!isRestrictedUser && !expandedItems?.length && expandMode === 'expanded') {
      setExpandMode('collapsed');
    }
  }, [isRestrictedUser, expandMode, expandedItems?.length]);

  useEffect(() => {
    if (expandMode === 'expanded') {
      return setExpanded(true);
    }
    if (expandMode === 'collapsed') {
      return setExpanded(false);
    }

    if (
      !isRestrictedUser && expandedItems
      && !expandedItems?.length
      && !expandMode && (index < 1 || isRestrictedUser)) {
      setExpandedItems(expandedItems => [...expandedItems, index]);
    }

    return setExpanded(index < 1 || isRestrictedUser);
  }, [expandMode, index, isRestrictedUser]);

  const lazyLoadRef = useRef();

  const onWorkflowChange = useCallback(selectedWorkflow => {
    dispatch(
      Actions.Api.nautilus[API_RESOURCES.PROCESS_STEP].list(
        { workflows: selectedWorkflow }, {}, {}, {}, true,
      ));
  }, [lineItem?.workflow]);

  const onDuplicate = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(
        Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].clone(lineItem.uuid, { quantity: lineItem.quantity }),
      );
    } catch (error) {
      Alert.error(error.message);
    }
    setIsLoading(false);
    Alert.success(
      <FormattedMessage
        id="toaster.lineItem.duplicated"
        defaultMessage="Line item {lineItemUuid} was duplicated successfully."
        values={{ lineItemUuid: lineItem.uuid }}
      />,
    );
  }, [dispatch, lineItem?.uuid, lineItem?.quantity]);

  const onDelete = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.PRODUCT].delete(extractUuid(lineItem.product)))
        .then(() => dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].remove(extractUuid(lineItem.uri))));
    } catch (error) {
      Alert.error(error.message);
    }
    setIsLoading(false);
    Alert.success(
      <FormattedMessage
        id="toaster.lineItem.deleted"
        defaultMessage="Line item {lineItemUuid} was deleted successfully."
        values={{ lineItemUuid: lineItem.uuid }}
      />,
    );
  }, [dispatch, lineItem?.product, lineItem?.uri]);

  const onHandleConfirmModal = useCallback(async () => {
    switch (modalType) {
      case 'duplicate': {
        await onDuplicate();
        break;
      }
      case 'delete': {
        await onDelete();
        break;
      }
      default:
    }
  }, [modalType, onDuplicate, onDelete]);

  if (!lineItem) {
    return null;
  }

  const labelBgColor = LINE_ITEM_STATUS_COLOR_CODE_MAPPING[lineItem.status];
  const labelTextColor = TEXT_COLOR_CONTRAST[labelBgColor];

  return (
    <Card ref={lazyLoadRef} bg="dark" className="m-b" id={extractUuid(lineItem.uri)}>
      {isDebugModeEnabled && (
        <DebugModeDataPanel style={{ marginBottom: 10 }} className="spacer-bottom" data={lineItem} />
      )}
      <Card.Header
        className="pd-exp inverse d-flex align-items-center justify-content-between position-sticky z-index-1000"
        style={{ top: '56px' }}
      >
        <div id="card-header-main-content" className="d-flex align-items-center w-100">
          {isBulkActionLineItemMarked && (
            <BsForm.Check
              id={`bulk-action-checkbox-${props.formKey}`}
              name={`bulk-action-checkbox-${props.formKey}`}
              checked={isBulkActionLineItemMarked(lineItem.uri)}
              onChange={() => handleMarkLineItemForBulkAction(lineItem)}
              type="checkbox"
            />
          )}

          {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
          <div
            role="button"
            id="card-header-clickable-container"
            onClick={() => {
              setExpanded(previous => !previous);

              setExpandedItems(expanded => {
                if (!expanded.includes(index)) {
                  return [...expanded, index];
                }
                return expanded.filter(item => item !== index);
              });
            }}
            className="d-flex align-items-center w-100"
          >
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={expanded ? faAngleUp : faAngleDown}
                size="2x"
                style={{ lineHeight: '20px' }}
                className="spacer-left"
              />
              <span className="mx-2">
                Line Item of {lineItem.name} ({props.productIndex}) - ({lineItem.id})
              </span>
              <span
                className="badge badge-sm"
                style={{
                  backgroundColor: labelBgColor,
                  color: labelTextColor,
                }}
              >
                {capitalize(lineItem.status)}
              </span>
            </div>
          </div>

          <div
            tabIndex={0}
            role="button"
            onClick={event => { event.stopPropagation(); }}
            className="d-flex align-items-center"
          >
            <Button
              className="me-1"
              variant="primary"
              size="xs"
              title="Duplicate"
              disabled={isLoading}
              onClick={() => setModalType('duplicate')}

            >
              <FontAwesomeIcon icon={faClone} />
            </Button>
            <Button
              variant="danger"
              size="xs"
              title="Delete"
              disabled={isLoading}
              onClick={() => setModalType('delete')}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            {modalType && (
              <CancelOrDeleteModal
                modalType={modalType}
                handleConfirm={onHandleConfirmModal}
                handleOpen={setModalType}
              />
            )}
          </div>
        </div>

      </Card.Header>
      <SmoothCollapse expanded={expanded}>
        <div className="card-body-wrapper">
          <Card.Body className="p-a">
            {expanded && (
              <LineItemPowderWorkflowEditFormContainer
                lineItem={lineItem}
                formKey={lineItem.uri}
                readOnly={readOnly}
                onWorkflowChange={onWorkflowChange}
                workflowTypeKey={workflowTypeKey}
                workflowTypeEstimatesKey={workflowTypeEstimatesKey}
              />
            )}
          </Card.Body>
        </div>
      </SmoothCollapse>
    </Card>
  );
};

LineItemPowderWorkflow.defaultProps = {
  readOnly: false,
};

LineItemPowderWorkflow.propTypes = {
  uri: PropTypes.string.isRequired,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  expandMode: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.string]).isRequired,
  setExpandedItems: PropTypes.func.isRequired,
  setExpandMode: PropTypes.func.isRequired,
  expandedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  productIndex: PropTypes.number.isRequired,
  formKey: PropTypes.string.isRequired,
};

export default React.memo(LineItemPowderWorkflow);
