import PropTypes from 'prop-types';
import QuoteProcessStepModalContainer from 'rapidfab/containers/records/order/QuoteProcessStepModalContainer';
import React, { createContext, useCallback, useContext, useState } from 'react';

const QuoteProcessStepsModalContext = createContext(null);

export const QuoteProcessStepsModalContextProvider = ({
  orderQuoteFFEnabled,
  workstepQuoteDetailsFFEnabled,
  children,
}) => {
  const [isOrderQuoteModalActive, setIsOrderQuoteModalActive] = useState(false);
  const [quoteModalAdditionalProps, setQuoteModalAdditionalProps] = useState({});

  const showQuoteModal = useCallback(props => {
    setQuoteModalAdditionalProps(props);
    setIsOrderQuoteModalActive(true);
  }, []);

  const hideQuoteModal = useCallback(() => {
    setIsOrderQuoteModalActive(false);
  }, []);

  if (!orderQuoteFFEnabled && !workstepQuoteDetailsFFEnabled) {
    return children;
  }

  return (
    <QuoteProcessStepsModalContext.Provider value={{
      showQuoteModal,
      hideQuoteModal,
    }}
    >
      {children}

      {isOrderQuoteModalActive && (
        <QuoteProcessStepModalContainer
          {...quoteModalAdditionalProps}
          show={isOrderQuoteModalActive}
          close={hideQuoteModal}
        />
      )}

    </QuoteProcessStepsModalContext.Provider>
  );
};

QuoteProcessStepsModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  orderQuoteFFEnabled: PropTypes.bool.isRequired,
  workstepQuoteDetailsFFEnabled: PropTypes.bool.isRequired,
};

export const useQuoteModal = () => useContext(QuoteProcessStepsModalContext);
